import React, {useMemo} from 'react';
import Grid from "@mui/material/Grid";
import {useNavigate, useParams} from "react-router-dom";
import {CardContent, CardHeader, Chip, ListItem, Menu, MenuItem, Stack, Tab, Tabs, Theme,} from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment/moment";
import TraineeList from "../trainee/TraineeList";
import AddTrainee from "../trainee/AddTrainee";
import BreadcrumbItem from "../../types/BreadcrumbItem";
import {a11yProps, Item, TabPanel} from "../utils/Utils";
import InteractiveOutlinedInput from "../utils/InteractiveOutlinedInput";
import SnackBarUtils from "../SnackBarUtils";
import {useTheme} from '@mui/material/styles';
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {useLazyGetInstancesWithFiltersQuery} from "../../services/InstanceService";
import {
    useArchiveTrainingSessionMutation,
    useDeleteTrainingSessionMutation,
    useGetTrainingSessionLinksQuery,
    useGetTrainingSessionQuery,
    useUpdateTrainingSessionModuleMutation,
    useUpdateTrainingSessionMutation,
} from "../../services/TrainingSessionService";
import TrainingSessionModuleSelect from "./TrainingSessionModuleSelect";
import AccessCodeViewer from "./AccessCodeViewer";
import TrainingSessionModulesCalendar from "./TrainingSessionModulesCalendar";
import {momentLocalizer} from "react-big-calendar";
import {TrainingSessionLinksEditor} from "./TrainingSessionLinksEditor";
import {CoPresent} from "@mui/icons-material";
import TrainingModule from "../../types/TrainingModule";
import PracticalExercise from "../../types/PracticalExercise";
import Instance from "../../types/Instance";

type Props = {
    pageTitle: (arg: string) => void
    breadCrumbItems: (arg: BreadcrumbItem[]) => void
};

const TrainingSessionDetails: React.FunctionComponent<Props> = ({pageTitle, breadCrumbItems}) => {

    const [tabValue, setTabValue] = React.useState(0);
    const {id} = useParams<{ id: any }>();
    const localizer = momentLocalizer(moment);

    const {
        data: trainingSession = {
            id: '', title: '', startDate: new Date(),
            endDate: new Date(),
            trainer: '',
            trainees: []
        },
    } = useGetTrainingSessionQuery(id);
    const [triggerGetInstances] = useLazyGetInstancesWithFiltersQuery();
    const [archiveTrainingSession] = useArchiveTrainingSessionMutation();
    const [updateSession] = useUpdateTrainingSessionMutation();
    const [updateModule] = useUpdateTrainingSessionModuleMutation();
    const [deleteSession] = useDeleteTrainingSessionMutation();
    const [anchorMoreButtonEl, setAnchorMoreButtonEl] = React.useState<null | HTMLElement>(null);
    const moreButtonMenu = Boolean(anchorMoreButtonEl);
    const theme = useTheme<Theme>();
    const navigate = useNavigate();
    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = React.useState(false);
    const [openContainsInstancesDeleteDialog, setOpenContainsInstancesDeleteDialog] = React.useState(false);
    const [moduleEvents, setModuleEvents] = React.useState<any>([]);
    const items: BreadcrumbItem[] = useMemo(() => [{title: 'Sessions', link: '/sessions'}, {
        title: trainingSession.title,
        link: '/sessions/' + id
    }], [trainingSession.title, id]);
    const {
        data: links = [],
    } = useGetTrainingSessionLinksQuery(id);

    React.useEffect(() => {
        pageTitle("Sessions");
    });

    React.useEffect(() => {
        let calendarEvents: {
            id: string | undefined;
            title: string | undefined;
            start: Date | undefined;
            end: Date | undefined;
            isDraggable: boolean;
            isResizable: boolean
        }[] = [];
        trainingSession?.trainingModules?.forEach((module) => {
            calendarEvents.push({
                id: module.id,
                title: module.name,
                start: moment(module.startDate).toDate(),
                end: moment(module.endDate).toDate(),
                isDraggable: true,
                isResizable: true
            })
        })
        setModuleEvents(calendarEvents);
    }, [trainingSession.trainingModules])

    React.useEffect(() => {
        breadCrumbItems(items);
    }, [breadCrumbItems, items]);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleUpdateTitle = (title: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            updateSession({
                id: trainingSession.id,
                title: title,
                startDate: trainingSession.startDate,
                endDate: trainingSession.endDate,
                trainer: trainingSession.trainer,
            }).unwrap().then(() => {
                SnackBarUtils.info('Session title updated');
                resolve(true)
            }).catch(() => {
                SnackBarUtils.error('Error happened while updating session title');
                reject(false)
            });
        });
    };

    const handleUpdateStartDate = (startDate: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            updateSession({
                id: trainingSession.id,
                title: trainingSession.title,
                startDate: moment(startDate, "L LT").toDate(),
                endDate: trainingSession.endDate,
                trainer: trainingSession.trainer,
            }).unwrap().then(() => {
                SnackBarUtils.info('Session start date updated');
                resolve(true)
            }).catch(() => {
                SnackBarUtils.error('Error happened while updating session start date');
                reject(false)
            });
        });
    };

    const handleUpdateEndDate = (endDate: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            updateSession({
                id: trainingSession.id,
                title: trainingSession.title,
                endDate: moment(endDate, "L LT").toDate(),
                startDate: trainingSession.startDate,
                trainer: trainingSession.trainer,
            }).unwrap().then(() => {
                SnackBarUtils.info('Session end date updated');
                resolve(true)
            }).catch(() => {
                SnackBarUtils.error('Error happened while updating session end date');
                reject(false)
            });
        });
    };

    const handleUpdateTrainer = (trainer: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            updateSession({
                id: trainingSession.id,
                title: trainingSession.title,
                endDate: trainingSession.endDate,
                startDate: trainingSession.startDate,
                trainer: trainer,
            }).unwrap().then(() => {
                SnackBarUtils.info('Session trainer updated');
                resolve(true)
            }).catch(() => {
                SnackBarUtils.error('Error happened while updating session trainer');
                reject(false)
            });
        });
    };

    const handleMoreButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorMoreButtonEl(event.currentTarget);
    };

    const handleMoreButtonClose = () => {
        setAnchorMoreButtonEl(null);
    };

    const handleMoreButtonActionArchiveSession = (archive: boolean) => {
        if (trainingSession.id) {
            archiveTrainingSession({id: trainingSession.id, isArchived: archive});
        }
        handleMoreButtonClose();
    };

    const handleDeleteSession = () => {
        if (trainingSession.id) {
            deleteSession(trainingSession.id).unwrap().then(() => {
                SnackBarUtils.info('Session deleting...');
                navigate('/sessions');
            }).catch(() => {
                SnackBarUtils.error('Error happened while deleting session');
            });
        }
    };

    const handleConfirmDelete = () => {
        if (trainingSession.id) {
            triggerGetInstances({pageNo: 0, pageSize: 1, sessionId: trainingSession.id}).unwrap().then((data) => {
                if (data.totalElements > 0) {
                    setOpenContainsInstancesDeleteDialog(true);
                } else {
                    handleDeleteSession();
                }
                setOpenConfirmDeleteDialog(false);
            });
        }
    };

    const handleCloseContainsInstancesDeleteDialog = () => {
        setOpenContainsInstancesDeleteDialog(false);
    };
    const handleValidContainsInstancesDeleteDialog = () => {
        handleDeleteSession();
        setOpenContainsInstancesDeleteDialog(false);
    };

    const handleEventChange = (updatedEvent: any) => {
        trainingSession?.id && updateModule({
            sessionId: trainingSession.id,
            moduleId: updatedEvent.id,
            start: updatedEvent.start,
            end: updatedEvent.end,
        });
    }

    function exercisesToJson(exercises: PracticalExercise[], currentModuleInstances: Instance[]) {
        return exercises.map(exercise => {
            let currentExerciseInstances = currentModuleInstances.filter(instance => instance.practicalExercise?.id === exercise.id);
            return {
                name: exercise.name,
                trainees: currentExerciseInstances.map(traineeInstance => {
                    return {
                        "username": traineeInstance.trainee,
                        "answers": traineeInstance?.survey?.map(question => {
                            return {
                                "question": question.question,
                                "lastAnswer": question.lastAnswer,
                                "isCorrect": question.correct
                            }
                        })
                    }
                })
            }
        });
    }

    function modulesToJson(modules: TrainingModule[], sessionInstances: Instance[]) {
        return modules.map(currentModule => {
            if (currentModule.practicalExercises) {
                return {
                    name: currentModule.name,
                    startDate: currentModule.startDate,
                    endDate: currentModule.endDate,
                    exercises: exercisesToJson(currentModule.practicalExercises, sessionInstances)
                }
            }

        })
    }

    function download(content: string, type: string) {
        const element = document.createElement("a");
        const textFile = new Blob([content], {type: 'text/' + type});
        element.href = URL.createObjectURL(textFile);
        element.download = "session-" + trainingSession.id + "." + type;
        document.body.appendChild(element);
        element.click();


    }

    const exportToJson = async () => {
        triggerGetInstances({pageNo: 0, pageSize: 1000, sessionId: trainingSession.id}).unwrap().then((data) => {
            if (trainingSession.id && trainingSession.trainingModules) {
                const sessionJson = {
                    "name": trainingSession.title,
                    "modules": modulesToJson(trainingSession.trainingModules, data.content)
                }

                download(JSON.stringify(sessionJson), "json");
            }
        });
    }

    const exportToCSV = async () => {
        triggerGetInstances({pageNo: 0, pageSize: 1000, sessionId: trainingSession.id}).unwrap().then((data) => {
            if (trainingSession.id && trainingSession.trainingModules) {
                let delimiter = ";"
                let newLine = "\n"
                let content = "Session ID;Session title;Module ID;Module name;Module start date;Module end date;Trainee username;Question;Last answer;Correct";
                data.content.forEach((instance) => {
                    instance?.survey?.forEach((question) => {
                        const line = trainingSession.id + delimiter
                            + trainingSession.title + delimiter
                            + instance?.practicalExercise?.trainingModule?.id + delimiter
                            + instance?.practicalExercise?.trainingModule?.name + delimiter
                            + instance?.practicalExercise?.trainingModule?.startDate + delimiter
                            + instance?.practicalExercise?.trainingModule?.endDate + delimiter
                            + instance?.trainee + delimiter
                            + question.question + delimiter
                            + question.lastAnswer + delimiter
                            + question.correct + delimiter
                        content += newLine + line;
                    });
                })
                download(content, "csv");
            }
        });
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Item>
                        <CardHeader title={
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={8}>
                                    <InteractiveOutlinedInput
                                        initialValue={trainingSession.title}
                                        handleUpdate={handleUpdateTitle}
                                        fullWidth={true}
                                        sx={{color: theme.palette.primary.main, fontSize: 28, height: 40}}>
                                    </InteractiveOutlinedInput>
                                </Grid>
                                <Grid item xs={4} sx={{textAlign: 'right'}}>
                                    {trainingSession?.id &&
                                        <>
                                            <Button disableElevation size={"small"} variant="contained" target="_blank"
                                                    startIcon={<CoPresent/>}
                                                    href={"/presentation/" + trainingSession.id}>
                                                Presentation
                                            </Button>
                                            {trainingSession.archived &&
                                                <Chip size={"small"} label="ARCHIVED" color="default"
                                                      variant="outlined"/>
                                            }
                                            <IconButton
                                                aria-label="Plus d'actions"
                                                sx={{marginLeft: 2}}
                                                onClick={handleMoreButtonClick}
                                            >
                                                <MoreVertIcon/>
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorMoreButtonEl}
                                                open={moreButtonMenu}
                                                onClose={handleMoreButtonClose}
                                            >
                                                <MenuItem
                                                    onClick={() => exportToJson()}
                                                >
                                                    Export to JSON
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => exportToCSV()}
                                                >
                                                    Export to CSV
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => handleMoreButtonActionArchiveSession(!trainingSession?.archived)}
                                                >
                                                    {trainingSession.archived ? "Unarchive session" : "Archive session"}
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => setOpenConfirmDeleteDialog(true)}
                                                    sx={{color: theme.palette.error.main}}>
                                                    Delete session
                                                </MenuItem>
                                            </Menu>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        }/>
                        <CardContent sx={{height: 120, overflow: "auto", paddingY: 0}}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <List component={Stack} direction="row">
                                        <ListItem sx={{padding: 0}}>
                                            <ListItemText primaryTypographyProps={{fontWeight: 550}}
                                                          primary="Start date"
                                                          secondary={
                                                              <InteractiveOutlinedInput
                                                                  initialValue={moment(trainingSession.startDate).format("L LT")}
                                                                  handleUpdate={handleUpdateStartDate}
                                                                  displayAcceptButtons={false}
                                                                  sx={{
                                                                      color: theme.palette.text.secondary,
                                                                      fontSize: 14,
                                                                      height: 20
                                                                  }}>
                                                              </InteractiveOutlinedInput>
                                                          }
                                                          secondaryTypographyProps={{component: 'div'}}/>
                                        </ListItem>
                                        <ListItem sx={{padding: 0}}>
                                            <ListItemText primaryTypographyProps={{fontWeight: 550}} primary="End date"
                                                          secondary={
                                                              <InteractiveOutlinedInput
                                                                  initialValue={moment(trainingSession.endDate).format("L LT")}
                                                                  handleUpdate={handleUpdateEndDate}
                                                                  displayAcceptButtons={false}
                                                                  sx={{
                                                                      color: theme.palette.text.secondary,
                                                                      fontSize: 14,
                                                                      height: 20
                                                                  }}>
                                                              </InteractiveOutlinedInput>
                                                          }
                                                          secondaryTypographyProps={{component: 'div'}}/>
                                        </ListItem>
                                        <ListItem sx={{padding: 0}}>
                                            <ListItemText primaryTypographyProps={{fontWeight: 550}}
                                                          primary="Trainer"
                                                          secondary={
                                                              <InteractiveOutlinedInput
                                                                  initialValue={trainingSession.trainer}
                                                                  handleUpdate={handleUpdateTrainer}
                                                                  displayAcceptButtons={false}
                                                                  sx={{
                                                                      color: theme.palette.text.secondary,
                                                                      fontSize: 14,
                                                                      height: 20
                                                                  }}>
                                                              </InteractiveOutlinedInput>
                                                          }
                                                          secondaryTypographyProps={{component: 'div'}}/>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Item>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Item>
                        <CardContent sx={{overflow: "auto", paddingBottom: 0}}>
                            <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                                <Tab label="Modules" {...a11yProps(0)} />
                                <Tab label={"Trainees (" + trainingSession?.trainees?.length + ")"} {...a11yProps(1)} />
                                <Tab label="Planning" {...a11yProps(2)} />
                                <Tab label="Links" {...a11yProps(3)} />
                                <Tab label="Access Code" {...a11yProps(4)} />
                            </Tabs>
                        </CardContent>
                        <TabPanel value={tabValue} index={0}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <TrainingSessionModuleSelect
                                        trainingSession={trainingSession}></TrainingSessionModuleSelect>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <Grid item xs={12} md={12} lg={12}>
                                <TraineeList/>
                                {trainingSession.id && trainingSession.trainees ?
                                    <AddTrainee sessionId={trainingSession.id}
                                                trainees={trainingSession.trainees}/> : <></>}
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <TrainingSessionModulesCalendar localizer={localizer}
                                                                defaultDate={trainingSession.startDate}
                                                                events={moduleEvents}
                                                                onEventChange={handleEventChange}
                                                                modules={[]}></TrainingSessionModulesCalendar>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <Grid item xs={12} md={12} lg={12}>

                                <TrainingSessionLinksEditor trainingSessionId={id}
                                                            trainingSessionLinks={links}></TrainingSessionLinksEditor>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tabValue} index={4}>
                            <Grid item xs={12} md={12} lg={12}>
                                {trainingSession.id &&
                                    <AccessCodeViewer trainingSessionId={trainingSession.id}></AccessCodeViewer>}
                            </Grid>
                        </TabPanel>
                    </Item>
                </Grid>
            </Grid>
            <Dialog PaperProps={{
                style: {
                    backgroundImage: "none"
                },
            }} open={openConfirmDeleteDialog}>
                <DialogTitle>Delete the session?</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to delete this session?</p>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation onClick={() => setOpenConfirmDeleteDialog(false)}>CANCEL</Button>
                    <Button disableElevation color={"error"} onClick={handleConfirmDelete}>DELETE</Button>
                </DialogActions>
            </Dialog>
            <Dialog PaperProps={{
                style: {
                    backgroundImage: "none"
                },
            }} open={openContainsInstancesDeleteDialog}>
                <DialogTitle>Delete session with instances?</DialogTitle>
                <DialogContent>
                    <p>One or more instances are attached to this session. All instances will
                        be destroyed and deleted in database. Trainee will have no access to this session and will lose
                        progress on all exercises.</p>
                    <p>Are you sure you want to delete anyway?</p>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation onClick={handleCloseContainsInstancesDeleteDialog}>CANCEL</Button>
                    <Button disableElevation color={"error"} onClick={handleValidContainsInstancesDeleteDialog}>DELETE
                        ANYWAY</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TrainingSessionDetails;