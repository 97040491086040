import {RootState} from "../slices";

export const prepareHeaders = (headers: Headers, state: RootState) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = state.user.token;
    if (token) {
        headers.set('authorization', `Bearer ${token}`)
    }
    const csrfToken = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('X-XSRF-TOKEN', csrfToken);
    return headers
}