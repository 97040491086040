import React, {KeyboardEvent, useState} from 'react';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import {Add, Delete} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useUpdateTrainingSessionLinksMutation} from "../../services/TrainingSessionService";
import TrainingSessionLink from "../../types/TrainingSessionLink";
import SnackBarUtils from "../SnackBarUtils";
import {Checkbox, FormControlLabel} from "@mui/material";

type Props = {
    trainingSessionId: string,
    trainingSessionLinks: TrainingSessionLink[],
};

export const TrainingSessionLinksEditor: React.FunctionComponent<Props> = (
    {trainingSessionId, trainingSessionLinks: initialTrainingSessionLinks}: Props
) => {

    const [links, setLinks] = React.useState<TrainingSessionLink[]>(initialTrainingSessionLinks);
    const [updateTrainingLinks, {isLoading: isUpdatingTrainingLinks}] = useUpdateTrainingSessionLinksMutation();
    const [newLink, setNewLink] = useState<TrainingSessionLink>({
        id: undefined,
        name: '',
        url: '',
        visible: false
    })

    const handleSave = () => {
        updateTrainingLinks({id: trainingSessionId, links: links}).unwrap().then(() => {
            SnackBarUtils.info('Training successfully modified')
        });
    }
    const handleLinkUpdate = (link: TrainingSessionLink) => {

        let nextLinks = links.map((x) => Object.assign({}, x));
        nextLinks.map((currentLink: TrainingSessionLink) => {
            if (currentLink.id === link.id) {
                currentLink.name = link.name;
                currentLink.url = link.url;
                currentLink.visible = link.visible;
                return currentLink;
            } else {
                return currentLink;
            }
        })
        setLinks(() => ([
            ...nextLinks
        ]));

    }

    const handleNewLink = () => {
        setLinks((oldState: TrainingSessionLink[]) => ([
            ...oldState.concat(newLink)
        ]));

        setNewLink({id: undefined, name: '', url: '', visible: false})
    }

    const handleRemoveLink = (linkId?: string) => {
        setLinks((oldState: TrainingSessionLink[]) => ([
            ...oldState?.filter((link) => link.id !== linkId)
        ]));
        setNewLink({id: undefined, name: '', url: '', visible: false})
    }

    const handleKeyDownAdd = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleNewLink()
        }
    };

    return (
        <Grid>
            {links && links.map((link, index) => (
                <Grid key={index + "-link"} container spacing={2} paddingTop={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    variant="standard"
                                    label="Name"
                                    fullWidth
                                    value={link.name}
                                    onChange={(e) => {
                                        handleLinkUpdate({...link, name: e.target.value})
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    label="URL"
                                    value={link.url}
                                    onChange={(e) => {
                                        handleLinkUpdate({...link, url: e.target.value})
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={link.visible}
                                        onChange={(e) => {
                                            handleLinkUpdate({...link, visible: e.target.checked})
                                        }}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                } label="Visible"/>
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                                <IconButton color={"primary"} aria-label="Add"
                                            onClick={() => handleRemoveLink(link.id)}>
                                    <Delete></Delete>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={2} paddingTop={2} key={"new-link"}>
                    <Grid item xs={4}>
                        <TextField
                            onKeyDown={handleKeyDownAdd}
                            variant="standard"
                            label="Name"
                            autoFocus
                            fullWidth
                            value={newLink.name}
                            onChange={(e) => {
                                setNewLink({...newLink, name: e.target.value})
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="standard"
                            fullWidth
                            label="URL"
                            value={newLink.url}
                            onKeyDown={handleKeyDownAdd}
                            onChange={(e) => {
                                setNewLink({...newLink, url: e.target.value})
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} md={1} lg={1}>
                        <FormControlLabel control={
                            <Checkbox
                                checked={newLink.visible}
                                onChange={(e) => {
                                    setNewLink({...newLink, visible: e.target.checked})
                                }}
                                inputProps={{'aria-label': 'controlled'}}
                            />
                        } label="Visible"/>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1}>
                        <IconButton color={"primary"} aria-label="Add" onClick={handleNewLink}>
                            <Add></Add>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <LoadingButton sx={{marginTop: 2}}
                           size="small"
                           onClick={handleSave}
                           loading={isUpdatingTrainingLinks}
                           disableElevation
                           variant="contained"
            >
                <span>SAVE</span>
            </LoadingButton>
        </Grid>
    );
};