import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import LearningPath, {Level} from "../../../types/LearningPath";
import SnackBarUtils from "../../SnackBarUtils";
import {InputLabel, ToggleButton} from "@mui/material";
import {StyledToggleButtonGroup} from "../NewPracticalExerciseCatalog";
import {SignalCellularAlt} from "@mui/icons-material";
import {useUpdateLearningPathMutation} from "../../../services/CatalogService";

interface LearningPathGeneralProps {
    learningPath: LearningPath,
}

export const LearningPathGeneral = (props: LearningPathGeneralProps) => {

    const [learningPath, setLearningPath] = useState<LearningPath>(props.learningPath);
    const [nameError, setNameError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [imageLinkError, setImageLinkError] = useState(false);


    const [updateLearningPath, {isLoading: isUpdatingLearningPath}] = useUpdateLearningPathMutation();

    useEffect(() => {
        setLearningPath(props.learningPath);
    }, [props.learningPath])


    function getToggleColor() {
        switch (learningPath.level) {
            case Level.BEGINNER :
                return "success";
            case Level.INTERMEDIATE :
                return "warning";
            case Level.EXPERT:
                return "error";
            default:
                return "info"
        }
    }

    function handleSave() {
        updateLearningPath(learningPath).unwrap().then(() => {
            SnackBarUtils.info('Learning path successfully modified')
        });
    }

    return (<Grid container rowSpacing={1} sx={{paddingTop: "20px"}}>
        <Grid item xs={12} md={12} lg={12}>
            <TextField
                fullWidth
                required
                key={learningPath.id + "name"}
                variant="standard"
                label="Name"
                defaultValue={learningPath?.name}
                error={nameError}
                onChange={(e) => {
                    setNameError(e.target.value as string === "")
                    setLearningPath((oldState: LearningPath) => ({
                        ...oldState, name: e.target.value as string
                    }));
                }}
                helperText={nameError ? "Please enter a name." : ""}
            />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
            <TextField
                fullWidth
                required
                variant="standard"
                label="Description"
                key={learningPath.id + "description"}
                defaultValue={learningPath?.description}
                error={descriptionError}
                onChange={(e) => {
                    setDescriptionError(e.target.value === "")
                    setLearningPath((oldState: LearningPath) => ({
                        ...oldState, description: e.target.value
                    }));
                }}
                helperText={descriptionError ? "Please enter a description." : ""}
            />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
            <TextField
                margin="dense"
                id="image-link"
                label="Image link"
                type="text"
                fullWidth
                key={learningPath.id + "image-link"}
                defaultValue={learningPath?.imageLink}
                variant="standard"
                error={imageLinkError}
                onChange={(e) => {
                    if (e.target.value === "") {
                        setImageLinkError(true)
                    } else {
                        setImageLinkError(false)
                        setLearningPath((oldState: LearningPath) => ({
                            ...oldState, imageLink: e.target.value
                        }));
                    }
                }}
                required={true}
                helperText={nameError ? "Please enter an image link." : ""}
            />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
            <InputLabel htmlFor="level"
                        sx={{
                            fontWeight: 400,
                            fontSize: "11px",
                            marginTop: "13px",
                            marginBottom: "4px"
                        }}>
                Level
            </InputLabel>
            <StyledToggleButtonGroup
                size="small"
                color={getToggleColor()}
                value={learningPath?.level}
                exclusive
                onChange={(e, value) => {
                    setLearningPath((oldState: LearningPath) => ({
                        ...oldState, level: value
                    }))
                }}
                aria-label="Level"
            >
                <ToggleButton value="BEGINNER"><SignalCellularAlt/>BEGINNER</ToggleButton>
                <ToggleButton value="INTERMEDIATE"><SignalCellularAlt/>INTERMEDIATE</ToggleButton>
                <ToggleButton value="EXPERT"><SignalCellularAlt/>EXPERT</ToggleButton>
            </StyledToggleButtonGroup>
        </Grid>
        <LoadingButton sx={{marginTop: 2}}
                       size="small"
                       onClick={handleSave}
                       loading={isUpdatingLearningPath}
                       variant="contained" disableElevation
        >
            <span>SAVE</span>
        </LoadingButton>
    </Grid>)
}