import React from 'react';
import {Checkbox, List, ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import Button from "@mui/material/Button";

type Props = {
    trainees?: string[],
    onTraineeSelection: (arg: string[]) => void
};


const TraineeSelection: React.FunctionComponent<Props> = (
    {trainees, onTraineeSelection}: Props,
) => {
    const [checked, setChecked] = React.useState<number[]>([]);

    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        let selectedTrainees: string [] = [];
        if (trainees) newChecked.forEach(checkIndex => selectedTrainees.push(trainees[checkIndex]))
        onTraineeSelection(selectedTrainees);
    };

    const handleSelectAll = () => {
        const newChecked = [];
        if (trainees) {
            for (let index: number = 0; index < trainees.length; index++) {
                newChecked.push(index);
            }
            let selectedTrainees: string [] = [];
            newChecked.forEach(checkIndex => selectedTrainees.push(trainees[checkIndex]))
            setChecked(newChecked);
            onTraineeSelection(selectedTrainees);
        }
    };

    return (
        <React.Fragment>
            {
                trainees && trainees.length > 0 ?
                    <List dense={true}>
                        <ListItem><Button color="primary" variant="text" onClick={handleSelectAll}> Select all ({trainees.length})</Button></ListItem>
                        {
                            trainees.map((trainee, index) => {
                                return <ListItem key={trainee}>
                                    <ListItemButton role={undefined} onClick={handleToggle(index)} dense>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={checked.indexOf(index) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{'aria-labelledby': trainee}}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={trainee} primary={trainee}/>
                                    </ListItemButton>
                                </ListItem>
                            })
                        }

                    </List> : "Please add trainees first."
            }
        </React.Fragment>

    );
};

export default TraineeSelection;
