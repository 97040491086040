import React from 'react';
import Button from "@mui/material/Button";
import {Tooltip} from "@mui/material";
import TrainingSession from "../../types/TrainingSession";
import PracticalExercise from "../../types/PracticalExercise";
import IconButton from "@mui/material/IconButton";
import {RocketLaunch} from "@mui/icons-material";
import {useDestroyInstanceMutation, useLazyGetInstancesWithFiltersQuery} from "../../services/InstanceService";
import Instance from "../../types/Instance";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import SnackBarUtils from "../SnackBarUtils";

type Props = {
    session: TrainingSession;
    practicalExercise: PracticalExercise,
    type?: ("icon" | "full")
};

const StopExercise: React.FunctionComponent<Props> = ({practicalExercise, session, type = "full"}) => {

        const [destroyInstance, {isLoading: isDestroyingInstanceLoading}] = useDestroyInstanceMutation();
        const [open, setOpen] = React.useState(false);

        const [
            triggerGetSessionInstancesByExerciseQuery, {
                isLoading: isSessionLoading,
            }
        ] = useLazyGetInstancesWithFiltersQuery();

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };
        const handleStopExercise = () => {
            SnackBarUtils.info('Destroying instances for all trainees... ')
            if (session?.id && practicalExercise?.id) {
                triggerGetSessionInstancesByExerciseQuery({
                    sessionId: session.id,
                    exerciseId: practicalExercise.id,
                    pageSize: 1000,
                    pageNo: 0
                }).then((resp) => {
                    let instanceList = resp.data?.content;
                    if (instanceList !== undefined) {
                        instanceList.forEach((instance: Instance) => {
                            destroyInstance(instance.id);
                        });
                    }
                })
                handleClose();
            }

        };

        return (
            <>
                <Tooltip title="Destroy exercise instances" placement="bottom-start">
                    {
                        type === "icon" ?
                            <IconButton aria-label="delete" onClick={handleClickOpen}>
                                <RocketLaunch sx={{transform: "rotate(90deg)"}} color={"primary"}/>
                            </IconButton>
                            :
                            <Button size="small" disableElevation variant="outlined" onClick={handleClickOpen}
                                    sx={{m: 0.5}}>Destroy exercise instances</Button>
                    }
                </Tooltip>
                <Dialog PaperProps={{
                    style: {
                        backgroundImage: "none"
                    },
                }} open={open} fullWidth>
                    <DialogTitle>Warning</DialogTitle>
                    <DialogContent>
                        <p>You are about to stop exercise and destroy all corresponding instances : </p>
                        <p>- Trainees will not be able to access the deployed exercise anymore.</p>
                        <p>- Trainees will still be able to see exercise instructions.</p>
                        <p>- Trainees will still be able to answer questions.</p>
                        <p>Do you want to continue?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button disableElevation onClick={handleClose}
                                disabled={isSessionLoading || isDestroyingInstanceLoading}>CANCEL</Button>
                        <Button disableElevation variant="contained" onClick={handleStopExercise}
                                disabled={isSessionLoading || isDestroyingInstanceLoading}>CONTINUE</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
;

export default StopExercise;