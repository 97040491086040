import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {catalogApi} from "./services/CatalogService";
import {setupListeners} from "@reduxjs/toolkit/query";
import {configSlice, userSlice} from "./slices";
import {terraformApi} from "./services/TerraformService";
import {trainingSessionApi} from "./services/TrainingSessionService";
import {instanceApi} from "./services/InstanceService";
import {traineeApi} from "./services/TraineeService";
import {eventApi} from "./services/EventService";
import {themeSlice} from "./slices/themeSlice";
import {configApi} from "./services/ConfigService";

const rootReducer = combineReducers({
    user: userSlice.reducer,
    config: configSlice.reducer,
    theme: themeSlice.reducer,
    [catalogApi.reducerPath]: catalogApi.reducer,
    [terraformApi.reducerPath]: terraformApi.reducer,
    [instanceApi.reducerPath]: instanceApi.reducer,
    [trainingSessionApi.reducerPath]: trainingSessionApi.reducer,
    [traineeApi.reducerPath]: traineeApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
});
export const store = configureStore({
    reducer: rootReducer,
    // Add the generated reducer as a specific top-level slice,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            catalogApi.middleware,
            terraformApi.middleware,
            trainingSessionApi.middleware,
            instanceApi.middleware,
            traineeApi.middleware,
            eventApi.middleware,
            configApi.middleware
        ]),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)