import {ReactKeycloakProvider} from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getConfig} from "./api/ConfigApi";

import {configSlice, RootState, userSlice} from "./slices";
import {AppRouter} from "./routes/AppRouter";
import {SnackbarProvider} from "notistack";
import {styled} from "@mui/material";
import {SnackbarUtilsConfigurator} from "./components/SnackBarUtils";

const eventLogger = (_event: unknown, _error: unknown) => {
};
const SecuredApp: React.FunctionComponent = () => {
    const config = useSelector((state: RootState) => state.config);
    const dispatch = useDispatch();

    React.useEffect(() => {
        getConfig()
            .then(res => res.json())
            .then((resp) => {
                dispatch(configSlice.actions.setOauthConfig({
                    idpUrl: resp.idpUrl,
                    realmName: resp.realmName,
                    clientId: resp.clientId,
                }));
            });
    }, [dispatch]);

    function onTokens(tokens: any) {
        dispatch(userSlice.actions.setToken({token: tokens.token}));
    }

    if (config.clientId === "") {
        return (
            <div>
                <p>Retrieving Configuration...</p>
            </div>
        );
    }

    const keycloak = new Keycloak({
        url: config.idpUrl,
        realm: config.realmName,
        clientId: config.clientId
    });

    const StyledSnackbarProvider = styled(SnackbarProvider)`
      &.SnackbarItem-variantSuccess {
        background-color: green;
      }

      &.SnackbarItem-variantInfo {
        background-color: #56ACBF;
      }`;


    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            onEvent={eventLogger}
            onTokens={onTokens}
        >
            <StyledSnackbarProvider maxSnack={3} anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                                    iconVariant={{
                                        success: '🏆',
                                        warning: '⚠️',
                                    }}>
                <SnackbarUtilsConfigurator/>
                <AppRouter/>
            </StyledSnackbarProvider>
        </ReactKeycloakProvider>
    );
};

export default SecuredApp;
