import React, {useState} from 'react';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert, LinearProgress, MenuItem} from "@mui/material";
import LearningPath from "../../types/LearningPath";
import TrainingSession from "../../types/TrainingSession";
import moment from "moment";
import {useGetLearningPathsQuery} from "../../services/CatalogService";
import {useCreateTrainingSessionMutation} from "../../services/TrainingSessionService";
import {momentLocalizer} from "react-big-calendar";
import TrainingSessionModulesCalendar from "./TrainingSessionModulesCalendar";
import SnackBarUtils from "../SnackBarUtils";

type Props = {};

const NewTrainingSession: React.FunctionComponent<Props> = () => {

    const localizer = momentLocalizer(moment);
    const [open, setOpen] = React.useState(false);
    const {
        data: availableLearningPaths = {content: [], totalElements: 10},
        isLoading: isLoadingLearningPath
    } = useGetLearningPathsQuery({
        pageNo: 0,
        pageSize: 1000
    });
    const [learningPath, setLearningPath] = useState<LearningPath>();
    const [error, setError] = useState(false);
    const [learningPathError, setLearningPathError] = useState(false);
    const [createTrainingSession, {isLoading: isCreatingTrainingSession}] = useCreateTrainingSessionMutation();
    const [newTrainingSession, setNewTrainingSession] = useState<TrainingSession>({});
    const [events, setEvents] = useState<any>([]);

    function resetDatas() {
        setLearningPath(undefined);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setError(false);
        setOpen(false);
        resetDatas();
    };

    const handleCreate = () => {
        createTrainingSession({
            trainingSession: newTrainingSession,
            learningPathId: learningPath?.id
        }).unwrap().then(() => {
            SnackBarUtils.info('Training session successfully created')
            handleClose();
        }).catch(() => {
            SnackBarUtils.error('Error happened while creating training session.')
        })
    };

    function getTrainingSessionStartDate(calendarEvents: any): Date {
        if (calendarEvents) {
            let moments = calendarEvents.map((d: any) => moment(d.start));
            return moment.min(moments).toDate();
        }
        return new Date()
    }

    function getTrainingSessionEndDate(calendarEvents: any): Date {
        if (calendarEvents) {
            let moments = calendarEvents.map((d: any) => moment(d.end));
            return moment.max(moments).toDate();
        }
        return new Date();
    }

    const handleCalendarChange = (calendarEvents: any) => {
        setNewTrainingSession((oldState: TrainingSession) => ({
            ...oldState,
            startDate: getTrainingSessionStartDate(calendarEvents),
            endDate: getTrainingSessionEndDate(calendarEvents),
            trainingModules: calendarEvents.map((calendarEvent: any) => {
                return {id: calendarEvent.id, startDate: calendarEvent.start, endDate: calendarEvent.end}
            })
        }))
    }

    function isFormValid() {
        // all modules must be selected
        return newTrainingSession?.trainingModules?.length && newTrainingSession.trainingModules?.length === learningPath?.trainingModules?.length
    }

    function createNewEmptySessions(selectedTraining: LearningPath) {
        setNewTrainingSession({
            trainingModules: [],
            title: selectedTraining.name,
            description: selectedTraining.description,
            level: selectedTraining.level,
            imageLink: selectedTraining.imageLink,
            links: selectedTraining.links?.map(link => {
                return {...link, id: ''}
            }),
            startDate: moment(new Date().setMinutes(0)).add(1, 'hours').toDate(),
            endDate: moment(new Date().setMinutes(0)).add(2, 'hours').toDate(),
        });
    }

    return (
        <>
            <Button disableElevation size="small" variant="contained" onClick={handleClickOpen}>Create training
                session</Button>
            <Dialog open={open} fullScreen scroll={"paper"} PaperProps={{
                style: {
                    backgroundImage: "none"
                },
            }}>
                <DialogTitle>Create training session</DialogTitle>
                <DialogContent>
                    {
                        isLoadingLearningPath ?
                            <LinearProgress></LinearProgress>
                            :
                            <TextField
                                fullWidth
                                id="learning-path"
                                select
                                margin="dense"
                                label="Learning path"
                                variant="standard"
                                defaultValue=""
                                onChange={(e) => {
                                    if (e.target.value === undefined) {
                                        setLearningPathError(true)
                                    } else {
                                        setLearningPathError(false)
                                        setLearningPath(JSON.parse(e.target.value));
                                        setEvents([])
                                        createNewEmptySessions(JSON.parse(e.target.value))
                                    }
                                }}
                                error={learningPathError}
                                required
                                helperText={learningPath === undefined ? "Please select a learning path." : ""}
                            >
                                {availableLearningPaths.content.map((option) => (
                                    <MenuItem key={option.id} value={JSON.stringify(option)}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                    }
                    {
                        learningPath &&
                        <TrainingSessionModulesCalendar localizer={localizer} events={events} defaultDate={new Date()}
                                                        onCalendarChange={handleCalendarChange}
                                                        modules={learningPath.trainingModules}></TrainingSessionModulesCalendar>
                    }
                </DialogContent>
                <DialogActions>
                    <Button disableElevation onClick={handleClose} disabled={isCreatingTrainingSession}>Cancel</Button>
                    <Button disableElevation variant='contained' onClick={handleCreate}
                            disabled={isCreatingTrainingSession || !isFormValid()}>Create</Button>
                </DialogActions>
                {isCreatingTrainingSession && (
                    <LinearProgress/>
                )}
                {error && (
                    <Alert severity="error">Une erreur est survenue lors de la création de la session</Alert>
                )}
            </Dialog>
        </>
    );
};

export default NewTrainingSession;
