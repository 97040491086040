import React from 'react';

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TrainingSessionList from "../components/trainingSession/TrainingSessionList";

type Props = {
    pageTitle: (arg: string) => void,
};

const Sessions: React.FunctionComponent<Props> = ({pageTitle}) => {

    React.useEffect(() => {
        pageTitle("Sessions");
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <TrainingSessionList/>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Sessions;
