import React, {useState} from 'react';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {CircularProgress, FormControlLabel, LinearProgress, Switch, Tooltip} from "@mui/material";
import TrainingSession from "../../types/TrainingSession";
import PracticalExercise from "../../types/PracticalExercise";
import TraineeSelection from "../trainee/TraineeSelection";
import IconButton from "@mui/material/IconButton";
import Instance from "../../types/Instance";
import {useLaunchInstanceMutation, useLazyGetInstancesWithFiltersQuery} from "../../services/InstanceService";
import SnackBarUtils from "../SnackBarUtils";
import {RocketLaunch} from "@mui/icons-material";
import {useLazyGetTrainingSessionQuery} from "../../services/TrainingSessionService";

type Props = {
    session: TrainingSession;
    practicalExercise: PracticalExercise,
    type?: ("icon" | "full")
};

const StartExercise: React.FunctionComponent<Props> = ({practicalExercise, session, type = "full"}) => {

        const [open, setOpen] = React.useState(false);
        const [isAutoDestroy, setIsAutoDestroy] = React.useState(true);
        const [selectedTrainees, setSelectedTrainees] = useState<string[]>([]);
        const [launchInstance, {isLoading: isLaunchInstanceLoading}] = useLaunchInstanceMutation();
        const [
            triggerGetSessionInstancesByExerciseQuery, {
                isLoading: isSessionInstancesLoading,
            }
        ] = useLazyGetInstancesWithFiltersQuery();

        const [
            triggerGetSessionQuery, {
                isFetching: isSessionLoading,
            }
        ] = useLazyGetTrainingSessionQuery();

        const handleClickOpen = () => {
            if (session?.id) {
                triggerGetSessionQuery(session.id);
                setOpen(true);
            }
        };

        const handleClose = () => {
            setOpen(false);
        };

        const handleStartExercise = () => {
            setOpen(false);
            SnackBarUtils.info('Starting instances for selected trainees..')
            if (session?.id && practicalExercise?.id) {
                triggerGetSessionInstancesByExerciseQuery({
                    sessionId: session.id,
                    exerciseId: practicalExercise.id,
                    pageNo: 0,
                    pageSize: 1000,
                }).then((resp) => {
                    selectedTrainees.forEach(trainee => {
                        let instanceList = resp.data?.content;
                        if (instanceList !== undefined) {
                            instanceList.forEach((instance: Instance) => {
                                if (instance.trainee === trainee) {
                                    launchInstance({
                                        instanceId: instance.id,
                                        isAutomaticallyDestroyed: isAutoDestroy,
                                    });
                                }
                            });
                        }

                    })
                })
            }

        };

        const handleTraineeSelection = (trainees: string[]) => {
            setSelectedTrainees(trainees);
        }

        const handleChangeIsAutoDestroy = (event: React.ChangeEvent<HTMLInputElement>) => {
            setIsAutoDestroy(event.target.checked);
        }

        return (
            <>
                <Tooltip title="Deploy exercise instances" placement="bottom-start">
                    {
                        type === "icon" ?
                            <IconButton aria-label="delete" onClick={handleClickOpen}>
                                <RocketLaunch color={"primary"}/>
                            </IconButton>
                            :
                            <Button size="small" variant="contained" disableElevation onClick={handleClickOpen}
                                    sx={{m: 0.5}}>Deploy exercise instances</Button>
                    }
                </Tooltip>

                <Dialog PaperProps={{
                    style: {
                        backgroundImage: "none"
                    },
                }} open={open} fullWidth>
                    <DialogTitle>Deploy exercise instances</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="exercise"
                            label="Exercise"
                            type="text"
                            variant="standard"
                            aria-readonly={true}
                            required
                            value={practicalExercise.name}
                        />
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="session"
                            label="Session"
                            type="text"
                            aria-readonly={true}
                            variant="standard"
                            required
                            value={session.title}
                        />
                        {!isSessionLoading ?
                            <>
                                <FormControlLabel control={<Switch checked={isAutoDestroy} onChange={handleChangeIsAutoDestroy}/>}
                                                  label="Automatically destroy instances at night"/>
                                <TraineeSelection trainees={session.trainees}
                                                  onTraineeSelection={handleTraineeSelection}></TraineeSelection>
                            </> : <CircularProgress/>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button disableElevation onClick={handleClose} disabled={isSessionInstancesLoading}>Cancel</Button>
                        <Button disableElevation variant="contained" onClick={handleStartExercise}
                                disabled={isSessionInstancesLoading || isLaunchInstanceLoading || selectedTrainees.length === 0}>Deploy</Button>
                    </DialogActions>
                    {isSessionInstancesLoading && (
                        <LinearProgress/>
                    )}
                </Dialog>
            </>
        );
    }
;

export default StartExercise;