import React from 'react';
import Grid from "@mui/material/Grid";
import ProfileInfo from "../components/profile/ProfileInfo";

type Props = {
    pageTitle: (arg: string) => void,
};

const ProfilePage: React.FunctionComponent<Props> = ({pageTitle}) => {
    React.useEffect(() => {
        pageTitle("Profile");
    });
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <ProfileInfo/>
            </Grid>
        </Grid>
    );
};

export default ProfilePage;
