import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BadgeItem from "./BadgeItem";
import {CardContent, CardHeader} from "@mui/material";
import {useGetTraineeQuery} from "../services/TraineeService";
import AwardedBadge from "../types/AwarededBadge";
import {Item} from "./utils/Utils";

// Display the identity of the user
const BadgeList: React.FunctionComponent = () => {

    const {
        data: trainee,
    } = useGetTraineeQuery();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Item>
                    <CardHeader title={
                        <>
                            <Typography component="h2" variant="h6" color="primary">
                                My badges
                            </Typography>
                            <Typography component="h4">
                                Keep completing exercises and modules to earn new badges !
                            </Typography>
                        </>
                    }/>
                    <CardContent sx={{}}>
                        <Grid container spacing={2}>
                            {
                                trainee?.awardedBadges !== undefined && trainee.awardedBadges.length > 0 ?
                                    trainee?.awardedBadges?.map((badge: AwardedBadge, index: number) => {
                                        return <BadgeItem key={index} badge={badge}/>
                                    })
                                    :
                                    <Typography fontSize={"small"} sx={{color: 'text.secondary', paddingLeft: 4}}>
                                        You have no badge yet.
                                    </Typography>
                            }

                        </Grid>
                    </CardContent>
                </Item>
            </Grid>
        </Grid>
    );
};

export default BadgeList;
