import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {API_URL} from "../api/ConfigApi";
import Trainee from "../types/Trainee";
import SnackbarUtils from "../components/SnackBarUtils";
import {prepareHeaders} from "./ServiceUtils";
import {RootState} from "../slices";

export const traineeApi = createApi({
    reducerPath: 'traineeApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL + '/trainee',
        prepareHeaders: (headers, {getState}) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            return prepareHeaders(headers, getState() as RootState)
        },
    }),
    tagTypes: ['Trainee'],
    endpoints: (builder) => ({
        getTrainee: builder.query<Trainee, void>({
            query: () => ``,
            providesTags: ['Trainee'],
            async onQueryStarted(id, {queryFulfilled, getCacheEntry}) {
                let cache = getCacheEntry().data;
                let result = await queryFulfilled;
                if (result?.data?.awardedBadges?.length !== undefined && cache?.awardedBadges?.length !== undefined && result?.data?.awardedBadges?.length > cache?.awardedBadges?.length) {
                    SnackbarUtils.success('You earned a new badge', {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        }
                    }, '/home')
                }
            },
        }),
        setPseudonym: builder.mutation<Trainee, { username: string, pseudonym: string }>({
            query: ({username, pseudonym}) => ({
                url: `/${username}/pseudonym`,
                method: 'PUT',
                body: pseudonym,
                headers: {
                    'Content-Type': 'application/json', // Specify JSON content type
                },
            }),
            invalidatesTags: ['Trainee'],
        }),
    }),
})

export const {
    useGetTraineeQuery,
    useLazyGetTraineeQuery,
    useSetPseudonymMutation,
} = traineeApi