import React, {useState} from 'react';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import CustomizableInput from "../../types/CustomizableInput";
import {Checkbox, FormControlLabel, MenuItem} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    useGetInstanceInputsQuery,
    useLaunchInstanceMutation,
    useUpdateInstanceInputsMutation
} from "../../services/InstanceService";
import Instance from "../../types/Instance";
import SnackBarUtils from "../SnackBarUtils";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

type Props = {
    instance: Instance
};

export const InstanceParametersEditor: React.FunctionComponent<Props> = (
    {instance}: Props
) => {

    const [launchInstance, {isLoading: isLaunchInstanceLoading}] = useLaunchInstanceMutation();
    const [openApplyDialog, setOpenApplyDialog] = React.useState(false);
    const {
        data: data,
    } = useGetInstanceInputsQuery(instance.id);
    const [inputs, setInputs] = useState<CustomizableInput[]>();
    const [untouched, setUntouched] = useState(true);

    React.useEffect(() => {
        setInputs(data);
    }, [data]);

    const [updateInputs, {isLoading: isUpdatingInputs}] = useUpdateInstanceInputsMutation();

    const handleSave = () => {
        if (instance.id && inputs) {
            updateInputs({instanceId: instance.id, newInputs: inputs}).unwrap().then(() => {
                SnackBarUtils.info('Instance configuration successfully modified')
                setOpenApplyDialog(true)
            }).catch(() => {
                SnackBarUtils.error('Error happened while updating instance configuration.')
            });
        }

    }

    const handleApplyInstance = () => {
        launchInstance({
            instanceId: instance.id,
            isAutomaticallyDestroyed: true,
        }).unwrap().catch(() => {
            SnackBarUtils.error('Instance already busy')
        });
        handleCloseApplyDialog();
    };

    const handleCloseApplyDialog = () => {
        setOpenApplyDialog(false);
    };
    const handleInputUpdate = (input: CustomizableInput) => {
        setUntouched(false)
        if (inputs) {
            let nextInputs = inputs.map(x => Object.assign({}, x));
            nextInputs.map((i: CustomizableInput) => {
                if (i.id === input.id) {
                    i.value = input.value;
                    return i;
                } else {
                    return i;
                }
            })
            setInputs(nextInputs);
        }
    }

    function renderInput(input: CustomizableInput) {

        switch (input.type) {
            case "BOOLEAN" :
                return (
                    <FormControlLabel control={
                        <Checkbox
                            checked={input.value === "true"}
                            onChange={(e) => {
                                handleInputUpdate({...input, value: e.target.checked ? "true" : "false"})
                            }}
                            inputProps={{'aria-label': 'controlled'}}
                        />} label=""/>

                );
            case "STRING" :
                return (
                    <TextField fullWidth
                               variant={"outlined"}
                               value={input.value}
                               onChange={(e) => {
                                   handleInputUpdate({...input, value: e.target.value})
                               }}
                    />);
            case "ENUM":
                return (<TextField
                    id="outlined-select-currency"
                    select
                    fullWidth
                    variant={"outlined"}
                    value={input.value}
                    defaultValue={input.value}
                    onChange={(e) => {
                        handleInputUpdate({...input, value: e.target.value})
                    }}
                >
                    {input.enumValues.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>);
            case "INTEGER":
                return (<TextField
                    id="outlined-number"
                    fullWidth
                    variant={"outlined"}
                    type="number"
                    value={input.value}
                    onChange={(e) => {
                        handleInputUpdate({...input, value: e.target.value})
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />)

        }
        return (<TextField
            fullWidth
            variant={"outlined"}
            value={input.value}
            onChange={(e) => {
                handleInputUpdate({...input, value: e.target.value})
            }}
        />)
    }

    return (
        <Grid container
              direction="row"
              justifyContent="flex-start"
              alignItems="center">
            {inputs?.map((input, index) => (
                <Grid key={index + "-input"} item xs={12} p={2}>
                    <Grid container alignItems="center">
                        <Grid item xs={6}>
                            <Typography variant="body1"
                                        component="h2">{input.label}</Typography>
                            <Typography variant="body2"
                                        component="h2">{input.description}</Typography>
                        </Grid>
                        <Grid item xs={6} pl={1}>
                            {
                                renderInput(input)
                            }
                        </Grid>
                    </Grid>
                </Grid>

            ))}

            <Dialog PaperProps={{
                style: {
                    backgroundImage: "none"
                },
            }} open={openApplyDialog} fullWidth>
                <DialogTitle>Configuration update</DialogTitle>
                <DialogContent>
                    <p>Updated changes are not taken into consideration yet.
                        To apply changes, you need to restart the instance.</p>
                    <p>Do you want to apply changes ?</p>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation onClick={handleCloseApplyDialog}>CANCEL</Button>
                    <Button disableElevation variant="contained"
                            onClick={() => handleApplyInstance()}>Yes, apply</Button>
                </DialogActions>
            </Dialog>
            {!inputs || inputs.length < 1 ?
                <p>No parameters</p> :
                <LoadingButton sx={{marginTop: 2}}
                               size="small"
                               disabled={untouched}
                               onClick={handleSave}
                               loading={isUpdatingInputs}
                               disableElevation
                               variant="contained"
                >
                    <span>SAVE</span>
                </LoadingButton>
            }

        </Grid>
    );
};