import React, {useMemo, useState} from "react";
import {CardContent, CardHeader, Tab, Tabs} from "@mui/material";
import PracticalExercise from "../../types/PracticalExercise";
import Grid from "@mui/material/Grid";
import {commands, ICommand, TextAreaTextApi, TextState} from '@uiw/react-md-editor';
import {PracticalExerciseGeneral} from "./PracticalExerciseGeneral";
import {PracticalExerciseCatalogQuestionsEditor} from "./PracticalExerciseCatalogQuestionsEditor";
import {useGetPracticalExerciseQuery, useUpdatePracticalExerciseMutation} from "../../services/CatalogService";
import {useParams} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {PracticalExerciseBadge} from "./PracticalExerciseBadge";
import BreadcrumbItem from "../../types/BreadcrumbItem";
import SnackBarUtils from "../SnackBarUtils";
import {a11yProps, Item, StyledGuidelineEditor, TabPanel} from "../utils/Utils";
import {ReviewList} from "./ReviewList";
import {PracticalExerciseCatalogScriptConfigurationEditor} from "./PracticalExerciseCatalogScriptConfigurationEditor";

type Props = {
    pageTitle: (arg: string) => void,
    breadCrumbItems: (arg: BreadcrumbItem[]) => void
};

const questionCommand: ICommand = {
    name: "question",
    keyCommand: "question",
    buttonProps: {"aria-label": "Insert question", title: 'Insert question'},
    icon: (
        <svg width="14" height="14" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 12H9v-.148c0-.876.306-1.499 1-1.852.385-.195 1-.568 1-1a1.001 1.001 0 00-2 0H7c0-1.654 1.346-3 3-3s3 1 3 3-2 2.165-2 3zm-2 3h2v-2H9v2z"
                fill="#5C5F62"/>
            <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1116 0 8 8 0 01-16 0z" fill="#5C5F62"/>
        </svg>
    ),
    execute: (state: TextState, api: TextAreaTextApi) => {
        let modifyText = `???QUESTION???\n`;
        if (!state.selectedText) {
            modifyText = `???QUESTION???`;
        }
        api.replaceSelection(modifyText);
    }
};

const ExerciseGuideLineEditor = ({exercise: initialExercise}: { exercise: PracticalExercise }) => {
    const [exercise, setExercise] = useState(initialExercise)
    const [updateExercise, {isLoading: isUpdatingExercise}] = useUpdatePracticalExerciseMutation();

    function handleSave() {
        updateExercise(exercise).unwrap().then(() => {
            SnackBarUtils.info('Practical exercise successfully modified')
        }).catch(() => {
            SnackBarUtils.error('Error happened while updating practical exercise.')
        });
    }

    return (
        <>
            <StyledGuidelineEditor
                height={400}
                value={exercise.guideline}
                onChange={(e) => {
                    setExercise((oldState: PracticalExercise) => ({
                        ...oldState, guideline: e
                    }));
                }}
                previewOptions={{
                    disallowedElements: ['script', 'iframe']
                }}
                commands={[
                    // Custom Toolbars
                    ...commands.getCommands(),
                    commands.divider,
                    questionCommand,
                ]}/>
            <LoadingButton sx={{marginTop: 2}}
                           size="small"
                           disableElevation
                           onClick={handleSave}
                           loading={isUpdatingExercise}
                           variant="contained"
            >
                <span>SAVE</span>
            </LoadingButton>
        </>
    )
}

const ExerciseTrainerNotesEditor = ({exercise: initialExercise}: { exercise: PracticalExercise }) => {
    const [exercise, setExercise] = useState(initialExercise)
    const [updateExercise, {isLoading: isUpdatingExercise}] = useUpdatePracticalExerciseMutation();

    function handleSave() {
        updateExercise(exercise).unwrap().then(() => {
            SnackBarUtils.info('Practical exercise successfully modified')
        }).catch(() => {
            SnackBarUtils.error('Error happened while updating practical exercise.')
        });
    }

    return (
        <>
            <StyledGuidelineEditor
                height={400}
                value={exercise.trainerNotes}
                onChange={(e) => {
                    setExercise((oldState: PracticalExercise) => ({
                        ...oldState, trainerNotes: e
                    }));
                }}
                previewOptions={{
                    disallowedElements: ['script', 'iframe']
                }}
            />
            <LoadingButton sx={{marginTop: 2}}
                           size="small"
                           disableElevation
                           onClick={handleSave}
                           loading={isUpdatingExercise}
                           variant="contained"
            >
                <span>SAVE</span>
            </LoadingButton>
        </>
    )
}


const PracticalExerciseCatalogDetails: React.FunctionComponent<Props> = (props: Props) => {

    const {id} = useParams<{ id: any }>()
    const {
        data: exercise = {}
    } = useGetPracticalExerciseQuery(id);
    const [tabValue, setTabValue] = React.useState(0);
    const items: BreadcrumbItem[] = useMemo(() => [{title: 'Catalog Exercises', link: '/exercises'}, {
        title: 'Details',
        link: '/exercises/' + id
    }], [id]);
    React.useEffect(() => {
        props.breadCrumbItems(items);
    }, [items, props]);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
                <Item>
                    <CardHeader title={exercise.name}/>
                    <CardContent sx={{overflow: "auto", paddingY: 0}}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                                    <Tab label="GENERAL" {...a11yProps(0)} />
                                    <Tab label="INSTRUCTIONS" {...a11yProps(1)} />
                                    <Tab label="TRAINER NOTES" {...a11yProps(2)} />
                                    <Tab label="Q/A" {...a11yProps(3)} />
                                    <Tab label="PARAMETERS" {...a11yProps(4)}
                                         disabled={!exercise.inputs || exercise.inputs.length === 0}/>
                                    <Tab label="BADGE" {...a11yProps(5)} />
                                    <Tab label="RATINGS" {...a11yProps(6)} />
                                </Tabs>
                                <TabPanel value={tabValue} index={0}>
                                    <PracticalExerciseGeneral exercise={exercise}></PracticalExerciseGeneral>
                                </TabPanel>
                                <TabPanel value={tabValue} index={1}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <ExerciseGuideLineEditor exercise={exercise}></ExerciseGuideLineEditor>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tabValue} index={2}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <ExerciseTrainerNotesEditor exercise={exercise}></ExerciseTrainerNotesEditor>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tabValue} index={3}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <PracticalExerciseCatalogQuestionsEditor
                                                exercise={exercise}></PracticalExerciseCatalogQuestionsEditor>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tabValue} index={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <PracticalExerciseCatalogScriptConfigurationEditor
                                                exercise={exercise}></PracticalExerciseCatalogScriptConfigurationEditor>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tabValue} index={5}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <PracticalExerciseBadge exercise={exercise}></PracticalExerciseBadge>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tabValue} index={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <ReviewList ratings={exercise.ratings}></ReviewList>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Item>
            </Grid>
        </Grid>
    );
}

export default PracticalExerciseCatalogDetails;
