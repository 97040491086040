import React from "react";
import {useSelector} from "react-redux";

import {RootState} from "../slices";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {CardContent, CardHeader} from "@mui/material";
import moment from "moment/moment";
import {useGetTraineeTrainingSessionsQuery} from "../services/InstanceService";
import {Item} from "./utils/Utils";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

// Display the identity of the user
const UserInfo: React.FunctionComponent = () => {
    const isAdmin = useSelector((state: RootState) => state.user.isAdmin);
    const firstname = useSelector((state: RootState) => state.user.firstname);
    const lastname = useSelector((state: RootState) => state.user.lastname);
    const {
        data: traineeTrainingSessions = []
    } = useGetTraineeTrainingSessionsQuery();

    const nextSession = traineeTrainingSessions.find((session) => moment(new Date()).isBetween(session?.startDate, session?.endDate)) ? traineeTrainingSessions.find((session) => moment(new Date()).isBetween(session?.startDate, session?.endDate)) : traineeTrainingSessions.find((session) => moment(new Date()).isAfter(session?.endDate));
    return (
        <Grid container spacing={3} alignItems={"stretch"}>
            <Grid item xs={6} md={6} lg={6}>
                <Item>
                    <CardHeader title={
                        <Typography component="h2" variant="h6" color="primary">
                            Welcome {firstname} {lastname} !
                            {isAdmin ? " (admin)" : ""}
                        </Typography>
                    }/>

                    <CardContent>
                        <Typography component="h2" variant="subtitle1">
                            Cyber Academy is a training platform that deploy exercises for you to train and make you
                            aware of the challenges in cyber security.
                        </Typography>
                        <Typography component="h2" variant="subtitle1" gutterBottom>
                            You will learn how attackers are trying to break in a system and how to defend.
                        </Typography>
                    </CardContent>
                </Item>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <Item sx={{height: "100%", textAlign: "center"}}>
                    <CardHeader title={
                        <Typography component="h2" variant="h6" color="primary">
                            {moment(new Date()).isBetween(nextSession?.startDate, nextSession?.endDate) ? "Your current session" : "Your upcoming session"}
                        </Typography>
                    }/>

                    <CardContent>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sx={{textAlign: "center"}}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography component="h2" variant="subtitle1"
                                                    sx={{color: 'text.secondary', fontWeight: 700}}>
                                            {nextSession?.title}
                                        </Typography>
                                        <Typography fontSize={"small"} sx={{color: 'text.secondary', paddingLeft: 1}}>
                                            {
                                                nextSession ?
                                                    "Starts on " + moment(nextSession?.startDate).format("L LT")
                                                    : "No planned sessions yet."
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign: "center"}} mt={2}>
                                <Button variant="contained" disableElevation component={Link}
                                        to={nextSession?.id ? "/my-sessions?trainingId=" + nextSession.id : "/my-sessions"}>
                                    {
                                        nextSession ? "LET'S GO!" : "SEE PREVIOUS SESSIONS"
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Item>

            </Grid>
        </Grid>
    );
};

export default UserInfo;
