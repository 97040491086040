import React from 'react';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import PracticalExercise from "../../types/PracticalExercise";
import {useUpdatePracticalExerciseMutation} from "../../services/CatalogService";
import LoadingButton from "@mui/lab/LoadingButton";
import CustomizableInput from "../../types/CustomizableInput";
import {Checkbox, FormControlLabel, MenuItem} from "@mui/material";
import Typography from "@mui/material/Typography";
import SnackBarUtils from "../SnackBarUtils";

type Props = {
    exercise: PracticalExercise,
};

export const PracticalExerciseCatalogScriptConfigurationEditor: React.FunctionComponent<Props> = (
    {exercise: initialExercise}: Props
) => {

    const [exercise, setExercise] = React.useState<PracticalExercise>(initialExercise);
    const [updateExercise, {isLoading: isUpdatingExercise}] = useUpdatePracticalExerciseMutation();

    const handleSave = () => {
        updateExercise(exercise).unwrap().then(() => {
            SnackBarUtils.info('Script configuration successfully modified')
        }).catch(() => {
            SnackBarUtils.error('Error happened while updating script configuration.')
        });
    }

    const handleInputUpdate = (input: CustomizableInput) => {
        if (exercise?.inputs) {
            let nextInputs = exercise?.inputs.map(x => Object.assign({}, x));
            nextInputs.map((i: CustomizableInput) => {
                if (i.id === input.id) {
                    i.value = input.value;
                    return i;
                } else {
                    return i;
                }
            })
            setExercise((oldState: PracticalExercise) => ({
                ...oldState, inputs: nextInputs
            }));
        }
    }

    function renderInput(input: CustomizableInput) {

        switch (input.type) {
            case "BOOLEAN" :
                return (
                    <FormControlLabel control={
                        <Checkbox
                            checked={input.value === "true"}
                            onChange={(e) => {
                                handleInputUpdate({...input, value: e.target.checked ? "true" : "false"})
                            }}
                            inputProps={{'aria-label': 'controlled'}}
                        />} label=""/>

                );
            case "STRING" :
                return (
                    <TextField fullWidth
                               variant={"outlined"}
                               value={input.value}
                               onChange={(e) => {
                                   handleInputUpdate({...input, value: e.target.value})
                               }}
                    />);
            case "ENUM":
                return (<TextField
                    id="outlined-select-currency"
                    select
                    fullWidth
                    variant={"outlined"}
                    value={input.value}
                    defaultValue={input.value}
                    onChange={(e) => {
                        handleInputUpdate({...input, value: e.target.value})
                    }}
                >
                    {input.enumValues.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>);
            case "INTEGER":
                return (<TextField
                    id="outlined-number"
                    fullWidth
                    variant={"outlined"}
                    type="number"
                    value={input.value}
                    onChange={(e) => {
                        handleInputUpdate({...input, value: e.target.value})
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />)

        }
        return (<TextField
            fullWidth
            variant={"outlined"}
            value={input.value}
            onChange={(e) => {
                handleInputUpdate({...input, value: e.target.value})
            }}
        />)
    }

    return (
        <Grid container
              direction="row"
              justifyContent="flex-start"
              alignItems="center">
            {exercise?.inputs?.map((input, index) => (
                <Grid key={index + "-input"} item xs={12} p={2}>
                    <Grid container alignItems="center">
                        <Grid item xs={6}>
                            <Typography variant="body1"
                                        component="h2">{input.label}</Typography>
                            <Typography variant="body2"
                                        component="h2">{input.description}</Typography>
                        </Grid>
                        <Grid item xs={6} pl={1}>
                            {
                                renderInput(input)
                            }
                        </Grid>
                    </Grid>

                </Grid>

            ))}
            <LoadingButton sx={{marginTop: 2}}
                           size="small"
                           onClick={handleSave}
                           loading={isUpdatingExercise}
                           disableElevation
                           variant="contained"
            >
                <span>SAVE</span>
            </LoadingButton>
        </Grid>
    );
};