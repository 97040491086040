import Grid from "@mui/material/Grid";
import Rating from "../../types/Rating";
import Typography from "@mui/material/Typography";
import {StyledRating} from "../utils/Utils";
import Box from "@mui/material/Box";
import {Card, CardActions, CardContent, Tooltip} from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import moment from "moment";

interface ReviewListProps {
    ratings: Rating[] | undefined,
}

export const ReviewList = ({ratings}: ReviewListProps) => {

    function getGlobalRating(): number {
        if (ratings) {
            return (ratings.reduce((sum, current) => sum + current.note, 0) / ratings.length);
        }
        return 0;
    }

    function getGlobalRatingLabelText() {
        let globalRating = getGlobalRating();
        if (globalRating) {
            return `${getGlobalRating().toPrecision(2)}`;
        }
        return `Not rated`
    }

    const [expandedId, setExpandedId] = React.useState(-1);

    const handleExpandClick = (index: number) => {
        setExpandedId(expandedId === index ? -1 : index);
    };

    return (<Grid container rowSpacing={1} sx={{paddingTop: "20px"}}>
        <Grid item xs={12} md={12} lg={12}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <StyledRating size="large" name="read-only" getLabelText={getGlobalRatingLabelText}
                              value={getGlobalRating()}
                              readOnly/>
                <Box sx={{ml: 2, fontWeight: "800", fontSize: 25}}>{getGlobalRatingLabelText()}</Box>

            </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
            <Typography variant={"h6"}>Reviews :</Typography>
        </Grid>
        {ratings && ratings.length > 0 ? ratings.map((rating, index) => {
            return (<Grid item xs={4} p={1} key={"review-" + index}>
                <Card variant={"outlined"}>
                    <CardContent sx={{height: "100%", minHeight: "87px", paddingBottom: 0}}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <StyledRating name="read-only" value={rating.note}
                                          readOnly
                                          size="small"/>
                            <Box sx={{ml: 1}}>
                                <Tooltip
                                    title={moment(rating.creationDate).format("L LT")}
                                    placement="right">
                                    <span>{moment(rating.creationDate).fromNow()}</span>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Typography sx={{
                            overflow: expandedId !== index ? "hidden" : "inherit",
                            textOverflow: expandedId !== index ? "ellipsis" : "inherit",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: expandedId !== index ? "vertical" : "inherit",
                        }}
                                    component="legend"> {rating.comment}</Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button
                            onClick={() => handleExpandClick(index)}
                            aria-expanded={expandedId === index}
                            aria-label="show more"
                        >
                            {expandedId === index ? "SHOW LESS" : "SHOW MORE"}
                        </Button>
                    </CardActions>
                </Card>
            </Grid>)
        }) : "No reviews yet."}
    </Grid>)
}