import {Chip, CircularProgress, Stack, Tooltip} from "@mui/material";
import {useLazyGetInstancesWithFiltersQuery} from "../../services/InstanceService";
import React, {useEffect} from "react";
import {LocalFireDepartment, Rocket, RocketLaunch} from "@mui/icons-material";

type Props = {
    sessionId?: string;
    exerciseId?: string;
};

const PracticalExerciseStatusChip: React.FunctionComponent<Props> = (props: Props) => {

    const [triggerGetInstances,
        {
            data: instancesPage = {content: [], totalElements: 0},
            isLoading,
        }] = useLazyGetInstancesWithFiltersQuery({pollingInterval: 5000});

    useEffect(() => {
        props.exerciseId !== undefined && props.sessionId !== undefined && triggerGetInstances({
            pageNo: 0,
            pageSize: 10000,
            sessionId: props.sessionId,
            exerciseId: props.exerciseId
        }).unwrap().then(() => renderExerciseStatus());
    }, [props, triggerGetInstances]);

    function renderExerciseStatus() {
        return (
            <Stack direction="row" spacing={1}>
                <Tooltip title="Created instances" placement="bottom">
                    <Chip
                        label={instancesPage.content.filter(instance => instance.status === "CREATED").length}
                        icon={<Rocket/>}
                        variant="outlined"
                        size={"small"}
                        color="default"
                    />
                </Tooltip>
                <Tooltip title="Starting instances" placement="bottom">
                    <Chip
                        label={instancesPage.content.filter(instance => instance.status === "STARTING").length}
                        icon={<RocketLaunch/>}
                        variant="outlined"
                        size={"small"}
                        color="info"
                    />
                </Tooltip>
                <Tooltip title="Started instances" placement="bottom">
                    <Chip
                        label={instancesPage.content.filter(instance => instance.status === "STARTED").length}
                        icon={<RocketLaunch sx={{transform: "rotate(45deg)"}}/>}
                        variant="outlined"
                        size={"small"}
                        color={"success"}
                    />
                </Tooltip>
                <Tooltip title="Destroying instances" placement="bottom">
                    <Chip
                        label={instancesPage.content.filter(instance => instance.status === "DESTROYING").length}
                        icon={<RocketLaunch sx={{transform: "rotate(90deg)"}}/>}
                        variant="outlined"
                        size={"small"}
                        color="info"
                    />
                </Tooltip>
                <Tooltip title="Destroyed instances" placement="bottom">
                    <Chip
                        label={instancesPage.content.filter(instance => instance.status === "DESTROYED").length}
                        icon={<Rocket/>}
                        variant="outlined"
                        size={"small"}
                        color="warning"
                    />
                </Tooltip>
                <Tooltip title="Failed instances" placement="bottom">
                    <Chip
                        label={instancesPage.content.filter(instance => instance.status === "FAILED").length}
                        icon={<LocalFireDepartment/>}
                        variant="outlined"
                        size={"small"}
                        color="error"
                    />
                </Tooltip>
            </Stack>
        )
    }

    return (
        isLoading ? <CircularProgress size={30} color="primary"/> : renderExerciseStatus()
    );
}

export default PracticalExerciseStatusChip;
