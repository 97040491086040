import {CircularProgress} from "@mui/material";
import React from "react";
import Answer from "../../types/Answer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type Props = {
    survey?: Answer[];
};

const TraineeSessionStatusChip: React.FunctionComponent<Props> = ({survey}) => {
    function calculateInstanceProgression() {
        let nbOfSuccess = 0;
        survey?.forEach((answer) => {
            answer.correct && nbOfSuccess++;
        })
        if (survey?.length) {
            return (nbOfSuccess / survey?.length) * 100
        } else {
            return 0;
        }

    }

    function calculateProgressionLabel() {
        let nbOfSuccess = 0;
        survey?.forEach((answer) => {
            answer.correct && nbOfSuccess++;
        })
        if (survey?.length) {
            return nbOfSuccess + "/" + survey?.length
        } else {
            return "";
        }

    }

    return (
        survey?.length && survey.length > 0 ?
            <Box sx={{position: 'relative', display: 'inline-flex'}}>
                <CircularProgress color={calculateInstanceProgression() === 100 ? "success" : "primary"}
                                  variant="determinate" value={calculateInstanceProgression()}/>
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color={calculateInstanceProgression() === 100 ? "green" : "primary"}
                    >{calculateProgressionLabel()}</Typography>
                </Box>
            </Box>
            :
            <Box sx={{position: 'relative', display: 'inline-flex'}}>
                <CircularProgress sx={{color: "rgba(224, 224, 224, 1)"}} variant="determinate" value={100}/>
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color={"info"}
                    >{"N/A"}</Typography>
                </Box>
            </Box>
    );
}

export default TraineeSessionStatusChip;
