import React from 'react';
import Grid from "@mui/material/Grid";
import PracticalExerciseCatalogList from "../components/catalog/PracticalExerciseCatalogList";

type Props = {
    pageTitle: (arg: string) => void,
};

const PracticalExercises: React.FunctionComponent<Props> = ({pageTitle}) => {
    React.useEffect(() => {
        pageTitle("Catalog - Practical exercises");
    });
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <PracticalExerciseCatalogList/>
            </Grid>
        </Grid>
    );
};

export default PracticalExercises;
