import React from 'react';

import Grid from "@mui/material/Grid";
import TraineeTrainingsOverview from "../components/trainee/TraineeTrainingsOverview";

type Props = {
    pageTitle: (arg: string) => void
};

const TraineeTrainings: React.FunctionComponent<Props> = ({pageTitle}) => {

    React.useEffect(() => {
        pageTitle("My trainings");
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <TraineeTrainingsOverview/>
            </Grid>
        </Grid>
    );
};

export default TraineeTrainings;
