import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {Navigate} from 'react-big-calendar'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'

export default function CustomWeekView({
                                           date,
                                           localizer,
                                           max = new Date().setHours(21, 0, 0, 0),
                                           min = new Date().setHours(8, 0, 0, 0),
                                           scrollToTime = localizer.startOf(new Date(), 'day'),
                                           ...props
                                       }) {
    const currRange = useMemo(
        () => CustomWeekView.range(date, {localizer}),
        [date, localizer]
    )

    return (
        <TimeGrid
            date={date}
            eventOffset={15}
            localizer={localizer}
            max={max}
            min={min}
            range={currRange}
            scrollToTime={scrollToTime}
            {...props}
        />
    )
}

CustomWeekView.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    localizer: PropTypes.object,
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    scrollToTime: PropTypes.instanceOf(Date),
}

CustomWeekView.range = (date, {localizer}) => {
    let firstOfWeek = localizer.startOfWeek()
    let start = localizer.startOf(date, 'week', firstOfWeek)
    let end = localizer.endOf(date, 'week', firstOfWeek)

    return localizer.range(start, end)
}

CustomWeekView.navigate = (date, action, {localizer}) => {
    switch (action) {
        case Navigate.PREVIOUS:
            return localizer.add(date, -1, 'week')

        case Navigate.NEXT:
            return localizer.add(date, 1, 'week')

        default:
            return date
    }
}

CustomWeekView.title = (date, {localizer}) => {
    const [start, ...rest] = CustomWeekView.range(date, {localizer})
    return localizer.format({start, end: rest.pop()}, 'dayRangeHeaderFormat')
}