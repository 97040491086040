import {createSlice} from "@reduxjs/toolkit";

const configSlice = createSlice({
    name: "config",
    initialState: {
        idpUrl: "",
        userProfileUrl: "",
        clientId: "",
        realmName: "",
    },
    reducers: {
        setOauthConfig: (state, action) => {
            state.idpUrl = action.payload.idpUrl;
            state.userProfileUrl = action.payload.userProfileUrl;
            state.clientId = action.payload.clientId;
            state.realmName = action.payload.realmName;
        }
    },
});

export {configSlice};
