import React, {useState} from 'react';
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import {Autocomplete, List, ListItem} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import LearningPath from "../../../types/LearningPath";
import {useGetTrainingModulesQuery, useUpdateLearningPathMutation} from "../../../services/CatalogService";
import TrainingModuleCatalog from "../../../types/TrainingModuleCatalog";
import SnackBarUtils from "../../SnackBarUtils";
import TextField from "@mui/material/TextField";
import TrainingModule from "../../../types/TrainingModule";

type Props = {
    learningPath: LearningPath,
};

export const LearningPathCatalogModuleList: React.FunctionComponent<Props> = (
    {learningPath: initialLearningPath}: Props
) => {

    const [learningPath, setLearningPath] = React.useState<LearningPath>(initialLearningPath);
    const {
        data: availableModules = {content: [], totalElements: 0},
    } = useGetTrainingModulesQuery({pageNo: 0, pageSize: 1000});
    const [updateLearningPath, {isLoading: isUpdatingLearningPath}] = useUpdateLearningPathMutation();
    const [autocompleteValue, setAutocompleteValue] = useState<TrainingModule>({name: ""})

    const handleSave = () => {
        updateLearningPath(learningPath).unwrap().then(() => {
            SnackBarUtils.info('Learning path successfully modified')
        });
    }

    const removeModule = (moduleToRemove: TrainingModuleCatalog | undefined) => {
        setLearningPath((oldState: LearningPath) => ({
            ...oldState,
            trainingModules: oldState.trainingModules?.filter((module) => module?.id !== moduleToRemove?.id)
        }));
    }

    const handleNewModuleSelection = (moduleToAdd: TrainingModuleCatalog) => {
        setLearningPath((oldState: LearningPath) => ({
            ...oldState,
            trainingModules: oldState.trainingModules ? oldState.trainingModules.concat(moduleToAdd) : new Array(1).fill(moduleToAdd)
        }));
    }

    return (
        <Grid>
            <List>
                {
                    learningPath?.trainingModules?.map((trainingModule) => {
                        return <ListItem
                            key={trainingModule?.name}
                            secondaryAction={
                                <Grid container>
                                    <IconButton edge="end" value={trainingModule?.name}
                                                onClick={() => removeModule(trainingModule)}
                                                aria-label="delete">
                                        <DeleteIcon color={"primary"}/>
                                    </IconButton>
                                </Grid>
                            }
                        >
                            <ListItemText
                                sx={{
                                    "& .MuiListItemText-root": {
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }
                                }}
                                primary={trainingModule?.name}
                            />
                        </ListItem>
                    })
                }

            </List>
            <Autocomplete
                disablePortal
                id="add-exercise"
                fullWidth
                options={availableModules.content}
                getOptionDisabled={(option) =>
                    availableModules
                        ? learningPath?.trainingModules?.findIndex(module => module?.id === option.id) !== -1
                        : false
                }
                getOptionLabel={(option) => option.name ?? ""}
                renderInput={(params) =>
                    <TextField {...params} label="Add module"/>}
                onChange={(event: any, newModule: TrainingModuleCatalog | null | undefined) => {
                    if (newModule != null) handleNewModuleSelection(newModule);
                    setAutocompleteValue({name: ""})
                }}
                isOptionEqualToValue={() => true}
                value={autocompleteValue}
            />
            <LoadingButton sx={{marginTop: 2}}
                           size="small"
                           onClick={handleSave}
                           loading={isUpdatingLearningPath}
                           disableElevation
                           variant="contained"
            >
                <span>SAVE</span>
            </LoadingButton>
        </Grid>
    );
};