import React from 'react';

import Grid from "@mui/material/Grid";
import TrainingSessionPresentation from "../components/trainingSession/TrainingSessionPresentation";

type Props = {
    pageTitle: (arg: string) => void
};

const TrainingSessionPresentationPage: React.FunctionComponent<Props> = ({pageTitle}) => {

    React.useEffect(() => {
        pageTitle("Presentation mode");
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <TrainingSessionPresentation/>
            </Grid>
        </Grid>
    );
};

export default TrainingSessionPresentationPage;
