import Question from "./Question";
import Badge from "./Badge";
import TrainingModule from "./TrainingModule";
import Rating from "./Rating";
import CustomizableInput from "./CustomizableInput";

export enum ExerciseStatus {
    UPCOMING = "UPCOMING",
    LIVE = "LIVE",
    FINISHED = "FINISHED",
}

export default interface PracticalExercise {
    id?: string | "",
    name?: string,
    description?: string,
    status?: ExerciseStatus,
    scriptId?: string,
    guideline?: string,
    trainerNotes?: string,
    questions?: Question[],
    badge?: Badge,
    exerciseOrder?: number,
    deploymentDuration?: number,
    exerciseDuration?: number,
    level?: string,
    trainingModule?: TrainingModule,
    ratings?: Rating[],
    inputs?: CustomizableInput[]
}