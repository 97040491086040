import React, {useState} from 'react';
import Typography from "@mui/material/Typography";
import {Card, CardContent, CardMedia, CircularProgress, Drawer, styled} from "@mui/material";
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import {useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    timelineOppositeContentClasses,
    TimelineSeparator
} from "@mui/lab";
import {
    ChevronLeft,
    ChevronRight,
    Close,
    EmojiEvents,
    EventAvailableOutlined,
    LaptopMacOutlined,
    Today
} from "@mui/icons-material";
import moment from "moment/moment";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TrainingModule from "../../types/TrainingModule";
import PracticalExercise, {ExerciseStatus} from "../../types/PracticalExercise";
import Box from "@mui/material/Box";
import {
    useGetTrainingSessionQuery,
    useLazyGetTrainingSessionExerciseGuidelineQuery,
    useLazyGetTrainingSessionQuery,
    useUpdateTrainingSessionExerciseStatusMutation
} from "../../services/TrainingSessionService";
import TraineeExerciseStatusChip from "../trainee/TraineeExerciseStatusChip";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import {StyledGuidelineViewer} from "../utils/Utils";
import TextField from "@mui/material/TextField";
import {useTheme} from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import ExerciseLeaderboard from "../practicalExercise/ExerciseLeaderboard";
import CssBaseline from "@mui/material/CssBaseline";
import TrainingSession from "../../types/TrainingSession";


const drawerWidthPercent = 30;

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
}>(({theme, open}) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidthPercent + '%',
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidthPercent}%)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidthPercent + '%',
    }),
}));

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-start',
    ...theme.mixins.toolbar,
}));

function getTimelineColor(module: TrainingModule): string {
    if (moment(new Date()).isAfter(module.endDate)) {
        return "grey";
    } else if (moment(new Date()).isBetween(module.startDate, module.endDate)) {
        return "green"
    }
    return "#aaa"
}

type ExerciseGuidelineAndQuestionsProps = {
    exercise: PracticalExercise,
    guideline: string,
    isGuidelineLoading: boolean
};
export const ExerciseGuidelineAndQuestions: React.FunctionComponent<ExerciseGuidelineAndQuestionsProps> = ({
                                                                                                               exercise,
                                                                                                               guideline,
                                                                                                               isGuidelineLoading
                                                                                                           }) => {
    const theme = useTheme();
    return (
        <Grid item xs={12}>
            {
                isGuidelineLoading ?
                    <CircularProgress size={32} color="primary"/> :
                    guideline && guideline.split("???QUESTION???").map((guidelinePart, index) => {
                        let answer = exercise?.questions?.find((survey) => survey.sequence === index);
                        if (answer) {
                            return (<React.Fragment key={"question-part-" + index}>
                                <StyledGuidelineViewer
                                    disallowedElements={['script', 'iframe']}
                                    style={{
                                        marginTop: 10, marginBottom: 10
                                    }}
                                    source={guidelinePart}/>
                                <Grid item xs={12} md={12} lg={12}
                                      sx={{
                                          backgroundColor: theme.palette.background.paper,
                                          padding: "1em",
                                          borderRadius: "10px",
                                          border: "2px solid " + theme.palette.primary.main
                                      }}>
                                    <TextField
                                        sx={{minWidth: '100%'}}
                                        label={answer.question}
                                        placeholder={"Answer format: " + answer.answerFormat}
                                        value={""}
                                        variant="standard"
                                        color={"primary"}
                                        focused

                                    />
                                </Grid>
                            </React.Fragment>)
                        } else return (
                            <StyledGuidelineViewer key={"question-part-" + index}
                                                   disallowedElements={['script', 'iframe']}
                                                   style={{
                                                       marginTop: 10, marginBottom: 10
                                                   }}
                                                   source={guidelinePart}/>)
                    })
            }
        </Grid>
    )
}

type TrainingModuleTimelineProps = {
    trainingModule: TrainingModule,
    trainingSessionId: string
};

type ExercisePresentationDialogProps = {
    exercise: PracticalExercise
};

const ExercisePresentationDialog: React.FunctionComponent<ExercisePresentationDialogProps> = ({
                                                                                                  exercise: initialExercise
                                                                                              }) => {
    const [exercise, setExercise] = useState(initialExercise);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const {trainingSessionId} = useParams<{ trainingSessionId: any }>()

    const [triggerGetTrainingSessionQuery] = useLazyGetTrainingSessionQuery();
    const [updateExerciseStatus] = useUpdateTrainingSessionExerciseStatusMutation();

    const [
        triggerGetPracticalExerciseGuidelineQuery, {
            data: guideline = "",
            isLoading: isGuidelineLoading
        }
    ] = useLazyGetTrainingSessionExerciseGuidelineQuery();

    React.useEffect(() => {
        setExercise(initialExercise);
        triggerGetPracticalExerciseGuidelineQuery({exerciseId: initialExercise?.id});
    }, [initialExercise]);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [openDrawer, setOpenDrawer] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    async function handleLive() {
        triggerGetTrainingSessionQuery(trainingSessionId).unwrap().then((response: TrainingSession) => {
            let upcoming = false;
            response.trainingModules?.forEach((currentModule: TrainingModule) => {
                return currentModule.practicalExercises?.forEach((currentExercise: PracticalExercise) => {
                    if (currentExercise.id === exercise.id) {
                        currentExercise?.id && updateExerciseStatus({
                            sessionId: trainingSessionId,
                            exerciseId: currentExercise.id,
                            status: ExerciseStatus.LIVE
                        });
                        upcoming = true;
                    } else if (!upcoming && currentExercise.status !== ExerciseStatus.FINISHED) {
                        currentExercise?.id && updateExerciseStatus({
                            sessionId: trainingSessionId,
                            exerciseId: currentExercise.id,
                            status: ExerciseStatus.FINISHED
                        });
                    } else if (upcoming && currentExercise.status !== ExerciseStatus.UPCOMING) {
                        currentExercise?.id && updateExerciseStatus({
                            sessionId: trainingSessionId,
                            exerciseId: currentExercise.id,
                            status: ExerciseStatus.UPCOMING
                        });
                    }
                })
            })
        })

    }


    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    return (
        <>
            <Button variant="contained" size={"small"} onClick={handleClickOpen}>
                Details
            </Button>
            <Dialog PaperProps={{
                style: {
                    backgroundColor: theme.palette.background.paper,
                    backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"
                },
            }}
                    fullScreen
                    open={open}
                    onClose={handleClose}
            >
                <Box sx={{display: 'flex'}}>
                    <CssBaseline/>
                    <AppBar position="fixed" open={openDrawer}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <Close/>
                            </IconButton>
                            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div"
                                        color={theme.palette.mode === "dark" ? "primary" : "inherit"}>
                                {exercise.name}
                            </Typography>
                            <Button sx={{
                                fontWeight: "700",
                                color: exercise.status === "LIVE" ? "red" : "inherit",
                                borderColor: exercise.status === "LIVE" ? "red" : "none"
                            }}
                                    disabled={exercise.status === "LIVE"}
                                    onClick={() => handleLive()}

                                    startIcon={exercise.status === "LIVE" ? <svg height="10" width="15"
                                                                                 className={exercise.status === "LIVE" ? "blinking" : ""}>
                                        <circle cx="10" cy="5" r="4"
                                                fill={exercise.status === "LIVE" ? "red" : "inherit"}/>
                                    </svg> : <></>}

                            >
                                LIVE
                            </Button>
                            <IconButton
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerOpen}
                                sx={{...(openDrawer && {display: 'none'}), color: "gold"}}
                            >
                                <EmojiEvents/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Main open={openDrawer}>
                        <DrawerHeader/>
                        <ExerciseGuidelineAndQuestions exercise={exercise} guideline={guideline}
                                                       isGuidelineLoading={isGuidelineLoading}/>
                    </Main>
                    <Drawer
                        sx={{
                            width: drawerWidthPercent + '%',
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                overflow: 'visible',
                                border: "none",
                                backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                                width: drawerWidthPercent + '%',
                            },
                        }}
                        variant="persistent"
                        anchor="right"
                        open={openDrawer}
                    >
                        <DrawerHeader>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronLeft/> : <ChevronRight/>}
                            </IconButton>
                            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div"
                                        color={"primary"}>
                                Leaderboard
                            </Typography>

                        </DrawerHeader>
                        {exercise?.id && <ExerciseLeaderboard trainingSessionId={trainingSessionId}
                                                              exerciseId={exercise.id}></ExerciseLeaderboard>}

                    </Drawer>
                </Box>
            </Dialog></>
    )
}

const TrainingModuleTimeline: React.FunctionComponent<TrainingModuleTimelineProps> = ({
                                                                                          trainingModule
                                                                                      }) => {
    const [module, setModule] = useState(trainingModule)
    React.useEffect(() => {
        setModule(trainingModule)
    }, [trainingModule]);

    function getDotColor(module: TrainingModule, exerciseStatus: ExerciseStatus | undefined): "primary" | "grey" | "error" | "success" {
        switch (exerciseStatus) {
            case ExerciseStatus.FINISHED:
                if (moment(new Date()).isAfter(module.endDate)) {
                    return "grey";
                }
                return "success"
            case ExerciseStatus.LIVE:
                return "error"
            case ExerciseStatus.UPCOMING:
                return "primary"
        }
        return "grey"
    }

    return (
        <>
            {
                module.practicalExercises?.map((currentPracticalExercise, index) => {
                    return (
                        <TimelineItem key={"timeline-exercise-" + index}>
                            <TimelineOppositeContent
                                sx={{m: 'auto 0', marginTop: 0, paddingRight: "1.8em"}}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                <TraineeExerciseStatusChip
                                    status={currentPracticalExercise.status}></TraineeExerciseStatusChip>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <Grid direction="column" container
                                      justifyContent="center"
                                      alignItems="center">
                                    <Grid item>
                                        <TimelineDot
                                            color={getDotColor(module, currentPracticalExercise.status)}/>
                                    </Grid>
                                </Grid>
                                <TimelineConnector
                                    sx={{bgcolor: getTimelineColor(module)}}/>
                            </TimelineSeparator>
                            <TimelineContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={8} container>
                                        <Grid item container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexWrap: 'wrap',
                                                }}>
                                                    <Typography sx={{paddingRight: 1}}
                                                                component="label">
                                                        {currentPracticalExercise.name}
                                                    </Typography>
                                                </div>
                                                <Typography variant="body2"
                                                            color="text.secondary">
                                                    {currentPracticalExercise.description}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <ExercisePresentationDialog
                                            exercise={currentPracticalExercise}></ExercisePresentationDialog>
                                    </Grid>
                                </Grid>
                            </TimelineContent>
                        </TimelineItem>
                    )
                })
            }</>
    )
}

const TrainingSessionPresentation: React.FunctionComponent = () => {

        const {trainingSessionId} = useParams<{ trainingSessionId: any }>()

        const {
            data: trainingSession = {
                id: '', title: '', startDate: new Date(),
                endDate: new Date(),
                trainer: '',
                trainees: []
            }, isLoading: isLoadingSession
        } = useGetTrainingSessionQuery(trainingSessionId, {pollingInterval: 5000});

        function getModuleIcon(module: TrainingModule) {
            if (moment(new Date()).isAfter(module.endDate)) {
                return <EventAvailableOutlined/>
            } else if (moment(new Date()).isBetween(module.startDate, module.endDate)) {
                return <LaptopMacOutlined sx={{color: "white"}}/>
            } else {
                return <Today/>
            }
        }


        function getSeparatorColor(module: TrainingModule): "grey" | "success" | "primary" {
            if (moment(new Date()).isAfter(module.endDate)) {
                return "grey";
            } else if (moment(new Date()).isBetween(module.startDate, module.endDate)) {
                return "success"
            }
            return "primary"
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Card sx={{display: 'flex'}}>
                        <CardMedia
                            component="img"
                            sx={{width: 151}}
                            image={trainingSession.imageLink}
                            alt="Training session image"
                        />
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <CardContent sx={{flex: '1 0 auto'}}>
                                <Typography component="div" variant="h5" color={"primary"}>
                                    {trainingSession.title}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    {trainingSession.description}
                                </Typography>
                            </CardContent>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Timeline sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: 0.2,
                            },
                        }}>
                            {
                                trainingSession?.trainingModules?.map((module, index) => {
                                    return (
                                        <React.Fragment key={"timeline-" + index}>
                                            <TimelineItem key={"timeline-module-" + index}>
                                                <TimelineOppositeContent
                                                    sx={{m: '18px 0'}}
                                                    align="right"
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    {moment(module.startDate).format("L LT")}
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot color={getSeparatorColor(module)}>
                                                        {getModuleIcon(module)}
                                                    </TimelineDot>
                                                    <TimelineConnector sx={{bgcolor: getTimelineColor(module)}}/>
                                                </TimelineSeparator>
                                                <TimelineContent sx={{py: '12px', px: 2}}>
                                                    <Typography variant="h6" component="span" color={"primary"}>
                                                        {module.name}
                                                    </Typography>
                                                    <Typography>{module.description}</Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                            {
                                                trainingSession.id &&
                                                <TrainingModuleTimeline
                                                    trainingSessionId={trainingSession.id}
                                                    trainingModule={module}>
                                                </TrainingModuleTimeline>
                                            }
                                        </React.Fragment>)
                                })
                            }
                            {
                                isLoadingSession &&
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} lg={12} sx={{textAlign: "center"}}>
                                        <CircularProgress/>
                                    </Grid>
                                </Grid>
                            }
                        </Timeline>
                    </Paper>
                </Grid></Grid>
        );
    }
;

export default TrainingSessionPresentation;
