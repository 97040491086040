import React from 'react';

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {
    useGetBackendVersionQuery,
    useGetExercisesVersionQuery,
    useGetModulesVersionQuery
} from "../services/ConfigService";

type Props = {
    pageTitle: (arg: string) => void,
};

const Versions: React.FunctionComponent<Props> = ({pageTitle}) => {

    const {data: backendVersion} = useGetBackendVersionQuery();
    const {data: versionedModules} = useGetModulesVersionQuery();
    const {data: versionedExercises} = useGetExercisesVersionQuery();
    const [frontVersion] = React.useState(process.env.REACT_APP_VERSION);

    React.useEffect(() => {
        pageTitle("Versions");
    });

    const theme = useTheme();

    return (
        <Grid container spacing={3}>

            <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: theme.palette.background.paper,
                }}>
                    <Grid item xs={12}>
                        <Typography component="p" variant="h6" color="primary">
                            Versions
                        </Typography>
                    </Grid>
                    <Grid item xs={12} p={0.5}>
                        <Typography component="p" variant="body1">
                            Application
                        </Typography>
                        <Typography component="p" variant="body2" key={"backend-version"}>
                            Backend : {backendVersion}
                        </Typography>
                        <Typography component="p" variant="body2" key={"front-version"}>
                            Front : {frontVersion}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} p={0.5}>
                        <Typography component="div" variant="body1">
                            Modules
                        </Typography>
                        {
                            versionedModules?.map((module) => {
                                return (
                                    <Typography component="p" variant="body2" key={module.name + "-version"}>
                                        {module.name} : {module.version} ({module.release})
                                    </Typography>)
                            })
                        }
                    </Grid>
                    <Grid item xs={12} p={0.5}>
                        <Typography component="div" variant="body1">
                            Exercises
                        </Typography>
                        {
                            versionedExercises?.map((exercise) => {
                                return (
                                    <Typography component="p" variant="body2" key={exercise.name + "-version"}>
                                        {exercise.name} : {exercise.version} ({exercise.release})
                                    </Typography>)
                            })
                        }
                    </Grid>
                </Paper>
            </Grid>
        </Grid>

    )
        ;
};

export default Versions;
