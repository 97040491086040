import React, {useState} from 'react';
import {ListItem, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import {Refresh} from "@mui/icons-material";
import {
    useGenerateNewAccessCodeMutation,
    useLazyGetTrainingSessionAccessCodeQuery
} from "../../services/TrainingSessionService";
import moment from "moment";
import {copyTextToClipboard} from "../utils/Utils";
import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Typography from "@mui/material/Typography";

type Props = {
    trainingSessionId: string
};

const AccessCodeViewer: React.FunctionComponent<Props> = ({trainingSessionId}: Props) => {

    const [triggerGetAccessCode, {
        data: accessCode
    }] = useLazyGetTrainingSessionAccessCodeQuery();
    const [generateNewAccessCode, {isLoading: isGeneratingCode}] = useGenerateNewAccessCodeMutation();
    const [isCopied, setIsCopied] = useState(false);

    React.useEffect(() => {
        trainingSessionId && triggerGetAccessCode(trainingSessionId);
    }, [trainingSessionId]);

    const handleGenerateNewAccessCode = () => {
        trainingSessionId && generateNewAccessCode(trainingSessionId)
    }

    const handleCopyClick = (accessCode: string | undefined) => {
        // Asynchronously call copyTextToClipboard
        if (typeof window !== 'undefined') {
            let joinUrl = window.location.protocol + '//' + window.location.host + '/my-trainings/join/';
            copyTextToClipboard(joinUrl + accessCode)
                .then(() => {
                    // If successful, update the isCopied state value
                    setIsCopied(true);
                    setTimeout(() => {
                        setIsCopied(false);
                    }, 1500);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <React.Fragment>
            <ListItem sx={{padding: 0}}>
                <ListItemText secondaryTypographyProps={{component: "div"}}
                              primary={
                                  <div style={{
                                      display: 'flex'
                                  }}>
                                      <Typography noWrap fontWeight={550}>Access
                                          code &nbsp;</Typography>
                                      {moment().isAfter(accessCode?.expirationDate) ?
                                          <Typography noWrap color="error">(expired)</Typography> :
                                          <Typography noWrap>(expires
                                              in {moment.utc(moment.duration(moment(accessCode?.expirationDate).diff((moment(new Date())))).asMilliseconds()).format("mm[min]")})
                                          </Typography>}
                                  </div>}
                              secondary={<div style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexWrap: 'wrap',
                              }}>
                                  {
                                      accessCode?.code ?
                                          <Typography component="div">{accessCode?.code}</Typography>
                                          :
                                          <Typography>No access code</Typography>
                                  }
                                  <Tooltip title="Regenerate" placement="bottom-start">
                                      <IconButton disabled={isGeneratingCode}
                                                  onClick={handleGenerateNewAccessCode}
                                                  color="primary">
                                          <Refresh/>
                                      </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Copy access link" placement="bottom-start">
                                      <IconButton disabled={!accessCode?.code}
                                                  onClick={() => handleCopyClick(accessCode?.code)} color="primary">
                                          {isCopied ? <CheckIcon color="success"/> : <ContentCopyIcon/>}
                                      </IconButton>
                                  </Tooltip>
                              </div>}
                />
            </ListItem>
        </React.Fragment>

    );
};

export default AccessCodeViewer;
