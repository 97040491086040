import {Chip, Tooltip} from "@mui/material";
import React from "react";
import {LocalFireDepartment, Rocket, RocketLaunch} from "@mui/icons-material";
import {useCountInstancesQuery} from "../../services/InstanceService";
import Link from "@mui/material/Link";

const InstancesCount: React.FunctionComponent = () => {

    const {
        data: startingInstancesCount = 0,
    } = useCountInstancesQuery({filter: "status=STARTING"}, {pollingInterval: 5000});
    const {
        data: startedInstancesCount = 0,
    } = useCountInstancesQuery({filter: "status=STARTED"}, {pollingInterval: 5000});
    const {
        data: failedInstancesCount = 0,
    } = useCountInstancesQuery({filter: "status=FAILED"}, {pollingInterval: 5000});

    return (
        <>
            {startingInstancesCount > 0 &&
                <Tooltip title="Starting instances" placement="bottom">
                    <Link href={'/instances?status=STARTING'}>
                        <Chip
                            label={startingInstancesCount}
                            icon={<Rocket/>}
                            variant="outlined"
                            color={"primary"}
                            sx={{marginRight: 2, cursor: "pointer"}}
                        />
                    </Link>
                </Tooltip>
            }
            {startedInstancesCount > 0 &&
                <Tooltip title="Started instances" placement="bottom">
                    <Link href={'/instances?status=STARTED'}>
                        <Chip
                            label={startedInstancesCount}
                            icon={<RocketLaunch/>}
                            variant="outlined"
                            color={"success"}
                            sx={{marginRight: 2, cursor: "pointer"}}
                        />
                    </Link>
                </Tooltip>
            }
            {failedInstancesCount > 0 &&
                <Tooltip title="Failed instances" placement="bottom">
                    <Link href={'/instances?status=FAILED'}>
                        <Chip
                            label={failedInstancesCount}
                            icon={<LocalFireDepartment/>}
                            variant="outlined"
                            color="error"
                            sx={{marginRight: 2, cursor: "pointer"}}
                        />
                    </Link>
                </Tooltip>
            }
        </>
    );
}

export default InstancesCount;
