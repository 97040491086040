import React from 'react';

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {
    Timeline,
    TimelineConnector, TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent, timelineOppositeContentClasses,
    TimelineSeparator
} from "@mui/lab";
import {useGetEventsQuery, useLazyGetEventLongDescriptionQuery} from "../services/EventService";
import moment from "moment/moment";
import {Level} from "../types/Event";
import Event from "../types/Event";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Ansi from "ansi-to-react";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

type Props = {
    pageTitle: (arg: string) => void,
};

const Events: React.FunctionComponent<Props> = ({pageTitle}) => {

    const [openLongDescriptionDialog, setOpenLongDescriptionDialog] = React.useState(false);
    const [selectedEvent, setSelectedEvent] = React.useState<Event>({});
    const [levelFilter, setLevelFilter] = React.useState<string>("");
    const {
        data: events = [],
    } = useGetEventsQuery({page: 1, level: levelFilter}, {pollingInterval: 2000});
    const [triggerGetEventLongDescription] = useLazyGetEventLongDescriptionQuery();

    React.useEffect(() => {
        pageTitle("Logbook");
    });

    const handleChangeLevelFilter = (event: SelectChangeEvent) => {
        setLevelFilter(event.target.value as string);
    };

    const handleClickOpenLongDescriptionDialog = (event: Event) => {
        if (event.id) {
            triggerGetEventLongDescription(event.id).unwrap().then((data) => {
                setSelectedEvent({...event, longDescription: data});
                setOpenLongDescriptionDialog(true);
            });
        }
    };

    const handleLongDescriptionDialogClose = () => {
        setSelectedEvent({});
        setOpenLongDescriptionDialog(false);
    };

    const beautifyLogs = (logs: string) => {
        return logs?.split("\n").map((logLine, index) => <li key={index}><Ansi>{logLine}</Ansi></li>)
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <Typography component="div" variant="h6" color="primary">
                                Logbook
                            </Typography>
                        </Grid>
                        <Grid item xs={2} alignItems={"right"}>
                            <FormControl fullWidth size={"small"}>
                                <InputLabel id="select-level">Level</InputLabel>
                                <Select
                                    labelId="select-level"
                                    value={levelFilter}
                                    label="Level"
                                    onChange={handleChangeLevelFilter}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={"INFO"}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemIcon>
                                                <InfoIcon color={"primary"}/>
                                            </ListItemIcon>
                                            <ListItemText primary={"INFO"}/>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={"WARNING"}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemIcon>
                                                <ReportProblemIcon color={"warning"}/>
                                            </ListItemIcon>
                                            <ListItemText primary={"WARNING"}/>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value={"ALERT"}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ListItemIcon>
                                                <ErrorIcon color={"error"}/>
                                            </ListItemIcon>
                                            <ListItemText primary={"ALERT"}/>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Timeline
                        sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: 0.2,
                            },
                        }}>
                        {events.map((event, index) => (
                            <TimelineItem key={'timeline-' + index}>
                                <TimelineOppositeContent
                                    sx={{m: 'auto 0'}}
                                    align="right"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    <Tooltip title={moment(event.createdDate).format("L LT")}
                                             placement="right">
                                        <span>{moment(event.createdDate).fromNow()}</span>
                                    </Tooltip>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    {index !== 0 &&
                                        <TimelineConnector/>
                                    }

                                    {event.level === Level.INFO &&
                                        <TimelineDot color="primary">
                                            <InfoIcon/>
                                        </TimelineDot>
                                    }
                                    {event.level === Level.WARNING &&
                                        <TimelineDot color="warning">
                                            <ReportProblemIcon/>
                                        </TimelineDot>
                                    }
                                    {event.level === Level.ALERT &&
                                        <TimelineDot color="error">
                                            <ErrorIcon/>
                                        </TimelineDot>
                                    }
                                    {index < events.length - 1 &&
                                        <TimelineConnector/>
                                    }
                                </TimelineSeparator>
                                <TimelineContent sx={{"marginTop": "10px", "marginBottom": "10px"}}>
                                    <Typography variant={"body2"} sx={{fontWeight: "600"}}>
                                        {event.title}
                                    </Typography>
                                    <Typography variant={"body2"}
                                                sx={{fontStyle: "italic"}}>{event.shortDescription}</Typography>
                                    {event.hasLongDescription &&
                                        <Button size="small"
                                                onClick={() => handleClickOpenLongDescriptionDialog(event)}>
                                            More details
                                        </Button>
                                    }
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                    </Timeline>
                    {selectedEvent?.longDescription &&
                        <Dialog
                            fullWidth={true}
                            maxWidth={"md"}
                            open={openLongDescriptionDialog}
                            onClose={handleLongDescriptionDialogClose}
                        >
                            <DialogTitle>
                                <Typography sx={{fontWeight: "600"}}>
                                    {selectedEvent.title}
                                </Typography>
                                <Typography
                                    sx={{fontStyle: "italic"}}>{selectedEvent.shortDescription}</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <Typography component={"div"} fontSize={"small"}>
                                    <ul style={{listStyle: 'none'}}>
                                        {beautifyLogs(selectedEvent?.longDescription)}
                                    </ul>
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleLongDescriptionDialogClose}>Close</Button>
                            </DialogActions>
                        </Dialog>
                    }
                </Paper>
            </Grid>
        </Grid>

    )
        ;
};

export default Events;
