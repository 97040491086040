import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Tooltip} from "@mui/material";
import AwardedBadge from "../types/AwarededBadge";
import moment from "moment";

interface BadgeItemProps {
    badge: AwardedBadge,
}

const AwardBadge: React.FunctionComponent = () => {
    return (
        <svg className="badge" xmlns="http://www.w3.org/2000/svg"
             viewBox="-40 -40 440 440">
            <circle className="outer" fill="#F9D535" stroke="#fff" strokeWidth="8"
                    strokeLinecap="round" cx="180" cy="180" r="157"/>
            <circle className="inner" fill="#DFB828" stroke="#fff" strokeWidth="8" cx="180"
                    cy="180" r="108.3"/>
            <path className="inline"
                  d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97"
                  stroke="#CAA61F" strokeWidth="7" strokeLinecap="round" fill="none"/>
            <g className="star">
                <path fill="#F9D535" stroke="#fff" strokeWidth="4" strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M180 107.8l16.9 52.1h54.8l-44.3 32.2 16.9 52.1-44.3-32.2-44.3 32.2 16.9-52.1-44.3-32.2h54.8z"/>
                <circle fill="#DFB828" stroke="#fff" strokeWidth="4" strokeLinecap="round"
                        strokeLinejoin="round" cx="180" cy="107.8" r="4.4"/>
                <circle fill="#DFB828" stroke="#fff" strokeWidth="4" strokeLinecap="round"
                        strokeLinejoin="round" cx="223.7" cy="244.2" r="4.4"/>
                <circle fill="#DFB828" stroke="#fff" strokeWidth="4" strokeLinecap="round"
                        strokeLinejoin="round" cx="135.5" cy="244.2" r="4.4"/>
                <circle fill="#DFB828" stroke="#fff" strokeWidth="4" strokeLinecap="round"
                        strokeLinejoin="round" cx="108.3" cy="160.4" r="4.4"/>
                <circle fill="#DFB828" stroke="#fff" strokeWidth="4" strokeLinecap="round"
                        strokeLinejoin="round" cx="251.7" cy="160.4" r="4.4"/>
            </g>
        </svg>
    )
}
// Display the identity of the user
export const BadgeItem = (props: BadgeItemProps) => {

    return (
        <Grid item xs={12} md={6} lg={6}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Tooltip title={"Awarded on " + moment(props.badge.awardedDate).format("L")}>
                        <Box sx={{width: 128, height: 128}}>
                            <AwardBadge></AwardBadge>
                        </Box>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm container>
                    <Grid item style={{overflow: "hidden", textOverflow: "ellipsis", width: '25rem'}}>
                        <Tooltip title={props.badge.title} placement="bottom-start">
                            <Typography component="h2" variant="h6" color="primary" noWrap>
                                {props.badge.title}
                            </Typography>
                        </Tooltip>
                        <Tooltip title={props.badge.description} placement="bottom-start">
                            <Typography component="h4" noWrap>
                                {props.badge.description}
                            </Typography>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BadgeItem;
