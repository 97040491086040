import {Chip, Tooltip} from "@mui/material";
import {EventAvailableOutlined, Today} from "@mui/icons-material";
import React from "react";
import {ExerciseStatus} from "../../types/PracticalExercise";

type Props = {
    status?: ExerciseStatus
};

const TraineeExerciseStatusChip: React.FunctionComponent<Props> = ({status}) => {
    function switchColor(status: ExerciseStatus | undefined) {
        switch (status) {
            case ExerciseStatus.UPCOMING:
                return (
                    <Tooltip title='Upcoming' placement="right">
                        <Chip sx={{border: "none", fontWeight: "700"}} icon={<Today color={"primary"}/>}
                              label="Upcoming"
                              variant="outlined"/>
                    </Tooltip>)
            case ExerciseStatus.LIVE:
                return (
                    <Tooltip title='Live' placement="right">
                        <Chip sx={{border: "none", fontWeight: "700"}} color={"error"}
                              icon={<svg height="10" width="15" className="blinking">
                                  <circle cx="10" cy="5" r="4" fill="red"/>
                              </svg>} label="LIVE" variant="outlined"/>

                    </Tooltip>)
            case ExerciseStatus.FINISHED:
                return (<Tooltip title='Finished' placement="right">
                    <Chip sx={{border: "none", fontWeight: "700"}} icon={<EventAvailableOutlined color={"disabled"}/>}
                          label="Finished"
                          variant="outlined"/>
                </Tooltip>)
            default:
                return (<Tooltip title='Finished' placement="right">
                    <Chip sx={{border: "none", fontWeight: "700"}} icon={<EventAvailableOutlined color={"disabled"}/>}
                          label="Finished"
                          variant="outlined"/>
                </Tooltip>)
        }
    }


    return (
        switchColor(status)
    );
}

export default TraineeExerciseStatusChip;
