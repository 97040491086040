import {CircularProgress} from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Instance from "../../types/Instance";

type Props = {
    trainingSessionInstance?: Instance[];
};

const TrainingProgress: React.FunctionComponent<Props> = ({trainingSessionInstance}) => {
    function calculateInstanceProgression() {
        let nbOfSuccess = 0;
        let nbOfQuestions = 0;
        trainingSessionInstance?.forEach((instance) => {
            instance?.survey?.forEach(answer => {
                if (answer.questionId) {
                    nbOfQuestions++
                }
                answer.correct && nbOfSuccess++;
            })

        })


        if (nbOfQuestions === 0) {
            return 100;
        }

        return Math.round((nbOfSuccess / nbOfQuestions) * 100)

    }

    return (

        <Box sx={{position: 'relative', display: 'inline-flex'}}>
            <CircularProgress color={calculateInstanceProgression() === 100 ? "success" : "primary"}
                              variant="determinate" value={calculateInstanceProgression()}/>
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color={calculateInstanceProgression() === 100 ? "green" : "primary"}
                >{calculateInstanceProgression() + "%"}</Typography>
            </Box>
        </Box>
    );
}

export default TrainingProgress;
