import React, {useEffect} from 'react';
import Typography from "@mui/material/Typography";
import {
    DataGrid,
    GridColDef,
    GridFilterModel,
    GridPaginationModel,
    GridRowSelectionModel,
    GridSortDirection,
    GridSortModel
} from "@mui/x-data-grid";
import moment from "moment";
import {styled, Switch} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import NewTrainingSession from "./NewTrainingSession";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SnackBarUtils from "../SnackBarUtils";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {stringFilterOperators} from "../utils/Utils";
import {useDeleteTrainingSessionMutation, useLazyGetTrainingSessionsQuery} from "../../services/TrainingSessionService";


const TrainingSessionList: React.FunctionComponent = () => {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showArchives, setShowArchives] = React.useState(false);
    const [pageSize, setPageSize] = React.useState(10);
    const [pageNo, setPageNo] = React.useState(0);
    const [sortOptions, setSortOptions] = React.useState(["startDate,desc"]);
    const [filterOptions, setFilterOptions] = React.useState<string[]>([]);
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
    });
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: "startDate", sort: "desc"
    }]);
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 10,
        page: 0,
    });

    const handlePaginationChange = React.useCallback((newPaginationModel: GridPaginationModel) => {
        setPaginationModel(newPaginationModel);
        setPageSize(newPaginationModel.pageSize);
        setPageNo(newPaginationModel.page)
        // Here you save the data you need from the sort model
        setSearchParams(searchParams => {
            searchParams.set("page", newPaginationModel.page.toString())
            searchParams.set("pageSize", newPaginationModel.pageSize.toString())
            return searchParams;
        });
    }, []);

    const [triggerGetSessions, {
        data: sessionsPage = {content: [], totalElements: 0},
        isFetching
    }] = useLazyGetTrainingSessionsQuery();

    const getSessionPage = () => {
        let filters = [...filterOptions]
        if (showArchives) {
            filters = [...filterOptions, "isArchived=true"]
        }
        if (filters.length > 0) {
            triggerGetSessions({
                pageNo: pageNo,
                pageSize: pageSize,
                sort: sortOptions,
                filter: filters
            })
        } else {
            triggerGetSessions({
                pageNo: pageNo,
                pageSize: pageSize,
                sort: sortOptions
            })
        }
    }

    const [rowCountState, setRowCountState] = React.useState(sessionsPage.totalElements);
    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            sessionsPage.totalElements !== undefined ? sessionsPage.totalElements : prevRowCountState,
        );
    }, [sessionsPage.totalElements, setRowCountState]);

    const [deleteSession] = useDeleteTrainingSessionMutation();
    const [deleteButtonDisabled, setDeleteButtonDisabled] = React.useState(true);
    const [gridSelectionModelToDelete, setGridSelectionModelToDelete] = React.useState<GridRowSelectionModel>([]);
    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = React.useState(false);

    React.useEffect(() => {
        getSessionPage();
    }, [pageNo, pageSize, sortOptions, filterOptions, showArchives]);

    useEffect(() => {
        let title = searchParams.get("title");
        if (title) {
            handleFilterModelChange({items: [{id: 1, field: 'title', operator: 'contains', value: title}]});
        }
        let trainer = searchParams.get("trainer");
        if (trainer) {
            handleFilterModelChange({items: [{id: 1, field: 'trainer', operator: 'contains', value: trainer}]});
        }
        let sort = searchParams.get("sort");
        if (sort) {
            handleSortModelChange([{field: sort.split(",")[0], sort: sort.split(",")[1] as GridSortDirection}]);
        }
        let pageParam = searchParams.get("page");
        let pageSizeParam = searchParams.get("pageSize");
        handlePaginationChange({
            pageSize: pageSizeParam ? parseInt(pageSizeParam) : 10,
            page: pageParam ? parseInt(pageParam) : 0
        });
    }, [searchParams]);

    const columns: GridColDef[] = [

        {
            field: 'title',
            align: 'center',
            headerName: 'Name',
            flex: 1,
            headerAlign: 'center',
            filterOperators: stringFilterOperators,
        },
        {
            field: 'startDate',
            align: 'center',
            filterable: false,
            headerName: 'Begin date',
            flex: 1,
            headerAlign: 'center',
            valueFormatter: params => moment(params?.value).format("L LT"),
        },
        {
            field: 'endDate',
            align: 'center',
            filterable: false,
            headerName: 'End date',
            flex: 1,
            headerAlign: 'center',
            valueFormatter: params => moment(params?.value).format("L LT"),
        },
        {
            field: 'trainer',
            align: 'center',
            headerName: 'Trainer',
            flex: 1,
            headerAlign: 'center',
            filterOperators: stringFilterOperators,
        }
    ];

    const handleCheckboxSelection = (sessionIds: GridRowSelectionModel) => {
        setGridSelectionModelToDelete(sessionIds);
        if (sessionIds.length > 0) {
            setDeleteButtonDisabled(false);
        } else {
            setDeleteButtonDisabled(true);
        }
    };

    const handleDeleteSelection = () => {
        gridSelectionModelToDelete.forEach((element) => {
            deleteSession(element.toString()).unwrap().then(() => {
                SnackBarUtils.info('Session deleting...');
                getSessionPage();
            }).catch(() => {
                SnackBarUtils.error('Error happened while deleting session');
            });
        });
        setOpenConfirmDeleteDialog(false);
    };

    const handleSeeArchivedSessionClick = (checked: boolean) => {
        setShowArchives(checked);
    };

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        setSortModel(sortModel)
        let firstSort = sortModel.find(first => first);
        if (firstSort) {
            setSearchParams(searchParams => {
                searchParams.set("sort", firstSort?.field + "," + firstSort?.sort)
                return searchParams;
            });
        }

        // Here you save the data you need from the sort model
        setSortOptions(sortModel.map((sortItem) => {
            return sortItem.field + "," + sortItem.sort
        }));
    }, []);

    const handleFilterModelChange = React.useCallback((filterModel: GridFilterModel) => {
        setFilterModel(filterModel)
        setFilterOptions(filterModel.items.map((filterItem) => {
            return filterItem.value ? filterItem.field + "=" + filterItem.value : ""
        }));
        filterModel.items.forEach((item) => {
            item.value && setSearchParams(searchParams => {
                searchParams.set(item.field, item.value)
                return searchParams;
            });
        })
    }, []);

    const MaterialUISwitch = styled(Switch)(({theme}) => ({
        width: 62,
        height: 34,
        padding: 7,
        '& .MuiSwitch-switchBase': {
            margin: 1,
            padding: 0,
            transform: 'translateX(6px)',
            '&.Mui-checked': {
                color: '#fff',
                transform: 'translateX(22px)',
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path fill="${encodeURIComponent(
                        '#fff',
                    )}" d="M144-168v-412q-23-7-35.5-26T96-648v-144q0-29.7 21.15-50.85Q138.3-864 168-864h624q29.7 0 50.85 21.15Q864-821.7 864-792v144q0 23-12.5 42T816-580v411.926Q816-138 794.85-117 773.7-96 744-96H216q-29.7 0-50.85-21.15Q144-138.3 144-168Zm648-480v-144H168v144h624ZM384-408h192v-72H384v72Z"/></svg>')`,
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.primary.main,
            width: 32,
            height: 32,
            '&:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M144-168v-412q-23-7-35.5-26T96-648v-144q0-29.7 21.15-50.85Q138.3-864 168-864h624q29.7 0 50.85 21.15Q864-821.7 864-792v144q0 23-12.5 42T816-580v411.926Q816-138 794.85-117 773.7-96 744-96H216q-29.7 0-50.85-21.15Q144-138.3 144-168Zm72-408v408h528v-408H216Zm576-72v-144H168v144h624ZM384-408h192v-72H384v72ZM216-168v-408 408Z"/></svg>')`,
            },
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            borderRadius: 20 / 2,
        },
    }));

    return (
        <React.Fragment>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <Typography component="div" variant="h6" color="primary" mb={3}>
                        Sessions
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign: 'right'}}>
                    <MaterialUISwitch sx={{m: 1}} checked={showArchives}
                                      onChange={(e, checked) => handleSeeArchivedSessionClick(checked)}/>
                    <Button sx={{marginRight: 2}} color={"error"} disableElevation disabled={deleteButtonDisabled}
                            size="small" variant="contained"
                            onClick={() => setOpenConfirmDeleteDialog(true)}>Delete</Button>
                    <NewTrainingSession/>
                </Grid>
            </Grid>
            <DataGrid
                sx={{
                    boxShadow: 0,
                    border: 0,
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none",
                    },
                    cursor: "pointer"
                }}
                disableRowSelectionOnClick
                autoHeight
                rows={sessionsPage.content}
                columns={columns}
                pageSizeOptions={[10, 20, 50]}
                paginationMode={"server"}
                rowCount={rowCountState}
                filterMode="server"
                onFilterModelChange={handleFilterModelChange}
                filterModel={filterModel}
                getRowId={(row: any) => row.id.toString()}
                pagination
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                loading={isFetching}
                onRowClick={(row) => navigate("/sessions/" + row.id)}
                checkboxSelection
                onRowSelectionModelChange={items => handleCheckboxSelection(items)}
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationChange}
            />
            <Dialog PaperProps={{
                style: {
                    backgroundImage: "none"
                },
            }} open={openConfirmDeleteDialog}>
                <DialogTitle>Delete the session?</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to delete these sessions?</p>
                    <p>If one or more instances are attached to the session. All instances will
                        be destroyed and deleted in database. Trainee will have no access to this session and will lose
                        progress on all
                        exercises.</p>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation onClick={() => setOpenConfirmDeleteDialog(false)}>CANCEL</Button>
                    <Button disableElevation color={"error"} onClick={handleDeleteSelection}>DELETE</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default TrainingSessionList;
