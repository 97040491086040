import React, {KeyboardEvent, useState} from 'react';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert, Autocomplete, FormControlLabel, LinearProgress, Switch} from "@mui/material";
import {useGetPracticalExercisesQuery} from "../../services/CatalogService";
import SnackBarUtils from "../SnackBarUtils";
import {useCreateInstanceMutation} from "../../services/InstanceService";
import PracticalExercise from "../../types/PracticalExercise";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

type Props = {};

const NewInstance: React.FunctionComponent<Props> = () => {

    const [open, setOpen] = React.useState(false);
    const [isAutoDestroy, setIsAutoDestroy] = React.useState(true);
    const {
        data: availableExercises = {content: [], totalElements: 100},
        isLoading: isLoadingExercises
    } = useGetPracticalExercisesQuery({
        pageNo: 0,
        pageSize: 100
    });
    const [selectedExercise, setSelectedExercise] = useState<PracticalExercise>({});
    const [selectedTraineeError, setSelectedTraineeError] = useState(false);
    const [selectedTrainee, setSelectedTrainee] = useState<string>("");
    const [error, setError] = useState(false);
    const [createInstance, {isLoading: isCreatingInstance}] = useCreateInstanceMutation();

    function resetDatas() {
        setSelectedTrainee("");
        setSelectedExercise({});
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setError(false);
        setOpen(false);
        resetDatas();
    };

    const handleKeyDownAdd = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleCreate();
        }
    };

    const handleCreate = () => {
        if (isFormValid()) {
            createInstance({
                practicalExercise: selectedExercise,
                trainee: selectedTrainee.trim().toLowerCase(),
                automaticallyDestroyed: isAutoDestroy,
                id: ""
            }).unwrap().then(() => {
                SnackBarUtils.info('Instance successfully created')
                handleClose();
            }).catch(() => {
                SnackBarUtils.error('Error happened while creating instance.')
            })
        }

    };

    function isFormValid() {
        return selectedExercise !== undefined && selectedTrainee !== "";
    }

    const handleChangeIsAutoDestroy = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAutoDestroy(event.target.checked);
    }

    return (
        <>
            <Button disableElevation size="small" variant="contained" onClick={handleClickOpen}>Create standalone
                instance</Button>
            <Dialog PaperProps={{
                style: {
                    backgroundImage: "none"
                },
            }} open={open} onKeyDown={handleKeyDownAdd}>
                <DialogTitle>Create standalone instance</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Choose a practical exercise and add trainee username
                    </DialogContentText>
                    {
                        isLoadingExercises ?
                            <LinearProgress></LinearProgress>
                            :
                            <Autocomplete
                                id="practical-exercise-choice"
                                fullWidth
                                options={availableExercises.content}
                                renderOption={(props, option, {inputValue}) => {
                                    const matches = option?.name ? match(option.name, inputValue, {insideWords: true}) : [];
                                    const parts = option?.name ? parse(option.name, matches) : [];
                                    return (
                                        <li {...props}>
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span
                                                        key={(index as unknown as string) + part.text}
                                                        style={{
                                                            fontWeight: part.highlight ? 700 : 400,
                                                        }}
                                                    >
                                                      {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        </li>
                                    );
                                }}
                                getOptionLabel={(option) => option.name ?? ""}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Exercise"
                                        placeholder="Choose exercise"
                                    />
                                )}
                                onChange={(event: any, newExercise: PracticalExercise | null | undefined) => {
                                    if (newExercise) {
                                        setSelectedExercise(newExercise)
                                    }
                                }}
                                value={selectedExercise}
                                isOptionEqualToValue={() => true}
                            />
                    }
                    <TextField
                        value={selectedTrainee}
                        margin="dense"
                        id="selected-trainee"
                        label="Trainee username"
                        type="text"
                        fullWidth
                        variant="standard"
                        error={selectedTraineeError}
                        disabled={isCreatingInstance}
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setSelectedTrainee(e.target.value)
                                setSelectedTraineeError(true)
                            } else {
                                setSelectedTraineeError(false)
                                setSelectedTrainee(e.target.value.trim().toLowerCase())
                            }
                        }}
                        required={true}
                        helperText={selectedTraineeError ? "Please enter the trainee username." : ""}
                    />
                    <FormControlLabel control={<Switch checked={isAutoDestroy} onChange={handleChangeIsAutoDestroy}/>}
                                      label="Automatically destroy instances at night"/>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation onClick={handleClose} disabled={isCreatingInstance}>Cancel</Button>
                    <Button disableElevation variant='contained' onClick={handleCreate}
                            disabled={isCreatingInstance || !isFormValid()}>Create</Button>
                </DialogActions>
                {isCreatingInstance && (
                    <LinearProgress/>
                )}
                {error && (
                    <Alert severity="error">Une erreur est survenue lors de la création de l'instance</Alert>
                )}
            </Dialog>
        </>
    );
};

export default NewInstance;
