import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import PracticalExercise from "../../types/PracticalExercise";
import {Autocomplete, CircularProgress, InputAdornment, InputLabel, ToggleButton} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useGetAllTerraformScriptsQuery} from "../../services/TerraformService";
import {useUpdatePracticalExerciseMutation} from "../../services/CatalogService";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackBarUtils from "../SnackBarUtils";
import TerraformScript from "../../types/TerraformScript";
import {SignalCellularAlt} from "@mui/icons-material";
import {StyledToggleButtonGroup} from "./NewPracticalExerciseCatalog";

interface PracticalExerciseGeneralProps {
    exercise: PracticalExercise,
}

export const PracticalExerciseGeneral = ({exercise: initialExercise}: PracticalExerciseGeneralProps) => {

    const [exercise, setExercise] = useState(initialExercise);
    const [nameError, setNameError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [script, setScript] = useState<TerraformScript>();
    const [updateExercise, {isLoading: isUpdatingExercise}] = useUpdatePracticalExerciseMutation();
    const {
        data: tfScripts = [],
        isLoading: isTFScriptsLoading
    } = useGetAllTerraformScriptsQuery();

    function handleSave() {
        updateExercise(exercise).unwrap().then(() => {
            SnackBarUtils.info('Practical exercise successfully modified')
        });
    }

    function getToggleColor() {
        switch (exercise.level) {
            case "EASY" :
                return "success";
            case "MEDIUM" :
                return "warning";
            case "HARD" :
                return "error";
            default:
                return "info"
        }
    }

    useEffect(() => {
        setExercise(initialExercise);
        setScript(tfScripts.find(script => script.id === initialExercise.scriptId));
    }, [initialExercise, tfScripts])

    return (<Grid container rowSpacing={1} sx={{paddingTop: "20px"}}>
        <Grid item xs={12} md={12} lg={12}>
            {
                isTFScriptsLoading ?
                    <CircularProgress size={30} color="primary"/>
                    :
                    <Autocomplete
                        id="select-script"
                        fullWidth
                        options={tfScripts}
                        onChange={(event: any, selectedScript: TerraformScript | null | undefined) => {
                            if (selectedScript != null) {
                                setExercise((oldState: PracticalExercise) => ({
                                    ...oldState,
                                    scriptId: selectedScript.id,
                                    inputs: selectedScript?.customizableInputs?.map(inputs => ({
                                        ...inputs,
                                        id: undefined,
                                        value: inputs.defaultValue
                                    })),
                                }));
                            }
                        }}
                        getOptionLabel={(option) => option?.id ? option.id : ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Script"
                                placeholder="Select a script"
                            />
                        )}
                        value={script || null}
                    />
            }
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
            <TextField
                fullWidth
                required
                variant="standard"
                label="Name"
                key={exercise.id + "name"}
                defaultValue={exercise?.name}
                error={nameError}
                onChange={(e) => {
                    setNameError(e.target.value as string === "")
                    setExercise((oldState: PracticalExercise) => ({
                        ...oldState, name: e.target.value as string
                    }));
                }}
                helperText={nameError ? "Please enter a name." : ""}
            />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
            <TextField
                fullWidth
                required
                key={exercise.id + "description"}
                variant="standard"
                label="Description"
                defaultValue={exercise?.description}
                error={descriptionError}
                onChange={(e) => {
                    setDescriptionError(e.target.value === "")
                    setExercise((oldState: PracticalExercise) => ({
                        ...oldState, description: e.target.value
                    }));
                }}
                helperText={descriptionError ? "Please enter a description." : ""}
            />
        </Grid>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
                <TextField
                    defaultValue={exercise?.exerciseDuration}
                    margin="dense"
                    key={exercise.id + "exerciseDuration"}
                    label="Exercise Duration"
                    type="number"
                    fullWidth
                    variant="standard"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">min</InputAdornment>
                    }}
                    onChange={(e) => {
                        setExercise((oldState: PracticalExercise) => ({
                            ...oldState, exerciseDuration: (e.target.value as unknown as number)
                        }));
                    }}
                    helperText={nameError ? "Please enter the exercise duration in minutes." : ""}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    defaultValue={exercise?.deploymentDuration}
                    margin="dense"
                    key={exercise.id + "deploymentDuration"}
                    label="Deployment Duration"
                    type="number"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                        setExercise((oldState: PracticalExercise) => ({
                            ...oldState, deploymentDuration: e.target.value as unknown as number
                        }));
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">min</InputAdornment>
                    }}
                    helperText={nameError ? "Please enter the deployment duration in minutes." : ""}
                />
            </Grid>
            <Grid item xs={4}>
                <InputLabel htmlFor="level"
                            sx={{fontWeight: 400, fontSize: "11px", marginTop: "13px", marginBottom: "4px"}}>
                    Level
                </InputLabel>
                <StyledToggleButtonGroup
                    fullWidth
                    size="small"
                    color={getToggleColor()}
                    value={exercise.level}
                    exclusive
                    onChange={(e, value) => {
                        setExercise((oldState: PracticalExercise) => ({
                            ...oldState, level: value
                        }));
                    }}
                    aria-label="Level"
                >
                    <ToggleButton value="EASY"><SignalCellularAlt/>Easy</ToggleButton>
                    <ToggleButton value="MEDIUM"><SignalCellularAlt/>Medium</ToggleButton>
                    <ToggleButton value="HARD"><SignalCellularAlt/>Hard</ToggleButton>
                </StyledToggleButtonGroup>
            </Grid>
        </Grid>
        <LoadingButton sx={{marginTop: 2}}
                       size="small"
                       onClick={handleSave}
                       loading={isUpdatingExercise}
                       variant="contained" disableElevation
        >
            <span>SAVE</span>
        </LoadingButton>
    </Grid>)
}