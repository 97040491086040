export enum Level {
    INFO = "INFO",
    WARNING = "WARNING",
    ALERT = "ALERT",
}

export default interface Event {
    id?: string | "",
    title?: string,
    shortDescription?: string,
    longDescription?: string,
    hasLongDescription?: boolean,
    level?: Level,
    createdDate?: Date,
}
