import React, {KeyboardEvent, useState} from 'react';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import {Add, Delete} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import LearningPath from "../../../types/LearningPath";
import TrainingSessionLink from "../../../types/TrainingSessionLink";
import SnackBarUtils from "../../SnackBarUtils";
import {useUpdateLearningPathMutation} from "../../../services/CatalogService";

type Props = {
    learningPath: LearningPath,
};

export const LearningPathCatalogLinksEditor: React.FunctionComponent<Props> = (
    {learningPath: initialLearningPath}: Props
) => {

    const [learningPath, setLearningPath] = React.useState<LearningPath>(initialLearningPath);
    const [updateLearningPath, {isLoading: isUpdatingLearningPath}] = useUpdateLearningPathMutation();
    const [newLink, setNewLink] = useState<TrainingSessionLink>({
        id: undefined,
        name: '',
        url: ''
    })

    const handleSave = () => {
        updateLearningPath(learningPath).unwrap().then(() => {
            SnackBarUtils.info('Learning path successfully modified')
        });
    }
    const handleLinkUpdate = (link: TrainingSessionLink) => {
        if (learningPath?.links) {
            let nextLinks = learningPath?.links.map((x) => Object.assign({}, x));
            nextLinks.map((currentLink: TrainingSessionLink) => {
                if (currentLink.id === link.id) {
                    currentLink.name = link.name;
                    currentLink.url = link.url;
                    return currentLink;
                } else {
                    return currentLink;
                }
            })
            setLearningPath((oldState: LearningPath) => ({
                ...oldState, links: nextLinks
            }));
        }
    }

    const handleNewLink = () => {
        setLearningPath((oldState: LearningPath) => ({
            ...oldState,
            links: oldState.links ? oldState.links.concat(newLink) : new Array(1).fill(newLink)
        }));
        setNewLink({id: undefined, name: '', url: ''})
    }

    const handleRemoveLink = (linkId?: string) => {
        setLearningPath((oldState: LearningPath) => ({
            ...oldState, links: oldState.links?.filter((link) => link.id !== linkId)
        }));
        setNewLink({id: undefined, name: '', url: ''})
    }

    const handleKeyDownAdd = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleNewLink()
        }
    };

    return (
        <Grid>
            {learningPath?.links?.map((link, index) => (
                <Grid key={index + "-link"} container spacing={2} paddingTop={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    variant="standard"
                                    label="Name"
                                    fullWidth
                                    value={link.name}
                                    onChange={(e) => {
                                        handleLinkUpdate({...link, name: e.target.value})
                                    }}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    label="URL"
                                    value={link.url}
                                    onChange={(e) => {
                                        handleLinkUpdate({...link, url: e.target.value})
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                                <IconButton color={"primary"} aria-label="Add"
                                            onClick={() => handleRemoveLink(link.id)}>
                                    <Delete></Delete>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={2} paddingTop={2} key={"new-link"}>
                    <Grid item xs={4}>
                        <TextField
                            onKeyDown={handleKeyDownAdd}
                            variant="standard"
                            label="Name"
                            autoFocus
                            fullWidth
                            value={newLink.name}
                            onChange={(e) => {
                                setNewLink({...newLink, name: e.target.value})
                            }}
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <TextField
                            variant="standard"
                            fullWidth
                            label="URL"
                            value={newLink.url}
                            onKeyDown={handleKeyDownAdd}
                            onChange={(e) => {
                                setNewLink({...newLink, url: e.target.value})
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} md={1} lg={1}>
                        <IconButton color={"primary"} aria-label="Add" onClick={handleNewLink}>
                            <Add></Add>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <LoadingButton sx={{marginTop: 2}}
                           size="small"
                           onClick={handleSave}
                           loading={isUpdatingLearningPath}
                           disableElevation
                           variant="contained"
            >
                <span>SAVE</span>
            </LoadingButton>
        </Grid>
    );
};