import TrainingModuleCatalog from "./TrainingModuleCatalog";
import TrainingSessionLink from "./TrainingSessionLink";

export default interface LearningPath {
    id?: string | "",
    name?: string,
    description?: string,
    level?: Level,
    imageLink?: string,
    trainingModules?: TrainingModuleCatalog[],
    links?: TrainingSessionLink[]
}

export enum Level {
    BEGINNER = "BEGINNER",
    INTERMEDIATE = "INTERMEDIATE",
    EXPERT = "EXPERT",
}
