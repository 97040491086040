import React, {useEffect, useMemo} from 'react';
import Grid from "@mui/material/Grid";
import {useParams} from "react-router-dom";
import {CardContent, CardHeader, CircularProgress, ListItem, Stack} from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import InstanceList from "../instance/InstanceList";
import StartExercise from "./StartExercise";
import StopExercise from "./StopExercise";
import Typography from "@mui/material/Typography";
import BreadcrumbItem from "../../types/BreadcrumbItem";
import {Item, StyledGuidelineViewer} from "../utils/Utils";
import {
    useGetTrainingSessionExerciseQuery,
    useLazyGetTrainingSessionExerciseGuidelineQuery,
    useLazyGetTrainingSessionExerciseTrainerNotesQuery,
} from "../../services/TrainingSessionService";
import {useTheme} from "@mui/material/styles";
import {ExerciseGuidelineAndQuestions} from "../trainingSession/TrainingSessionPresentation";

type Props = {
    pageTitle: (arg: string) => void,
    breadCrumbItems: (arg: BreadcrumbItem[]) => void
};
const PracticalExerciseDetails: React.FunctionComponent<Props> = ({breadCrumbItems}) => {

    const {sessionId} = useParams<{ sessionId: any }>();
    const {exerciseId} = useParams<{ exerciseId: any }>();
    const {
        data: practicalExercise,
    } = useGetTrainingSessionExerciseQuery(exerciseId);
    const [
        triggerGetPracticalExerciseGuidelineQuery, {
            data: guideline = "",
            isLoading: isGuidelineLoading
        }
    ] = useLazyGetTrainingSessionExerciseGuidelineQuery(exerciseId);

    const items: BreadcrumbItem[] = useMemo(() => [
        {title: 'Sessions', link: '/sessions'},
        {title: practicalExercise?.trainingModule?.trainingSession?.title, link: '/sessions/' + sessionId},
        {title: 'Exercise', link: '/sessions/' + sessionId + '/exercises/' + exerciseId}
    ], [sessionId, exerciseId, practicalExercise]);

    const [
        triggerGetPracticalExerciseTrainerNotesQuery, {
            data: trainerNotes = "",
            isLoading: isTrainerNotesLoading,
        }
    ] = useLazyGetTrainingSessionExerciseTrainerNotesQuery();

    useEffect(() => {
        triggerGetPracticalExerciseTrainerNotesQuery({
            exerciseId: exerciseId
        })
        triggerGetPracticalExerciseGuidelineQuery({
            exerciseId: exerciseId
        })
    }, [sessionId, exerciseId, triggerGetPracticalExerciseTrainerNotesQuery, triggerGetPracticalExerciseGuidelineQuery]);

    const theme = useTheme();

    React.useEffect(() => {
        breadCrumbItems(items);
    }, [breadCrumbItems, items]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
                <Item>
                    <CardHeader title={
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                    Exercise detail
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign: 'right'}}>
                                {practicalExercise?.trainingModule?.trainingSession ?
                                    <>
                                        <StartExercise session={practicalExercise?.trainingModule?.trainingSession}
                                                       practicalExercise={practicalExercise}></StartExercise>
                                        <StopExercise session={practicalExercise?.trainingModule?.trainingSession}
                                                      practicalExercise={practicalExercise}></StopExercise>
                                    </>
                                    : <></>
                                }
                            </Grid>
                        </Grid>
                    }/>
                    <CardContent sx={{height: 100, overflow: "auto", paddingY: 0}}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={12} lg={12}>
                                <List component={Stack} direction="row">
                                    < ListItem sx={{padding: 0}}>
                                        <ListItemText primaryTypographyProps={{fontWeight: 550}} primary="Status"
                                                      secondary="Pending"/>
                                    </ListItem>
                                    < ListItem sx={{padding: 0}}>
                                        <ListItemText primaryTypographyProps={{fontWeight: 550}} primary="Name"
                                                      secondary={practicalExercise?.name}/>
                                    </ListItem>
                                    <ListItem sx={{padding: 0}}>
                                        <ListItemText primaryTypographyProps={{fontWeight: 550}} primary="Description"
                                                      secondary={practicalExercise?.description}/>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Item>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <Item>
                    <CardContent sx={{overflow: "auto"}}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={12} lg={12}>
                                {
                                    sessionId && exerciseId ?
                                        <InstanceList sessionId={sessionId}
                                                      exerciseId={exerciseId}></InstanceList> : <></>
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Item>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <Item>
                    <CardHeader title={
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Trainer notes
                        </Typography>
                    }/>
                    <CardContent sx={{overflow: "auto", paddingY: 0}}>
                        {
                            isTrainerNotesLoading ?
                                <CircularProgress size={32} color="primary"/> :
                                <StyledGuidelineViewer
                                    disallowedElements={['script', 'iframe']}
                                    source={trainerNotes}/>
                        }
                    </CardContent>
                </Item>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <Item>
                    <CardHeader title={
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Guideline
                        </Typography>
                    }/>
                    <CardContent sx={{overflow: "auto", paddingY: 0}}>
                        {practicalExercise &&
                            <ExerciseGuidelineAndQuestions exercise={practicalExercise} guideline={guideline}
                                                           isGuidelineLoading={isGuidelineLoading}/>}
                    </CardContent>
                </Item>
            </Grid>
        </Grid>
    );
};

export default PracticalExerciseDetails;
