import React, {useMemo} from 'react';

import '../App.css';
import Grid from "@mui/material/Grid";
import BreadcrumbItem from "../types/BreadcrumbItem";
import {Breadcrumbs} from "@mui/material";
import Link from "@mui/material/Link";
import {useNavigate} from "react-router-dom";

type Props = {
    items: BreadcrumbItem[],
    breadCrumbItems: (arg: BreadcrumbItem[]) => void
};

const MainBreadCrumb: React.FunctionComponent<Props> = ({items, breadCrumbItems}) => {

    const navigate = useNavigate();
    const initialItems: BreadcrumbItem[] = useMemo(() => [], []);
    React.useEffect(() => {
        breadCrumbItems(initialItems)
    }, [breadCrumbItems, initialItems, navigate]);

    return (
        <Grid container sx={{pt: 2, pb: 2}}>
            <Grid item xs={12} md={12} lg={12}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    {items ?
                        items.map((item, index) => {
                            return <Link sx={{cursor: "pointer"}} underline="hover" key={index}
                                         color={index + 1 === items.length ? "primary" : "inherit"}
                                         onClick={() => navigate(item.link)}>
                                {item.title}
                            </Link>
                        })
                        :
                        <></>
                    }
                </Breadcrumbs>
            </Grid>
        </Grid>
    );
};

export default MainBreadCrumb;
