import React, {useState} from 'react';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Autocomplete,
    CircularProgress,
    InputAdornment,
    InputLabel,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import {useCreatePracticalExerciseMutation} from "../../services/CatalogService";
import {useGetAllTerraformScriptsQuery} from "../../services/TerraformService";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackBarUtils from "../SnackBarUtils";
import TerraformScript from "../../types/TerraformScript";
import Grid from "@mui/material/Grid";
import {SignalCellularAlt} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import CustomizableInput from "../../types/CustomizableInput";

type Props = {};

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
    '& .MuiToggleButton-root': {
        border: 0,
    },
}));

const NewPracticalExerciseCatalog: React.FunctionComponent<Props> = () => {

    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [exerciseDuration, setExerciseDuration] = useState(0);
    const [deploymentDuration, setDeploymentDuration] = useState(0);
    const [level, setLevel] = useState("EASY");
    const [script, setScript] = useState<TerraformScript>();
    const [inputs, setInputs] = useState<CustomizableInput[]>();
    const {
        data: tfScripts = [],
        isLoading: isTFScriptsLoading,
    } = useGetAllTerraformScriptsQuery();
    const [titleError, setTitleError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [createExercise, {isLoading: isCreatingExercise}] = useCreatePracticalExerciseMutation();

    function resetDatas() {
        setTitle('');
        setDescription('');
        setScript({});
    }

    function getToggleColor() {
        switch (level) {
            case "EASY" :
                return "success";
            case "MEDIUM" :
                return "warning";
            case "HARD" :
                return "error";
            default:
                return "info"
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        resetDatas();
    };

    const handleCreate = () => {
        createExercise({
            name: title,
            description: description,
            scriptId: script?.id,
            guideline: script?.guideline,
            trainerNotes: script?.trainerNotes,
            questions: script?.questions?.map(question => ({...question, id: undefined})),
            deploymentDuration: deploymentDuration,
            exerciseDuration: exerciseDuration,
            inputs: script?.customizableInputs?.map(inputs => ({
                ...inputs,
                id: undefined,
                value: inputs.defaultValue
            })),
            badge: {...script?.badge, id: undefined},
            level: level
        }).unwrap().then(() => {
            SnackBarUtils.info('Practical exercise successfully created')
            handleClose();
        }).catch(() => {
            SnackBarUtils.error('Error happened while creating practical exercise.')
        })
    };


    return (
        <div>
            <Button variant="contained" size={"small"} disableElevation onClick={handleClickOpen}>New practical
                exercise</Button>
            <Dialog open={open} PaperProps={{
                style: {
                    backgroundImage: "none"
                },
            }}>
                <DialogTitle>New practical exercise</DialogTitle>
                <DialogContent>
                    {
                        isTFScriptsLoading ?
                            <CircularProgress size={30} color="primary"/>
                            :
                            <Autocomplete
                                id="new-exercise-script-selection"
                                sx={{mt: 1}}
                                fullWidth
                                options={tfScripts}
                                onChange={(event: any, selectedScript: TerraformScript | null | undefined) => {
                                    if (selectedScript != null) {
                                        setScript(selectedScript);
                                        selectedScript.title && setTitle(selectedScript.title);
                                        selectedScript.level && setLevel(selectedScript.level)
                                        selectedScript.description && setDescription(selectedScript.description);
                                        selectedScript.deploymentDuration && setDeploymentDuration(selectedScript.deploymentDuration);
                                        selectedScript.customizableInputs && setInputs(selectedScript.customizableInputs);
                                        selectedScript.exerciseDuration && setExerciseDuration(selectedScript.exerciseDuration);
                                    }
                                }}
                                getOptionLabel={(option) => option.id ? option.id : ""}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Script"
                                        placeholder="Select a script"
                                    />
                                )}
                                isOptionEqualToValue={() => true}
                                value={script || null}
                                renderOption={(props, option, {inputValue}) => {
                                    const matches = option?.id ? match(option.id, inputValue, {insideWords: true}) : [];
                                    const parts = option?.id ? parse(option.id, matches) : [];
                                    return (
                                        <li {...props}>
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            fontWeight: part.highlight ? 700 : 400,
                                                        }}
                                                    >
                                                      {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        </li>
                                    );
                                }}
                            />
                    }
                    <TextField
                        value={title}
                        margin="dense"
                        id="title"
                        label="Title"
                        type="text"
                        fullWidth
                        variant="standard"
                        error={titleError}
                        disabled={isCreatingExercise}
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setTitleError(true)
                            } else {
                                setTitleError(false)
                                setTitle(e.target.value)
                            }
                        }}
                        required={true}
                        helperText={titleError ? "Please enter a title." : ""}
                    />
                    <TextField
                        value={description}
                        margin="dense"
                        id="description"
                        label="Description"
                        type="text"
                        fullWidth
                        variant="standard"
                        error={descriptionError}
                        disabled={isCreatingExercise}
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setDescriptionError(true)
                            } else {
                                setDescriptionError(false)
                                setDescription(e.target.value)
                            }
                        }}
                        required={true}
                        helperText={titleError ? "Please enter a description." : ""}
                    />
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <TextField
                                value={exerciseDuration}
                                margin="dense"
                                id="exerciseDuration"
                                label="Exercise Duration"
                                type="number"
                                fullWidth
                                variant="standard"
                                disabled={isCreatingExercise}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">min</InputAdornment>
                                }}
                                onChange={(e) => {
                                    setExerciseDuration(e.target.value as unknown as number)
                                }}
                                helperText={titleError ? "Please enter the exercise duration in minutes." : ""}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                value={deploymentDuration}
                                margin="dense"
                                id="deploymentDuration"
                                label="Deployment Duration"
                                type="number"
                                fullWidth
                                variant="standard"
                                disabled={isCreatingExercise}
                                onChange={(e) => {
                                    setDeploymentDuration(e.target.value as unknown as number)
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">min</InputAdornment>
                                }}
                                helperText={titleError ? "Please enter the deployment duration in minutes." : ""}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel htmlFor="level"
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: "11px",
                                            marginTop: "13px",
                                            marginBottom: "4px"
                                        }}>
                                Level
                            </InputLabel>
                            <StyledToggleButtonGroup
                                fullWidth
                                size="small"
                                color={getToggleColor()}
                                value={level}
                                exclusive
                                onChange={(e, value) => setLevel(value)}
                                aria-label="Level"
                            >
                                <ToggleButton value="EASY"><SignalCellularAlt/>Easy</ToggleButton>
                                <ToggleButton value="MEDIUM"><SignalCellularAlt/>Medium</ToggleButton>
                                <ToggleButton value="HARD"><SignalCellularAlt/>Hard</ToggleButton>
                            </StyledToggleButtonGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isCreatingExercise}>Cancel</Button>
                    <LoadingButton
                        size="small"
                        onClick={handleCreate}
                        disableElevation
                        loading={isCreatingExercise}
                        variant="contained"
                    >
                        <span>Create</span>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default NewPracticalExerciseCatalog;
