import Instance, {Status} from "../types/Instance";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {API_URL} from "../api/ConfigApi";
import TrainingSession from "../types/TrainingSession";
import Answer from "../types/Answer";
import {prepareHeaders} from "./ServiceUtils";
import {RootState} from "../slices";
import Page from "../types/Page";
import CustomizableInput from "../types/CustomizableInput";

function compareInstances(a: Instance, b: Instance) {
    if (a?.practicalExercise?.exerciseOrder !== undefined && b.practicalExercise?.exerciseOrder !== undefined) {
        return a.practicalExercise.exerciseOrder - b.practicalExercise.exerciseOrder;
    } else {
        return 0;
    }
}


export const instanceApi = createApi({
    reducerPath: 'instanceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers, {getState}) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            return prepareHeaders(headers, getState() as RootState)
        },
    }),
    tagTypes: ['Instance'],
    endpoints: (builder) => ({
        countInstances: builder.query<number, { filter?: string }>({
            query: (params) => {
                const {filter} = params;
                if (filter) {
                    return {
                        url: "/instances",
                        params: {pageNo: 0, pageSize: 1, filter},
                    };
                }
                return {
                    url: "/instances",
                    params: {pageNo: 0, pageSize: 1},
                };
            },
            transformResponse: (rawResult: Page<Instance>) => {
                return rawResult.totalElements;
            }
        }),
        getInstancesWithFilters: builder.query<Page<Instance>, { sessionId?: string | null, exerciseId?: string, pageNo: number, pageSize?: number, sort?: string[], filter?: string[] }>({
            query: (params) => {
                const {pageNo, pageSize, sort, filter} = params;

                if (params.exerciseId) {
                    return {
                        url: `/instances?exerciseId=${params.exerciseId}`,
                        params: {pageNo, pageSize, sort, filter},
                    };
                } else if (params.sessionId) {
                    return {
                        url: `/instances?trainingSessionId=${params.sessionId}`,
                        params: {pageNo, pageSize, sort, filter},
                    };
                } else {
                    return {
                        url: "/instances",
                        params: {pageNo, pageSize, sort, filter},
                    };
                }
            },
            providesTags: (result) =>
                result
                    ? [
                        // Provides a tag for each post in the current page,
                        // as well as the 'PARTIAL-LIST' tag.
                        ...result.content.map(({id}) => ({type: 'Instance' as const, id})),
                        {type: 'Instance', id: 'PARTIAL-LIST'},
                    ]
                    : [{type: 'Instance', id: 'PARTIAL-LIST'}],
            transformResponse: (rawResult: Page<Instance>) => {
                return rawResult;
            }
        }),
        getInstance: builder.query<Instance, string>({
            query: (id) => `/instances/${id}`,
            providesTags: (_instance, _err, id) => [{type: 'Instance', id}],
            transformResponse: (rawResult: Instance) => {
                if (rawResult.status === Status.STARTED) {
                    if (rawResult.outputs) {
                        Object.keys(rawResult.outputs).forEach(key => {
                            if (rawResult.practicalExercise && rawResult?.outputs && rawResult?.outputs[key]) {
                                let toReplace = '#' + key + '#'
                                let replaceWith = rawResult.outputs[key];
                                rawResult.practicalExercise.guideline = rawResult.practicalExercise?.guideline?.replaceAll(toReplace, replaceWith);
                            }
                        })
                    }
                }
                return rawResult;
            },
        }),
        getInstanceLogs: builder.query<string, string>({
            query: (id) => ({
                url: `/instances/${id}/logs`,
                responseHandler: "text"
            }),
            providesTags: (_instance, _err, id) => [{type: 'Instance', id}],
        }),
        getTrainingModuleInstances: builder.query<Page<Instance>, {
            pageNo: number,
            pageSize: number,
            trainingModuleId?: string
        }>({
            query: (params) => {
                return `/instances?trainingModuleId=${params.trainingModuleId}`;
            },

            providesTags: (result) =>
                result
                    ? [
                        // Provides a tag for each post in the current page,
                        // as well as the 'PARTIAL-LIST' tag.
                        ...result.content.map(({id}) => ({type: 'Instance' as const, id})),
                        {type: 'Instance', id: 'PARTIAL-LIST'},
                    ]
                    : [{type: 'Instance', id: 'PARTIAL-LIST'}],
            transformResponse: (rawResult: Page<Instance>) => {
                let sortedInstances = rawResult.content.sort(compareInstances);
                return {...rawResult, content: sortedInstances}
            }
        }),
        getTraineeStandaloneInstances: builder.query<Page<Instance>, {
            pageNo: number,
            pageSize: number,
        }>({
            query: (params) => {
                return `/instances?pageNo=${params.pageNo}&pageSize=${params.pageSize}&trainingSessionId=standalone`;
            },
            providesTags: (result) =>
                result
                    ? [
                        // Provides a tag for each post in the current page,
                        // as well as the 'PARTIAL-LIST' tag.
                        ...result.content.map(({id}) => ({type: 'Instance' as const, id})),
                        {type: 'Instance', id: 'PARTIAL-LIST'},
                    ]
                    : [{type: 'Instance', id: 'PARTIAL-LIST'}],
        }),
        getTraineeTrainingSessions: builder.query<TrainingSession[], void>({
            query: () => ({
                url: `/instances`,
                method: 'GET',
                params: {pageNo: 0, pageSize: 1000}
            }),
            providesTags: (result = []) => [
                ...result.map(({id}) => ({type: 'Instance', id} as const)),
                {type: 'Instance' as const, id: 'PARTIAL-LIST'},
            ],
            transformResponse: (rawResult: Page<Instance>, meta) => {
                if (meta?.response?.status === 204) {
                    return [];
                }
                let trainingSessions: TrainingSession[] = [];
                rawResult.content.forEach(instance => {
                    if (instance.practicalExercise?.trainingModule?.trainingSession && !trainingSessions.find(trainingSession => trainingSession?.id === instance.practicalExercise?.trainingModule?.trainingSession?.id)) {
                        trainingSessions.push(instance.practicalExercise?.trainingModule?.trainingSession)
                    } else if (instance.practicalExercise?.trainingModule === null && !trainingSessions.find(trainingSession => trainingSession?.id === "standalone")) {
                        trainingSessions.push({
                            title: "Standalone instances",
                            description: "Standalone instances",
                            id: "standalone"
                        })
                    }
                })

                return trainingSessions;
            },
        }),
        createInstance: builder.mutation<Instance, Instance>({
            query: (instance) => ({
                url: `/instances`,
                method: 'POST',
                body: instance,
            }),
            invalidatesTags: [{type: 'Instance', id: 'PARTIAL-LIST'}],
        }),
        planInstance: builder.mutation<Instance, string>({
            query: (id) => ({
                url: `/instances/${id}/plan`,
                method: 'POST',
                body: {},
            }),
            invalidatesTags: (post) => [{type: 'Instance', id: post?.id}],
        }),
        launchInstance: builder.mutation<Instance, { instanceId: string, isAutomaticallyDestroyed: boolean }>({
            query: (params) => ({
                url: `/instances/${params.instanceId}/launch`,
                method: 'POST',
                body: params.isAutomaticallyDestroyed,
            }),
            invalidatesTags: (post) => [{type: 'Instance', id: post?.id}],
        }),
        destroyInstance: builder.mutation<Instance, string>({
            query: (id) => ({
                url: `/instances/${id}/destroy`,
                method: 'POST',
                body: {},
            }),
            invalidatesTags: (post) => [{type: 'Instance', id: post?.id}],
        }),
        updateAnswer: builder.mutation<Answer, { instanceId: string, answer: string, questionId: string }>({
            query: (params) => ({
                url: `/instances/${params.instanceId}/answer`,
                method: 'POST',
                body: {answer: params.answer, questionId: params.questionId},
            }),
            invalidatesTags: ['Instance'],
        }),
        updateStatus: builder.mutation<Instance, { instanceId: string, newStatus: string }>({
            query: (params) => ({
                url: `/instances/${params.instanceId}/status`,
                method: 'PUT',
                body: params.newStatus,
            }),
            invalidatesTags: (put) => [{type: 'Instance', id: put?.id}],
        }),
        getInstanceGuideline: builder.query<string, string>({
            query: (id) => ({
                url: `/instances/${id}/guideline`,
                responseHandler: "text"
            }),
            providesTags: (_instance, _err, id) => [{type: 'Instance', id}],
        }),
        getInstanceInputs: builder.query<CustomizableInput[], string>({
            query: (id) => ({
                url: `/instances/${id}/inputs`
            }),
            providesTags: (_instance, _err, id) => [{type: 'Instance', id}],
        }),
        updateInstanceInputs: builder.mutation<Instance, { instanceId: string, newInputs: CustomizableInput[] }>({
            query: (params) => ({
                url: `/instances/${params.instanceId}/inputs`,
                method: 'PUT',
                body: params.newInputs,
            }),
            invalidatesTags: (put) => [{type: 'Instance', id: put?.id}],
        }),
    }),
})

export const {
    useDestroyInstanceMutation,
    useGetTraineeStandaloneInstancesQuery,
    useCreateInstanceMutation,
    useGetInstanceQuery, useLaunchInstanceMutation, usePlanInstanceMutation,
    useUpdateAnswerMutation,
    useGetTrainingModuleInstancesQuery,
    useGetTraineeTrainingSessionsQuery, useLazyGetInstanceLogsQuery,
    useLazyGetInstancesWithFiltersQuery, useLazyGetTraineeTrainingSessionsQuery, useCountInstancesQuery,
    useUpdateStatusMutation,
    useGetInstanceGuidelineQuery,
    useGetInstanceInputsQuery,
    useUpdateInstanceInputsMutation
} = instanceApi
