import React from 'react';

import '../App.css';
import UserInfo from "../components/UserInfo";
import BadgeList from "../components/BadgeList";
import Grid from "@mui/material/Grid";

type Props = {
    pageTitle: (arg: string) => void
};

const Home: React.FunctionComponent<Props> = ({pageTitle}) => {

    React.useEffect(() => {
        pageTitle("Home");
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <UserInfo/>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <BadgeList/>
            </Grid>
        </Grid>
    );
};

export default Home;
