import React from 'react';

import '../App.css';
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

type Props = {};

const PageNotFound: React.FunctionComponent<Props> = () => {
    const navigate = useNavigate();

    return (
        <Grid container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={5}>
            <Grid item xs={12} md={12} lg={12} textAlign={"center"}>
                <Typography component="h1" variant="h2" color="primary">
                    Page not found
                </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} textAlign={"center"}>
                <Typography component="h3" variant="h3">
                    (╯°□°）╯︵ ┻━┻
                </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} textAlign={"center"}>
                <Button variant="contained" onClick={() => navigate("/home")}>Go to home page</Button>
            </Grid>
        </Grid>
    )
        ;
};

export default PageNotFound;
