import {store} from "../slices";


// Return the user JWT found in store.
function getJWT(): string {
    return store.getState().user.token;
}

// Create a get response using the user token.
export function makeGetRequest(url: string): Promise<Response> {
    let headers = {};
    const options = {
        headers: new Headers(headers),
    };
    if (getJWT() !== '' && getJWT() != null) {
        options.headers.append('Authorization', `Bearer ${getJWT()}`);
    }
    return fetch(url, options);
}

