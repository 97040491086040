import React from 'react';

import Grid from "@mui/material/Grid";
import TrainingModuleList from "../components/catalog/trainingModule/TrainingModuleList";

type Props = {
    pageTitle: (arg: string) => void,
};

const TrainingModules: React.FunctionComponent<Props> = ({pageTitle}) => {

    React.useEffect(() => {
        pageTitle("Catalog - Training modules");
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <TrainingModuleList/>
            </Grid>
        </Grid>
    );
};

export default TrainingModules;
