import {Chip} from "@mui/material";
import React from "react";

type Props = {
    status?: string;
};

const InstanceStatusChip: React.FunctionComponent<Props> = ({status}) => {
    function switchColor(status: string | undefined) {
        switch (status) {
            case 'CREATED':
                return <Chip size={"small"} label={status} color="primary" variant="outlined"/>
            case 'STARTING':
                return <Chip size={"small"} label={status} color="primary" variant="outlined"/>
            case 'STARTED':
                return <Chip size={"small"} label={status} color="success" variant="outlined"/>
            case 'FAILED':
                return <Chip size={"small"} label={status} color="error" variant="outlined"/>
            case 'DESTROYED':
                return <Chip size={"small"} label={status} color="warning" variant="outlined"/>
            default:
                return <Chip size={"small"} label={status} color="default" variant="outlined"/>
        }
    }

    return (
        switchColor(status)
    );
}

export default InstanceStatusChip;
