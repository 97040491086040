import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {API_URL} from "../api/ConfigApi";
import TerraformScript from "../types/TerraformScript";
import {prepareHeaders} from "./ServiceUtils";
import {RootState} from "../slices";

export const terraformApi = createApi({
    reducerPath: 'terraformApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL + '/terraform',
        prepareHeaders: (headers, {getState}) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            return prepareHeaders(headers, getState() as RootState)
        },
    }),
    tagTypes: ['TerraformScripts'],
    endpoints: (builder) => ({
        getAllTerraformScripts: builder.query<Array<TerraformScript>, void>({
            query: () => `/scripts`,
            providesTags: ['TerraformScripts'],

            transformResponse: (rawResult: Array<TerraformScript>, meta) => {
                if (meta?.response?.status === 204) {
                    return [];
                }
                return rawResult;
            }
        }),
    }),
})

export const {
    useGetAllTerraformScriptsQuery
} = terraformApi