import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {API_URL} from "../api/ConfigApi";
import {prepareHeaders} from "./ServiceUtils";
import {RootState} from "../slices";
import VersionedModule from "../types/VersionedModule";
import VersionedExercise from "../types/VersionedExercise";

export const configApi = createApi({
    reducerPath: 'configApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL + '/config',
        prepareHeaders: (headers, {getState}) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            return prepareHeaders(headers, getState() as RootState)
        },
    }),
    tagTypes: ['Config'],
    endpoints: (builder) => ({
        getBackendVersion: builder.query<string, void>({
            query: () => ({
                url: `/version`,
                responseHandler: "text",
            }),
            providesTags: ['Config']
        }),
        getModulesVersion: builder.query<VersionedModule[], void>({
            query: () => ({
                url: `/modules/version`,
            }),
            providesTags: ['Config']
        }),
        getExercisesVersion: builder.query<VersionedExercise[], void>({
            query: () => ({
                url: `/exercises/version`,
            }),
            providesTags: ['Config']
        }),
    }),
})

export const {
    useGetBackendVersionQuery, useGetModulesVersionQuery, useGetExercisesVersionQuery
} = configApi