import React, {useEffect} from "react";
import {Flipped, Flipper} from "react-flip-toolkit";
import {useLazyGetInstancesWithFiltersQuery} from "../../services/InstanceService";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import {Item, LinearProgressWithLabel} from "../utils/Utils";
import Instance from "../../types/Instance";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {number} from "prop-types";
import moment from "moment";
import Confetti from "react-dom-confetti";


type Props = {
    trainingSessionId: string,
    exerciseId: string
};

const ExerciseLeaderboard: React.FunctionComponent<Props> = ({trainingSessionId, exerciseId}) => {
    const [triggerGetInstances,
        {
            data: instances = {content: [], totalElements: 0},
        }] = useLazyGetInstancesWithFiltersQuery({pollingInterval: 5000});
    const [orderedInstances, setOrderedInstances] = React.useState<Instance[]>();
    const theme = useTheme();

    useEffect(() => {
        triggerGetInstances({
            pageNo: 0,
            sessionId: trainingSessionId,
            exerciseId: exerciseId,
            pageSize: 100
        });
    }, [triggerGetInstances]);

    useEffect(() => {
        let previousRanking = orderedInstances;
        let newRanking = [...instances.content].sort(compareInstancesRank);
        newRanking.forEach((instance: Instance) => {
            if (calculateExerciseProgression(instance) === 100) {
                let previousInstance = previousRanking?.filter((previousInstance) => previousInstance.id === instance.id)
                if (previousInstance?.length === 1 && calculateExerciseProgression(previousInstance[0]) < 100) {
                    explode();
                }
            }
        })
        setOrderedInstances(newRanking);
    }, [instances.content]);

    function compareInstancesRank(a: Instance, b: Instance) {
        const aCorrectAnswers = a?.survey?.filter(answer => answer.correct);
        const bCorrectAnswers = b?.survey?.filter(answer => answer.correct);
        if (aCorrectAnswers !== undefined && bCorrectAnswers !== undefined) {
            if (aCorrectAnswers.length !== bCorrectAnswers.length) {
                return bCorrectAnswers.length - aCorrectAnswers.length;
            } else {
                return moment(a.lastCorrectAnswerDate).isBefore(b.lastCorrectAnswerDate) ? -1 : 1;
            }
        } else {
            return moment(a.lastCorrectAnswerDate).isBefore(b.lastCorrectAnswerDate) ? -1 : 1;
        }
    }

    function getRankIcon(rank: number) {
        switch (rank) {
            case 0 :
                return "🎖️";
            case 1 :
                return "🥈";
            case 2 :
                return "🥉";
            default:
                return number;
        }
    }

    function getItemBackgroundColor(rank: number) {
        switch (rank) {
            case 0 :
                return theme.palette.primary.main;
            case 1 :
                return theme.palette.primary.main;
            case 2 :
                return theme.palette.primary.main;
            default:
                return "inherit";
        }
    }

    function getItemBackgroundImage(rank: number) {
        switch (rank) {
            case 0 :
                return theme.palette.mode === 'dark' ? "linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05))" : "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))";
            case 1 :
                return theme.palette.mode === 'dark' ? "linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25))" : "linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25))";
            case 2 :
                return theme.palette.mode === 'dark' ? "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))" : "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))";
            default:
                return "inherit";
        }
    }

    function getItemColor(rank: number) {
        switch (rank) {
            case 0 :
                return theme.palette.grey["100"];
            case 1 :
                return theme.palette.grey["100"];
            case 2 :
                return theme.palette.grey["100"];
            default:
                return "inherit";
        }
    }

    function calculateExerciseProgression(instance: Instance) {

        if (instance.survey) {
            return instance.survey?.filter(answer => answer.correct).length / instance.survey?.length * 100;
        }
        return 0;

    }

    const [isVisible, setIsVisible] = React.useState(false);
    const delay = (ms: number) => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    const config = {
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 150,
        dragFriction: 0.12,
        duration: 3000,
        stagger: 3,
        width: "10px",
        height: "10px",
        perspective: "1000px",
        colors: ['#FFC700', '#FF0000', '#2E3191', '#41BBC7']
    };
    const explode = async () => {
        setIsVisible(true)
        await delay(100);
        setIsVisible(false)
    };
    return (
        <Flipper
            flipKey={orderedInstances?.map(({id}) => id).join(".")}
            spring="veryGentle"
            staggerConfig={{
                default: {
                    reverse: false,
                    speed: .1
                }
            }}
            decisionData={orderedInstances}
        >
            <List sx={{width: '100%'}}>
                <Grid container sx={{
                    backgroundColor: theme.palette.background.paper,
                    backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                }}>

                    {orderedInstances?.map((instance, index) => (
                        <Grid item xs={12} md={12} lg={12} p={0.5} key={"leaderboard-" + instance.id}>
                            <Flipped key={instance.id} flipId={instance.id}>
                                <Item elevation={2} sx={{
                                    backgroundColor: getItemBackgroundColor(index),
                                    backgroundImage: getItemBackgroundImage(index),
                                    color: getItemColor(index),
                                    overflow: 'visible'
                                }}
                                >
                                    <Grid container p={2} alignItems="center">
                                        <Grid item xs={1}>
                                            <Typography textAlign={"center"} fontSize={index < 3 ? "2.5em" : "1.5em"}>
                                                {index < 3 ? getRankIcon(index) : index + 1}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography noWrap fontSize={index < 3 ? "1.7em" : "1em"} ml={1}>
                                                {instance.trainee}
                                            </Typography>
                                            {index === 0 && <Confetti config={config} active={isVisible}/>}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <LinearProgressWithLabel
                                                sx={{
                                                    backgroundColor: theme.palette.grey["300"],
                                                    '& .MuiLinearProgress-bar': {
                                                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.dark
                                                    },
                                                    height: 13,
                                                    borderRadius: 5,
                                                }}
                                                value={calculateExerciseProgression(instance)}/>
                                        </Grid>
                                    </Grid>
                                </Item>
                            </Flipped>
                        </Grid>
                    ))}
                </Grid>
            </List>
        </Flipper>
    );
};

export default ExerciseLeaderboard;