import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes, {any} from 'prop-types';
import {Calendar, DateLocalizer, Views} from 'react-big-calendar';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {CardContent, CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CustomWeekView from "./CustomWeekView";
import moment from "moment";
import {styled, useTheme} from "@mui/material/styles";

const DragAndDropCalendar = withDragAndDrop(Calendar);

export default function TrainingSessionModulesCalendar({
                                                           localizer,
                                                           events,
                                                           modules,
                                                           onCalendarChange,
                                                           onEventChange,
                                                           defaultDate
                                                       }) {
    const [draggedEvent, setDraggedEvent] = useState()
    const [availableModules, setAvailableModules] = useState(modules)
    const [myEvents, setMyEvents] = useState(events)
    const [currentView, setCurrentView] = useState(Views.WEEK)
    const [currentDate, setCurrentDate] = useState(defaultDate)

    const theme = useTheme();

    const StyledDragAndDropCalendar = styled(DragAndDropCalendar)(() => ({
        '.rbc-today': {
            backgroundColor: "rgba(86, 172, 191, 0.24)"
        },
        '.rbc-toolbar button': {
            color: theme.palette.text.primary
        },
        '.rbc-toolbar button.rbc-active': {
            backgroundColor: theme.palette.primary.main,
            color: "#fff"
        },
        '.rbc-time-view': {
            backgroundColor: theme.palette.mode === 'dark' ? "rgba(86, 172, 191, 0.13)" : theme.palette.background.paper,
            color: theme.palette.text.secondary,
            borderRadius: "5px"
        },
        '.rbc-day-slot .rbc-time-slot': {
            border: "none"
        },
        '.rbc-day-slot .rbc-event': {
            border: "none"
        },
        '.rbc-event, .rbc-day-slot .rbc-background-event': {
            backgroundColor: theme.palette.primary.main
        },
        '.rbc-toolbar button:hover': {
            backgroundColor: theme.palette.mode === 'dark' ? "rgba(255, 255, 255, 0.08)" : "#e6e6e6"
        },
        '.rbc-toolbar button:focus': {
            backgroundColor: theme.palette.primary.main,
            color: "#fff"
        },
        '.rbc-off-range-bg': {
            backgroundColor: theme.palette.background.default,
        }
    }));

    const eventPropGetter = useCallback(
        (event) => ({
            ...(event.isDraggable
                ? {className: 'isDraggable'}
                : {className: 'nonDraggable'}),
        }),
        []
    )

    useEffect(() => {
        handleCalendarChange(myEvents);
    }, [myEvents])

    useEffect(() => {
        setMyEvents(events)
    }, [events])

    useEffect(() => {
        setAvailableModules(modules);
    }, [modules])

    const handleCalendarChange = (events) => {
        onCalendarChange && onCalendarChange(events);
    }

    const handleEventChange = (event) => {
        onEventChange && onEventChange(event);
    }

    const handleDragStart = useCallback((event) => setDraggedEvent(event), [])

    const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent])

    const customOnDragOver = useCallback(
        (dragEvent) => {
            if (draggedEvent !== 'undroppable') {
                dragEvent.preventDefault()
            }
        },
        [draggedEvent]
    )

    const moveEvent = useCallback(
        ({event, start, end, isAllDay: droppedOnAllDaySlot = false}) => {
            const {allDay} = event
            if (!allDay && droppedOnAllDaySlot) {
                event.allDay = true
            }

            setMyEvents((prev) => {
                const existing = prev.find((ev) => ev.id === event.id) ?? {}
                const filtered = prev.filter((ev) => ev.id !== event.id)
                return [...filtered, {...existing, start, end, allDay}]
            })
            handleEventChange({id: event.id, start: start, end: end});
        },
        [setMyEvents]
    )

    const newEvent = useCallback(
        (event) => {
            setMyEvents((prev) => {
                return [...prev, {
                    ...event,
                    id: event.id,
                    isDraggable: true,
                    end: moment(event.end).add(90, 'minutes').toDate()
                }]
            })
            setAvailableModules((prev) => {
                    const newAvailableModules = prev.filter((module) => module.id !== event.id)
                    return [...newAvailableModules]
                }
            )
        },
        [setMyEvents]
    )

    const onDropFromOutside = useCallback(
        ({start, end, allDay: isAllDay}) => {
            if (draggedEvent === 'undroppable') {
                setDraggedEvent(null)
                return
            }

            const {name, id} = draggedEvent
            const event = {
                id: id,
                title: name,
                start,
                end,
                isAllDay,
            }
            setDraggedEvent(null)

            newEvent(event)
        },
        [draggedEvent, setDraggedEvent, newEvent]
    )

    const resizeEvent = useCallback(
        ({event, start, end}) => {
            setMyEvents((prev) => {
                const existing = prev.find((ev) => ev.id === event.id) ?? {}
                const filtered = prev.filter((ev) => ev.id !== event.id)
                return [...filtered, {...existing, start, end}]
            })
            handleEventChange({id: event.id, start: start, end: end});
        },
        [setMyEvents]
    )

    const {views} = useMemo(() => ({
        views: {
            month: true,
            week: CustomWeekView,
            day: true
        },
    }), [])

    const onNavigate = (date) => {
        setCurrentDate(date);
    }

    const onView = (view) => {
        setCurrentView(view);
    }

    return (
        <Fragment>
            {availableModules.length > 0 && <CardHeader className="dndOutsideSourceExample" title={<>
                <Typography component="h2" variant="h6" color="primary" gutterBottom mb={3}>
                    Drag and drop following modules
                </Typography>
                <div>
                    {availableModules.map((module) => (
                        <Button draggable="true"
                                key={module.id} onDragStart={() =>
                            handleDragStart({name: module.name, id: module.id})
                        } sx={{cursor: "grab", marginRight: "0.5em"}} variant="contained">{module.name}</Button>
                    ))}
                </div>
            </>
            }>
            </CardHeader>}

            <CardContent>
                <div style={{height: "550px"}}>
                    <StyledDragAndDropCalendar
                        defaultDate={defaultDate}
                        date={currentDate}
                        view={currentView}
                        views={views}
                        dragFromOutsideItem={dragFromOutsideItem}
                        draggableAccessor="isDraggable"
                        eventPropGetter={eventPropGetter}
                        events={myEvents}
                        localizer={localizer}
                        onDropFromOutside={onDropFromOutside}
                        onDragOver={customOnDragOver}
                        onEventDrop={moveEvent}
                        onEventResize={resizeEvent}
                        onNavigate={onNavigate}
                        onView={onView}
                        resizable
                        selectable
                    />
                </div>
            </CardContent>
        </Fragment>
    )
}

TrainingSessionModulesCalendar.propTypes = {
    localizer: PropTypes.instanceOf(DateLocalizer),
    events: any,
    availableModules: any,
    onCalendarChange: any,
    onEventChange: any
}