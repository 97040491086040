import React, {useMemo} from "react";
import {CardContent, CardHeader, Menu, MenuItem, Tab, Tabs, Theme} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useNavigate, useParams} from "react-router-dom";
import BreadcrumbItem from "../../../types/BreadcrumbItem";
import {LearningPathGeneral} from "./LearningPathGeneral";
import {LearningPathCatalogModuleList} from "./LearningPathCatalogModuleList";
import {LearningPathCatalogLinksEditor} from "./LearningPathCatalogLinksEditor";
import {a11yProps, Item, TabPanel} from "../../utils/Utils";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SnackBarUtils from "../../SnackBarUtils";
import {useTheme} from "@mui/material/styles";
import {useDeleteLearningPathMutation, useGetLearningPathQuery} from "../../../services/CatalogService";

type Props = {
    pageTitle: (arg: string) => void,
    breadCrumbItems: (arg: BreadcrumbItem[]) => void
}
const LeaningPathCatalogDetails: React.FunctionComponent<Props> = (props: Props) => {

    const theme = useTheme<Theme>();
    const navigate = useNavigate();
    const {id} = useParams<{ id: any }>()
    const {
        data: learningPath = {}
    } = useGetLearningPathQuery(id);
    const [deleteLearningPath] = useDeleteLearningPathMutation();
    const [anchorMoreButtonEl, setAnchorMoreButtonEl] = React.useState<null | HTMLElement>(null);
    const moreButtonMenu = Boolean(anchorMoreButtonEl);
    const [tabValue, setTabValue] = React.useState(0);
    const items: BreadcrumbItem[] = useMemo(() => [{title: 'Training', link: '/learningPaths'}, {
        title: 'Details',
        link: '/learningPaths/' + id
    }], [id]);
    React.useEffect(() => {
        props.breadCrumbItems(items);
    }, [items, props]);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    }

    const handleMoreButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorMoreButtonEl(event.currentTarget);
    };

    const handleMoreButtonClose = () => {
        setAnchorMoreButtonEl(null);
    };

    const handleMoreButtonActionDeleteLearningPath = () => {
        if (learningPath.id) {
            deleteLearningPath(learningPath.id).unwrap().then(() => {
                SnackBarUtils.info('Learning path deleted');
                navigate('/learningPaths');
            }).catch(() => {
                SnackBarUtils.error('Error happened while deleting learning path');
            });
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
                <Item>
                    <CardHeader title={
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={8}>
                                {learningPath.name}
                            </Grid>
                            <Grid item xs={4} sx={{textAlign: 'right'}}>
                                {learningPath?.id &&
                                    <>
                                        <IconButton
                                            aria-label="Plus d'actions"
                                            sx={{marginLeft: 2}}

                                            onClick={handleMoreButtonClick}
                                        >
                                            <MoreVertIcon/>
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorMoreButtonEl}
                                            open={moreButtonMenu}
                                            onClose={handleMoreButtonClose}
                                        >
                                            <MenuItem
                                                onClick={handleMoreButtonActionDeleteLearningPath}
                                                sx={{color: theme.palette.error.main}}>
                                                Delete learning path
                                            </MenuItem>
                                        </Menu>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    }/>
                    <CardContent sx={{overflow: "auto", paddingY: 0}}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                                    <Tab label="GENERAL" {...a11yProps(0)} />
                                    <Tab label="MODULES" {...a11yProps(1)} />
                                    <Tab label="LINKS" {...a11yProps(2)} />
                                </Tabs>
                                <TabPanel value={tabValue} index={0}>
                                    <LearningPathGeneral learningPath={learningPath}></LearningPathGeneral>
                                </TabPanel>
                                <TabPanel value={tabValue} index={1}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <LearningPathCatalogModuleList
                                            learningPath={learningPath}></LearningPathCatalogModuleList>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tabValue} index={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <LearningPathCatalogLinksEditor
                                                learningPath={learningPath}></LearningPathCatalogLinksEditor>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Item>
            </Grid>
        </Grid>
    )
        ;
}

export default LeaningPathCatalogDetails;
