import React, {useState} from 'react';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from "@mui/lab/LoadingButton";
import SnackBarUtils from "../../SnackBarUtils";
import {useNavigate} from "react-router-dom";
import {Level} from "../../../types/LearningPath";
import {InputLabel, ToggleButton} from "@mui/material";
import {SignalCellularAlt} from "@mui/icons-material";
import {StyledToggleButtonGroup} from "../NewPracticalExerciseCatalog";
import {useCreateLearningPathMutation} from "../../../services/CatalogService";

type Props = {};

const NewLearningPathCatalog: React.FunctionComponent<Props> = () => {

    const [open, setOpen] = React.useState(false);
    const [name, setName] = useState('');
    const [level, setLevel] = useState<Level>(Level.BEGINNER);
    const [imageLink, setImageLink] = useState('');
    const [description, setDescription] = useState('');
    const [nameError, setNameError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [imageLinkError, setImageLinkError] = useState(false);
    const [createLearningPath, {isLoading: isCreatingLearningPath}] = useCreateLearningPathMutation();
    const navigate = useNavigate();


    function getToggleColor() {
        switch (level) {
            case Level.BEGINNER :
                return "success";
            case Level.INTERMEDIATE :
                return "warning";
            case Level.EXPERT:
                return "error";
            default:
                return "info"
        }
    }

    function resetDatas() {
        setName('');
        setDescription('');
        setLevel(Level.BEGINNER)
        setImageLink('')
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        resetDatas();
    };

    const handleCreate = () => {
        createLearningPath({
            name: name,
            description: description,
            imageLink: imageLink,
            level: level
        }).unwrap().then((data) => {
            SnackBarUtils.info('Learning path successfully created')
            handleClose();
            navigate('/learningPaths/' + data.id);
        }).catch(() => {
            SnackBarUtils.error('Error happened while creating learning path.')
        })
    };

    return (
        <div>
            <Button variant="contained" size={"small"} disableElevation onClick={handleClickOpen}>New learning
                path</Button>
            <Dialog PaperProps={{
                style: {
                    backgroundImage: "none"
                },
            }} open={open}>
                <DialogTitle>New learning path</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        error={nameError}
                        disabled={isCreatingLearningPath}
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setNameError(true)
                            } else {
                                setNameError(false)
                                setName(e.target.value)
                            }
                        }}
                        required={true}
                        helperText={nameError ? "Please enter a name." : ""}
                    />
                    <TextField
                        margin="dense"
                        id="description"
                        label="Description"
                        type="text"
                        fullWidth
                        variant="standard"
                        error={descriptionError}
                        disabled={isCreatingLearningPath}
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setDescriptionError(true)
                            } else {
                                setDescriptionError(false)
                                setDescription(e.target.value)
                            }
                        }}
                        required={true}
                        helperText={nameError ? "Please enter a description." : ""}
                    />
                    <TextField
                        margin="dense"
                        id="image-link"
                        label="Image link"
                        type="text"
                        fullWidth
                        variant="standard"
                        error={imageLinkError}
                        disabled={isCreatingLearningPath}
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setImageLinkError(true)
                            } else {
                                setImageLinkError(false)
                                setImageLink(e.target.value)
                            }
                        }}
                        required={true}
                        helperText={nameError ? "Please enter an image link." : ""}
                    />
                    <InputLabel htmlFor="level"
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "11px",
                                    marginTop: "13px",
                                    marginBottom: "4px"
                                }}>
                        Level
                    </InputLabel>
                    <StyledToggleButtonGroup
                        fullWidth
                        size="small"
                        color={getToggleColor()}
                        value={level}
                        exclusive
                        onChange={(e, value) => setLevel(value)}
                        aria-label="Level"
                    >
                        <ToggleButton value="BEGINNER"><SignalCellularAlt/>Beginner</ToggleButton>
                        <ToggleButton value="INTERMEDIATE"><SignalCellularAlt/>INTERMEDIATE</ToggleButton>
                        <ToggleButton value="EXPERT"><SignalCellularAlt/>EXPERT</ToggleButton>
                    </StyledToggleButtonGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isCreatingLearningPath}>Cancel</Button>
                    <LoadingButton
                        size="small"
                        onClick={handleCreate}
                        disableElevation
                        loading={isCreatingLearningPath}
                        variant="contained"
                    >
                        <span>Create</span>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default NewLearningPathCatalog;
