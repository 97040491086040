import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {Avatar, Menu, MenuItem, Tooltip} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {RootState} from "../../slices";
import {logout} from "../../helpers/auth";
import {useKeycloak} from "@react-keycloak/web";
import {useGetTraineeQuery} from "../../services/TraineeService";
import {useTheme} from "@mui/material/styles";
import {stringAvatar} from "../utils/Utils";

const HeaderLoggedMenu: React.FunctionComponent = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {keycloak} = useKeycloak();
    const theme = useTheme();

    const {} = useGetTraineeQuery();

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const onProfileClick = () => {
        navigate("/profile");
    };

    async function onLogoutClick() {
        await logout(keycloak, dispatch);
    }

    const firstname = useSelector((state: RootState) => state.user.firstname);
    const lastname = useSelector((state: RootState) => state.user.lastname);

    const {data: trainee} = useGetTraineeQuery();

    return (
        <Box sx={{flexGrow: 0}}>
            <Tooltip title={`${firstname} ${lastname} ${trainee?.pseudonym ? `(${trainee.pseudonym})` : ''}`}>
                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                    {
                        firstname && lastname ?
                            <Avatar {...stringAvatar(firstname, lastname)}
                                    sx={{
                                        color: theme.palette.primary.contrastText,
                                        backgroundColor: theme.palette.primary.main
                                    }}/> :
                            <Avatar {...stringAvatar("Unknown", "Unknown")}
                                    sx={{
                                        color: theme.palette.primary.contrastText,
                                        backgroundColor: theme.palette.primary.main
                                    }}
                            />
                    }
                </IconButton>
            </Tooltip>
            <Menu
                sx={{mt: '45px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem onClick={onProfileClick}>
                    <Typography textAlign="center">My account</Typography>
                </MenuItem>
                <MenuItem onClick={onLogoutClick}>
                    <Typography textAlign="center">Logout</Typography>
                </MenuItem>
            </Menu>
        </Box>
    );
}

export default HeaderLoggedMenu;