import React, {useState} from 'react';
import {
    CircularProgress,
    FormControl,
    FormHelperText,
    InputAdornment,
    OutlinedInput,
    SxProps,
    Theme
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import {makeStyles} from "@mui/styles";
import {useTheme} from "@mui/material/styles";

type Props = {
    initialValue?: string,
    handleUpdate: (arg: string) => Promise<boolean>,
    displayAcceptButtons?: boolean,
    fullWidth?: boolean,
    sx?: SxProps<Theme>,
    maxLength?: number
};

const InteractiveOutlinedInput: React.FunctionComponent<Props> = (
    {initialValue = "", handleUpdate, displayAcceptButtons = true, fullWidth = false, sx, maxLength}: Props,
) => {
    const [value, setValue] = useState("");
    const [oldValue, setOldValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [editButtonGroup, setEditButtonGroup] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    React.useEffect(() => {
        if (initialValue != null) {
            setValue(initialValue);
            setOldValue(initialValue)
        }
    }, [initialValue]);

    React.useEffect(() => {
        setError(Boolean(maxLength && value.length > maxLength))
    }, [maxLength, value])

    const theme = useTheme<Theme>();
    const useStyles = makeStyles(() => ({
        root: {
            "& .MuiOutlinedInput-input": {
                padding: 1,
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
                transition: "all 0.5s",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "solid 1px",
                borderColor: theme.palette.primary.main,
                transition: "all 0.5s",
            },
            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "solid 1px",
                borderColor: theme.palette.primary.main,
                transition: "all 0.5s"
            }
        },
    }));
    const classes = useStyles();

    const handleKeyboardEvent = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.currentTarget.blur();
        } else if (e.key === "Escape") {
            handleClose();
        }
    }
    const handleAccept = (
        event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (event?.relatedTarget?.id === "button-clear" || error) {
            return;
        }
        setEditButtonGroup(false);
        if (value === oldValue) {
            return;
        }
        handleUpdate(value.trim()).then(() => {
            setOldValue(value);
            setIsLoading(false);
        }).catch(() => {
            setValue(oldValue);
            setOldValue(oldValue);
            setIsLoading(false);
        });
        setOldValue(value);
        setIsLoading(true);

    };
    const handleClick = (
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setEditButtonGroup(true);
    };

    const handleClose = () => {
        setEditButtonGroup(false);
        setValue(oldValue);

    };

    return (
        <React.Fragment>
            <FormControl fullWidth={fullWidth}>
                <OutlinedInput
                    classes={classes}
                    value={value}
                    sx={sx}
                    error={error}
                    onFocus={handleClick}
                    onBlur={(e) => handleAccept(e)}
                    onChange={(e) => setValue(e.target.value)}
                    onKeyDown={(e) => handleKeyboardEvent(e)}
                    endAdornment={
                        <>
                            {
                                (displayAcceptButtons && editButtonGroup) &&
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Accept changes"
                                        onMouseDown={() => handleAccept()}
                                        edge="end"
                                        disabled={error}
                                        sx={{margin: 0}}>
                                        <CheckIcon/>
                                    </IconButton>
                                    <IconButton
                                        aria-label="Cancel changes"
                                        onMouseDown={handleClose}
                                        edge="end"
                                        sx={{margin: 0}}>
                                        <ClearIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }
                            {
                                isLoading &&
                                <CircularProgress
                                    sx={{padding: 1}}/>
                            }
                        </>
                    }
                />
                {error && (
                    <FormHelperText error id="max-length-error">
                        {`Input length must be ${maxLength} characters or less`}
                    </FormHelperText>
                )}
            </FormControl>
        </React.Fragment>

    );
}

export default InteractiveOutlinedInput;
