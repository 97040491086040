import Event from "../types/Event";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {API_URL} from "../api/ConfigApi";
import {prepareHeaders} from "./ServiceUtils";
import {RootState} from "../slices";

export const eventApi = createApi({
    reducerPath: 'eventApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL + '/events',
        prepareHeaders: (headers, {getState}) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            return prepareHeaders(headers, getState() as RootState)
        },
    }),
    tagTypes: ['Event'],
    endpoints: (builder) => ({
        getEvents: builder.query<Event[], { page: number; level?: string }>({
            query: (arg) => {
                const {page, level} = arg;
                return {
                    url: "",
                    params: {page, level},
                };
            },
            providesTags: ['Event'],
            transformResponse: (rawResult: Array<Event>, meta) => {
                //                                                        ^
                // The optional `meta` property is available based on the type for the `baseQuery` used
                if (meta?.response?.status === 204) {
                    return [];
                }
                // The return value for `transformResponse` must match `ResultType`
                return rawResult;
            },
        }),
        getEventLongDescription: builder.query<string, string>({
            query: (id) => ({
                url: `/${id}/longdescription`,
                responseHandler: "text",
            }),
        }),
    }),
})

export const {
    useGetEventsQuery,
    useLazyGetEventLongDescriptionQuery,
} = eventApi