import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import React, {useEffect} from "react";
import TrainingSession from "../../types/TrainingSession";
import PracticalExerciseList from "../practicalExercise/PracticalExerciseList";
import TrainingModule from "../../types/TrainingModule";
import {useSearchParams} from "react-router-dom";

type Props = {
    trainingSession: TrainingSession
};

const TrainingSessionModuleSelect: React.FunctionComponent<Props> = ({trainingSession}) => {
    const [selectedModule, setSelectedModule] = React.useState<TrainingModule>({});
    const [searchParams, setSearchParams] = useSearchParams();

    const handleModuleSelection = (
        event: React.MouseEvent<HTMLElement>,
        newModule: TrainingModule | null,
    ) => {
        if (newModule && newModule.id) {
            setSelectedModule(newModule);
            setSearchParams({["selectedModule"]: newModule.id});
        }
    };
    useEffect(() => {
        let selectionModuleId = searchParams.get("selectedModule") ? searchParams.get("selectedModule") : selectedModule.id;
        let firstModule = trainingSession?.trainingModules?.find((module) => module !== undefined);
        if (firstModule && !selectionModuleId) {
            setSelectedModule(firstModule)
        } else {
            let updatedSelectedModule = trainingSession?.trainingModules?.find((module) => module.id === selectionModuleId);
            updatedSelectedModule && setSelectedModule(updatedSelectedModule)
        }
    }, [trainingSession])
    return (
        <>
            <ToggleButtonGroup
                value={selectedModule}
                exclusive
                color="primary"
                size={"small"}
                onChange={handleModuleSelection}
                aria-label="module selection"
            >
                {trainingSession.trainingModules?.map((module) => {
                    return <ToggleButton key={module.id} value={module}
                                         aria-label={module.name}>{module.name}</ToggleButton>
                })}

            </ToggleButtonGroup>
            {
                selectedModule.practicalExercises &&
                <PracticalExerciseList trainingSession={trainingSession} module={selectedModule}/>
            }
        </>
    );
}

export default TrainingSessionModuleSelect;
