import React, {useEffect, useMemo, useRef} from 'react';

import Grid from "@mui/material/Grid";
import {Link, useNavigate, useParams} from "react-router-dom";
import Ansi from "ansi-to-react";


import {CardContent, CardHeader, DialogProps, Fab, ListItem, Tooltip, useMediaQuery} from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment/moment";
import StartStopInstance from "./StartStopInstance";
import InstanceOutputForm from "./InstanceOutputForm";
import {useGetInstanceQuery, useLazyGetInstanceLogsQuery} from "../../services/InstanceService";
import Typography from "@mui/material/Typography";
import BreadcrumbItem from "../../types/BreadcrumbItem";
import {Item} from "../utils/Utils";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useTheme} from '@mui/material/styles';
import IconButton from "@mui/material/IconButton";
import {Download, KeyboardDoubleArrowDown, OpenInNew, PreviewOutlined, Refresh} from "@mui/icons-material";
import InstanceStatusSelect from "./InstanceStatusSelect";
import {InstanceParametersEditor} from "./InstanceParametersEditor";

type Props = {
    pageTitle: (arg: string) => void,
    breadCrumbItems: (arg: BreadcrumbItem[]) => void
};

const beautifyLogs = (logs: string) => {
    return logs?.split("\n").map((logLine, index) => <li key={index}><Ansi>{logLine}</Ansi></li>)
};
const InstanceDetails: React.FunctionComponent<Props> = ({pageTitle, breadCrumbItems}) => {

    const {id} = useParams<{ id: any }>()
    const {
        data: instance = {id: '', trainee: ''},
    } = useGetInstanceQuery(id, {pollingInterval: 1000});

    const params = useParams();
    const navigate = useNavigate();

    function getBreadCrumbItems(sessionId?: string, sessionTitle?: string, exerciseId?: string, instanceId?: string): BreadcrumbItem[] {
        let items: BreadcrumbItem[] = [];
        instance.practicalExercise?.trainingModule?.trainingSession?.id && instance.practicalExercise?.id && items.push({
            title: 'Sessions',
            link: '/sessions'
        }, {
            title: instance.practicalExercise?.trainingModule?.trainingSession?.title,
            link: '/sessions/' + instance.practicalExercise?.trainingModule?.trainingSession?.id
        }, {
            title: 'Exercise',
            link: '/sessions/' + instance.practicalExercise?.trainingModule?.trainingSession?.id + '/exercises/' + instance.practicalExercise?.id
        }, {title: 'Instance', link: '/instances/' + id})
        return items;
    }

    const items: BreadcrumbItem[] = useMemo(() => {
        return getBreadCrumbItems(instance.practicalExercise?.trainingModule?.trainingSession?.id, instance.practicalExercise?.trainingModule?.trainingSession?.title, instance.practicalExercise?.id, id);
    }, [instance, id]);

    const [openLogsDialog, setOpenLogsDialog] = React.useState(false);
    const [logsDialogScroll, setLogsDialogScroll] = React.useState<DialogProps['scroll']>('paper');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const bottomElement = useRef<null | HTMLDivElement>(null);
    const [triggerGetInstanceLogs,
        {
            data: logs = ""
        }] = useLazyGetInstanceLogsQuery();
    React.useEffect(() => {
        breadCrumbItems(items);
    }, [breadCrumbItems, items]);

    const refreshLogs = () => () => {
        triggerGetInstanceLogs(id)
    };

    React.useEffect(() => {
        triggerGetInstanceLogs(id);
    }, [id]);

    useEffect(() => {
        pageTitle("Instances");
    }, [params.id, navigate, pageTitle]);

    const downloadLogFile = (fileContent: string) => {
        const element = document.createElement("a");
        const file = new Blob([fileContent], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "instance-" + id + ".log";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    const handleClickOpenLogsDialog = (scrollType: DialogProps['scroll']) => () => {
        setOpenLogsDialog(true);
        setLogsDialogScroll(scrollType);
    };
    const handleClickDownloadLogs = () => () => {
        triggerGetInstanceLogs(id).unwrap().then((response) => {
            downloadLogFile(response);
        });
    };

    const handleClickCloseLogsDialog = () => {
        setOpenLogsDialog(false);
    };

    const scrollToBottom = () => {
        bottomElement?.current?.scrollIntoView();
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Item>
                        <CardHeader title={
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography component="div" variant="h6" color="primary">
                                        {instance.practicalExercise?.name + " " + instance.trainee}
                                        <Tooltip title="Trainee's view" placement="bottom">
                                            <IconButton sx={{marginLeft: 2}} aria-label="preview" component={Link}
                                                        to={"/view/" + instance.id}>
                                                <PreviewOutlined color={"primary"}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <ListItem sx={{padding: 0, textAlign: "right"}}>
                                        {
                                            instance.status !== undefined &&
                                            <ListItemText primary={<InstanceStatusSelect
                                                currentStatus={instance.status}
                                                instanceId={instance.id}></InstanceStatusSelect>}
                                                          primaryTypographyProps={{component: 'div'}}
                                            />
                                        }
                                    </ListItem>
                                </Grid>
                                <Grid item xs={3} sx={{textAlign: 'right'}}>
                                    <StartStopInstance instance={instance}/>
                                </Grid>
                            </Grid>
                        }/>
                        <CardContent sx={{height: 180, overflow: "auto", paddingY: 0}}>
                            <Grid container spacing={0}>
                                <Grid item xs={4} md={4} lg={4}>
                                    <List>
                                        <ListItem sx={{padding: 0}}>
                                            <ListItemText primaryTypographyProps={{fontWeight: 550}}
                                                          primary="Creation date"
                                                          secondary={moment(instance.createdDate).format("L LT")}/>
                                        </ListItem>
                                        <ListItem sx={{padding: 0}}>
                                            <ListItemText primaryTypographyProps={{fontWeight: 550}}
                                                          primary="Automatically destroyed at night"
                                                          secondary={instance.automaticallyDestroyed ? "Yes" : "No"}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <List>
                                        <ListItem sx={{padding: 0}}>
                                            <ListItemText primaryTypographyProps={{fontWeight: 550}}
                                                          primary={(!instance.deploymentEndDate && instance.deploymentStartDate) ? "Deployment starting date" : "Last deployment starting date"}
                                                          secondary={moment(instance.deploymentStartDate).format("L LT")}/>
                                        </ListItem>
                                        < ListItem sx={{padding: 0}}>
                                            {
                                                (!instance.deploymentEndDate && instance.deploymentStartDate) &&
                                                <ListItemText primaryTypographyProps={{fontWeight: 550}}
                                                              primary="Deployment duration"
                                                              secondary={moment.utc(moment.duration(moment(new Date()).diff((moment(instance.deploymentStartDate)))).asMilliseconds()).format("mm[min] ss[s]")}/>
                                            }
                                            {
                                                (instance.deploymentEndDate && instance.deploymentStartDate) &&
                                                <ListItemText primaryTypographyProps={{fontWeight: 550}}
                                                              primary="Last Deployment duration"
                                                              secondary={moment.utc(moment.duration(moment(instance.deploymentEndDate).diff((moment(instance.deploymentStartDate)))).asMilliseconds()).format("mm[min] ss[s]")}/>
                                            }
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <List>
                                        <ListItem component={Link}
                                                  to={"/sessions/" + instance.practicalExercise?.trainingModule?.trainingSession?.id + "/exercises/" + instance.practicalExercise?.id}
                                                  style={{color: 'inherit', textDecoration: 'inherit'}}
                                                  sx={{padding: 0}}>
                                            <Tooltip title="Exercise details" placement="bottom-start">
                                                <ListItemText primaryTypographyProps={{fontWeight: 550}} sx={{
                                                    cursor: "pointer",
                                                    '& .MuiListItemText-secondary:hover, & .MuiListItemText-secondary:focus': {
                                                        textDecoration: "underline"
                                                    },
                                                }}
                                                              secondaryTypographyProps={{color: theme.palette.primary.main}}
                                                              primary="Exercise"
                                                              secondary={instance.practicalExercise?.name}/>
                                            </Tooltip>
                                        </ListItem>
                                        {instance.practicalExercise?.trainingModule?.trainingSession !== undefined && (
                                            <ListItem component={Link}
                                                      to={"/sessions/" + instance.practicalExercise?.trainingModule?.trainingSession?.id}
                                                      style={{color: 'inherit', textDecoration: 'inherit'}}
                                                      sx={{padding: 0}}>
                                                <Tooltip title="Session details" placement="bottom-start">
                                                    <ListItemText primaryTypographyProps={{fontWeight: 550}}
                                                                  primary="Session"
                                                                  sx={{
                                                                      cursor: "pointer",
                                                                      '& .MuiListItemText-secondary:hover, & .MuiListItemText-secondary:focus': {
                                                                          textDecoration: "underline"
                                                                      },
                                                                  }}
                                                                  secondaryTypographyProps={{color: theme.palette.primary.main}}
                                                                  secondary={instance.practicalExercise?.trainingModule?.trainingSession?.title}/>
                                                </Tooltip>
                                            </ListItem>
                                        )
                                        }
                                    </List>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <CardHeader title={
                            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                Parameters
                            </Typography>
                        }/>
                        <CardContent sx={{overflow: "auto", paddingY: 0}}>
                            {instance.id && <InstanceParametersEditor instance={instance}></InstanceParametersEditor>}
                        </CardContent>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <CardHeader title={
                            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                Outputs
                            </Typography>
                        }/>
                        <CardContent sx={{height: 180, overflow: "auto", paddingY: 0}}>
                            <List>
                                {
                                    instance.outputs ? Object.keys(instance.outputs).map(key =>
                                        <InstanceOutputForm key={key} label={key}
                                                            value={instance?.outputs ? [key] ? instance.outputs[key] : "" : ""}/>
                                    ) : <></>
                                }
                            </List>
                        </CardContent>
                    </Item>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Item>
                        <CardHeader
                            title={
                                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                    Logs
                                </Typography>
                            }
                            action={
                                <>
                                    <IconButton aria-label="refresh" color="primary"
                                                onClick={refreshLogs()}>
                                        <Tooltip title="Refresh" placement="top">
                                            <Refresh/>
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton aria-label="download" color="primary"
                                                onClick={handleClickDownloadLogs()}>
                                        <Tooltip title="Download" placement="top">
                                            <Download/>
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton aria-label="open" color="primary"
                                                onClick={handleClickOpenLogsDialog('paper')}>
                                        <Tooltip title="Expand" placement="top">
                                            <OpenInNew/>
                                        </Tooltip>
                                    </IconButton>
                                </>
                            }/>
                        <CardContent
                            sx={{
                                height: 300,
                                overflow: "auto",
                                paddingY: 0,
                                flexDirection: "column-reverse",
                                display: "flex",
                                fontSize: "small"
                            }}>
                            <ul style={{listStyle: 'none'}}>
                                {beautifyLogs(logs)}
                            </ul>
                        </CardContent>
                    </Item>
                </Grid>
            </Grid>
            <Dialog
                open={openLogsDialog}
                onClose={handleClickCloseLogsDialog}
                scroll={logsDialogScroll}
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={"xl"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Logs</DialogTitle>
                <DialogContent dividers={logsDialogScroll === 'paper'} sx={{
                    flexDirection: "column-reverse",
                    display: "flex"
                }}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        //ref={descriptionElementRef}
                        tabIndex={-1}
                        fontSize={"small"}
                    >
                        <ul style={{listStyle: 'none'}}>
                            {beautifyLogs(logs)}
                        </ul>
                        <div ref={bottomElement}></div>
                    </DialogContentText>
                    <Fab size="small" color="primary" aria-label="add" sx={{position: "absolute", right: "5%",}}
                         onClick={scrollToBottom}>
                        <Tooltip title="Scroll to bottom" placement="top">
                            <KeyboardDoubleArrowDown/>
                        </Tooltip>
                    </Fab>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickCloseLogsDialog}>Fermer</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default InstanceDetails;
