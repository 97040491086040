import React, {KeyboardEvent, useState} from 'react';
import Question from "../../types/Question";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import {Add, Delete, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import PracticalExercise from "../../types/PracticalExercise";
import {useUpdatePracticalExerciseMutation} from "../../services/CatalogService";
import LoadingButton from "@mui/lab/LoadingButton";
import reorderList from "./reorderList";
import SnackBarUtils from "../SnackBarUtils";

type Props = {
    exercise: PracticalExercise,
};

export const PracticalExerciseCatalogQuestionsEditor: React.FunctionComponent<Props> = (
    {exercise: initialExercise}: Props
) => {

    const [exercise, setExercise] = React.useState<PracticalExercise>(initialExercise);
    const [updateExercise, {isLoading: isUpdatingExercise}] = useUpdatePracticalExerciseMutation();
    const [newQuestion, setNewQuestion] = useState<Question>({
        answer: '',
        id: undefined,
        answerFormat: '',
        question: ''
    })

    const reorderQuestion = (questions: Question[]) => {
        return questions?.map((question: Question, index: number) => ({
            ...question, sequence: index
        }))
    }

    const handleSave = () => {
        let newQuestions = reorderQuestion(exercise?.questions ? exercise.questions : [])
        updateExercise({...exercise, questions: newQuestions}).unwrap().then(() => {
            SnackBarUtils.info('Practical exercise successfully modified')
        });
    }
    const handleQuestionUpdate = (question: Question, index: number) => {
        if (exercise?.questions) {
            let nextQuestions = exercise?.questions.map(x => Object.assign({}, x));
            nextQuestions.map((q: Question, curr: number) => {
                if (curr === index) {
                    q.answerFormat = question.answerFormat;
                    q.answer = question.answer;
                    q.question = question.question
                    return q;
                } else {
                    return q;
                }
            })
            setExercise((oldState: PracticalExercise) => ({
                ...oldState, questions: nextQuestions
            }));
        }
    }

    const handleNewQuestion = () => {
        setExercise((oldState: PracticalExercise) => ({
            ...oldState,
            questions: oldState.questions ? oldState.questions.concat(newQuestion) : new Array(1).fill(newQuestion)
        }));
        setNewQuestion({answer: '', id: undefined, answerFormat: '', question: ''})
    }

    const handleRemoveQuestion = (questionId?: string, curr?: number) => {
        setExercise((oldState: PracticalExercise) => ({
            ...oldState, questions: oldState.questions?.filter((question, index) => index !== curr)
        }));
        setNewQuestion({answer: '', id: undefined, answerFormat: '', question: ''})
    }

    const handleUp = (index: number) => {
        setExercise((oldState: PracticalExercise) => ({
            ...oldState, questions: reorderList(oldState?.questions ? oldState.questions : [], index, index - 1)
        }));
    }
    const handleDown = (index: number) => {
        setExercise((oldState: PracticalExercise) => ({
            ...oldState, questions: reorderList(oldState?.questions ? oldState.questions : [], index + 1, index)
        }));
    }

    const handleKeyDownAdd = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleNewQuestion()
        }
    };

    return (
        <Grid>
            {exercise?.questions?.map((question, index) => (
                <Grid key={index + "-question"} container spacing={2} paddingTop={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={1} md={1} lg={1} textAlign={'center'}>

                                <IconButton disabled={index === 0} size={"small"} color={"primary"} aria-label="Add"
                                            onClick={() => handleUp(index)}>
                                    <KeyboardArrowUp></KeyboardArrowUp>
                                </IconButton>
                                <IconButton disabled={index + 1 === exercise?.questions?.length} size={"small"}
                                            color={"primary"} aria-label="Add" onClick={() => handleDown(index)}>
                                    <KeyboardArrowDown></KeyboardArrowDown>
                                </IconButton>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    variant="standard"
                                    label={"Question " + (index + 1)}
                                    fullWidth
                                    value={question.question}
                                    onChange={(e) => {
                                        handleQuestionUpdate({...question, question: e.target.value}, index)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    label="Answer"
                                    value={question.answer}
                                    onChange={(e) => {
                                        handleQuestionUpdate({...question, answer: e.target.value}, index)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    label="Answer format"
                                    value={question.answerFormat}
                                    onChange={(e) => {
                                        handleQuestionUpdate({...question, answerFormat: e.target.value}, index)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                                <IconButton color={"primary"} aria-label="Add"
                                            onClick={() => handleRemoveQuestion(question.id, index)}>
                                    <Delete></Delete>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={2} paddingTop={2} key={"new-question"}>
                    <Grid item xs={4}>
                        <TextField
                            onKeyDown={handleKeyDownAdd}
                            variant="standard"
                            label="Question"
                            autoFocus
                            fullWidth
                            value={newQuestion.question}
                            onChange={(e) => {
                                setNewQuestion({...newQuestion, question: e.target.value})
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="standard"
                            fullWidth
                            label="Answer"
                            value={newQuestion.answer}
                            onKeyDown={handleKeyDownAdd}
                            onChange={(e) => {
                                setNewQuestion({...newQuestion, answer: e.target.value})
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            variant="standard"
                            fullWidth
                            label="Answer format"
                            onKeyDown={handleKeyDownAdd}
                            value={newQuestion.answerFormat}
                            onChange={(e) => {
                                setNewQuestion({...newQuestion, answerFormat: e.target.value})
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} md={1} lg={1}>
                        <IconButton color={"primary"} aria-label="Add" onClick={handleNewQuestion}>
                            <Add></Add>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <LoadingButton sx={{marginTop: 2}}
                           size="small"
                           onClick={handleSave}
                           loading={isUpdatingExercise}
                           disableElevation
                           variant="contained"
            >
                <span>SAVE</span>
            </LoadingButton>
        </Grid>
    );
};