import React, {useEffect} from 'react';
import Typography from "@mui/material/Typography";
import {
    DataGrid,
    GridColDef,
    GridFilterModel,
    GridPaginationModel,
    GridSortDirection,
    GridSortModel
} from "@mui/x-data-grid";
import moment from "moment";
import {Link as MuiLink, Tooltip, Theme} from "@mui/material";
import {useLazyGetInstancesWithFiltersQuery} from "../../services/InstanceService";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import {PreviewOutlined,} from "@mui/icons-material";
import {useTheme} from '@mui/material/styles';
import InstanceStatusChip from "./InstanceStatusChip";
import StartStopInstance from "./StartStopInstance";
import ExerciseProgress from "../trainee/ExerciseProgress";
import {enumFilterOperators, stringFilterOperators} from "../utils/Utils";
import Grid from "@mui/material/Grid";
import NewInstance from "./NewInstance";

type Props = {
    sessionId?: string,
    exerciseId?: string,
};

const InstanceList: React.FunctionComponent<Props> = ({sessionId = undefined, exerciseId = undefined}: Props) => {

    const navigate = useNavigate();

    const [triggerGetInstances,
        {
            data: instancesPage = {content: [], totalElements: 0},
            isFetching,
        }] = useLazyGetInstancesWithFiltersQuery({pollingInterval: 5000});

    const [searchParams, setSearchParams] = useSearchParams();
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
    });
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: "createdDate", sort: "desc"
    }]);
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 10,
        page: 0,
    });
    const [pageSize, setPageSize] = React.useState(10);
    const [pageNo, setPageNo] = React.useState(0);
    const [sortOptions, setSortOptions] = React.useState(["createdDate,desc"]);
    const [filterOptions, setFilterOptions] = React.useState([""]);

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        setSortModel(sortModel)
        let firstSort = sortModel.find(first => first);
        if (firstSort) {
            setSearchParams(searchParams => {
                searchParams.set("sort", firstSort?.field + "," + firstSort?.sort)
                return searchParams;
            });
        }

        // Here you save the data you need from the sort model
        setSortOptions(sortModel.map((sortItem) => {
            return sortItem.field + "," + sortItem.sort
        }));
    }, []);

    const handleFilterModelChange = React.useCallback((filterModel: GridFilterModel) => {
        setFilterModel(filterModel)
        setFilterOptions(filterModel.items.map((filterItem) => {
            return filterItem.value ? filterItem.field + "=" + filterItem.value : ""
        }));
        filterModel.items.forEach((item) => {
            item.value && setSearchParams(searchParams => {
                searchParams.set(item.field, item.value)
                return searchParams;
            });
        })
    }, []);

    const handlePaginationChange = React.useCallback((newPaginationModel: GridPaginationModel) => {
        setPaginationModel(newPaginationModel);
        setPageSize(newPaginationModel.pageSize);
        setPageNo(newPaginationModel.page)
        // Here you save the data you need from the sort model

        setSearchParams(searchParams => {
            searchParams.set("page", newPaginationModel.page.toString())
            searchParams.set("pageSize", newPaginationModel.pageSize.toString())
            return searchParams;
        }, {replace: true});
    }, []);


    useEffect(() => {
        triggerGetInstances({
            pageNo: pageNo,
            sessionId: sessionId,
            exerciseId: exerciseId,
            pageSize: pageSize,
            filter: filterOptions,
            sort: sortOptions
        });
    }, [sessionId, exerciseId, triggerGetInstances, pageNo, pageSize, sortOptions, filterOptions]);


    useEffect(() => {
        let status = searchParams.get("status");
        if (status) {
            handleFilterModelChange({items: [{id: 1, field: 'status', operator: 'equals', value: status}]});
        }
        let trainee = searchParams.get("trainee");
        if (trainee) {
            handleFilterModelChange({items: [{id: 1, field: 'trainee', operator: 'contains', value: trainee}]});
        }
        let sort = searchParams.get("sort");
        if (sort) {
            handleSortModelChange([{field: sort.split(",")[0], sort: sort.split(",")[1] as GridSortDirection}]);
        }
        let pageParam = searchParams.get("page");
        let pageSizeParam = searchParams.get("pageSize");
        handlePaginationChange({
            pageSize: pageSizeParam ? parseInt(pageSizeParam) : 10,
            page: pageParam ? parseInt(pageParam) : 0
        });
    }, [searchParams]);

    const theme: Theme = useTheme();

    const [rowCountState, setRowCountState] = React.useState(instancesPage.totalElements);
    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            instancesPage.totalElements !== undefined ? instancesPage.totalElements : prevRowCountState,
        );
    }, [instancesPage.totalElements, setRowCountState]);

    const columns: GridColDef[] = [
            {
                field: 'survey',
                align: 'center',
                headerName: 'Progress',
                filterable: false,
                sortable: false,
                flex: 1,
                headerAlign: 'center',
                renderCell: (params) => (
                    <ExerciseProgress survey={params.value}></ExerciseProgress>
                )
            },
            {
                field: 'createdDate',
                align: 'center',
                headerName: 'Creation date',
                flex: 1,
                filterable: false,
                headerAlign: 'center',
                renderCell: (params) => (
                    <div
                        onClick={() => navigate("/instances/" + params.row.id)}> {moment(params.row.createdDate).format("L LT")}</div>
                )
            },
            {
                field: 'lastDeploymentDate',
                align: 'center',
                headerName: 'Last deployment date',
                flex: 1,
                filterable: false,
                headerAlign: 'center',
                renderCell: (params) => (
                    <div onClick={() => navigate("/instances/" + params.row.id)}>
                        {params.row.deploymentStartDate ?
                            moment(params.row.deploymentStartDate).format("L LT") :
                            "Not deployed"}
                    </div>
                )
            },
            {
                field: 'trainee',
                align: 'center',
                headerName: 'Trainee',
                flex: 1,
                headerAlign: 'center',
                filterOperators: stringFilterOperators,
                renderCell: (params) => (
                    <div onClick={() => navigate("/instances/" + params.row.id)}> {params.row.trainee}</div>
                )
            },
            {
                field: 'status',
                align: 'center',
                headerName: 'Status',
                flex: 1,
                headerAlign: 'center',
                filterOperators: enumFilterOperators,
                renderCell: (params) => (
                    <div onClick={() => navigate("/instances/" + params.row.id)}>
                        <InstanceStatusChip status={params.row.status}></InstanceStatusChip>
                    </div>

                )
            },
            {
                field: 'lastDeploymentDuration',
                align: 'center',
                headerName: 'Last deployment duration',
                flex: 1,
                filterable: false,
                headerAlign: 'center',
                renderCell: (params) => {
                    let duration = "Not deployed"
                    if (params.row.deploymentStartDate && params.row.deploymentEndDate) {
                        duration = moment.utc(moment.duration(moment(params.row.deploymentEndDate).diff((moment(params.row.deploymentStartDate)))).asMilliseconds()).format("mm[min] ss[s]");
                    } else if (params.row.deploymentStartDate && !params.row.deploymentEndDate) {
                        duration = moment.utc(moment.duration(moment(new Date()).diff((moment(params.row.deploymentStartDate)))).asMilliseconds()).format("mm[min] ss[s]");
                    }
                    return <div
                        onClick={() => navigate("/instances/" + params.row.id)}> {duration}</div>
                }
            },
            {
                field: 'exercise',
                align: 'center',
                headerName: 'Exercise',
                flex: 1,
                filterable: false,
                sortable: false,
                headerAlign: 'center',
                renderCell: (params) => (
                    <MuiLink component={Link}
                              to={"/sessions/" + params.row.practicalExercise?.trainingModule?.trainingSession?.id + "/exercises/" + params.row.practicalExercise?.id}
                              style={{color: 'inherit', textDecoration: 'inherit'}}
                              sx={{padding: 0, textAlign:"center"}}>
                        <Tooltip title="Exercise details" placement="bottom-start">
                            <Typography variant={"body2"}
                                        fontWeight={500}
                                        color={theme.palette.primary.main}
                                        sx={{
                                            cursor: "pointer", '&:hover, &:focus': {
                                            textDecoration: "underline",
                                        }}}>
                                {params.row.practicalExercise?.name}
                            </Typography>
                        </Tooltip>
                    </MuiLink>
                )
            },
            {
                field: 'session',
                align: 'center',
                headerName: 'Session',
                flex: 1,
                filterable: false,
                sortable: false,
                headerAlign: 'center',
                renderCell: (params) => {
                    const session = params.row.practicalExercise?.trainingModule?.trainingSession;
                    return session ? (
                        <MuiLink component={Link}
                                  to={"/sessions/" + session.id}
                                  style={{color: 'inherit', textDecoration: 'inherit'}}
                                  sx={{padding: 0, textAlign: "center"}}>
                            <Tooltip title="Session details" placement="bottom-start">
                                <Typography variant={"body2"}
                                            fontWeight={500}
                                            color={theme.palette.primary.main}
                                            sx={{
                                                cursor: "pointer", '&:hover, &:focus': {
                                                    textDecoration: "underline",
                                                }}}>
                                    {session.title}
                                </Typography>
                            </Tooltip>
                        </MuiLink>
                    ) : (
                        <Typography variant={"body2"}>Standalone</Typography>
                    )
                }
            },
            {
                field: 'action',
                headerName: 'Action',
                flex: 1,
                align: 'center',
                headerAlign: 'center',
                filterable: false,
                sortable: false,
                width: 250,
                renderCell: (params) => (
                    <>
                        <StartStopInstance buttonType={"icon"} instance={params.row}></StartStopInstance>
                        <Tooltip title="Trainee's view" placement="bottom">
                            <IconButton aria-label="preview" component={Link} to={"/view/" + params.row.id}>
                                <PreviewOutlined color={"primary"}/>
                            </IconButton>
                        </Tooltip>
                    </>
                )
            }
        ]
    ;

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={6}>
                    <Typography component="h2" variant="h6" color="primary" gutterBottom mb={3}>
                        Instances
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign: 'right'}}>
                    <NewInstance/>
                </Grid>
            </Grid>
            <DataGrid
                sx={{
                    boxShadow: 0,
                    border: 0,
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none",
                    },
                    cursor: "pointer"
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            session: false,
                            createdDate: false,
                            lastDeploymentDuration: false,
                        },
                    },
                }}
                disableRowSelectionOnClick
                autoHeight
                rows={instancesPage.content}
                columns={columns}
                pageSizeOptions={[10, 20, 50]}
                paginationMode={"server"}
                filterMode="server"
                onFilterModelChange={handleFilterModelChange}
                filterModel={filterModel}
                sortModel={sortModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                rowCount={rowCountState}
                getRowId={(row: any) => row.id.toString()}
                loading={isFetching}
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationChange}
            />
        </React.Fragment>
    );
};

export default InstanceList;
