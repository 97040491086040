import React, {KeyboardEvent, useMemo, useState} from 'react';
import {CircularProgress, FormControl, Input, InputAdornment, InputLabel} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {AccountCircle, Add} from "@mui/icons-material";
import SnackbarUtils from '../SnackBarUtils'
import SnackBarUtils from '../SnackBarUtils'
import {useUpdateTrainingSessionTraineesMutation} from "../../services/TrainingSessionService";

type Props = {
    sessionId: string,
    trainees: string[],
};

const AddTrainee: React.FunctionComponent<Props> = (
    {sessionId, trainees: initialTrainees},
) => {
    const [trainees, setTrainees] = React.useState(initialTrainees);

    useMemo(() => {
        setTrainees(initialTrainees)
    }, [initialTrainees])
    const [newTrainee, setNewTrainee] = useState('');
    const [updateSessionTrainees, {isLoading: isUpdatingSessionTrainees}] = useUpdateTrainingSessionTraineesMutation();
    const handleClickAdd = () => {
        if (!trainees.includes(newTrainee.trim())) {
            setTrainees([...trainees, newTrainee.trim()])
            updateSessionTrainees({
                sessionId: sessionId,
                trainees: [...trainees, newTrainee.trim()]
            }).unwrap().then(() => {
                SnackbarUtils.info('Instances created for new trainee');
            }).catch(() => {
                SnackBarUtils.error('Error happened while creating trainee.');
            });
            setNewTrainee('');
        } else {
            SnackBarUtils.error('Trainee already exists');
            setNewTrainee('');
        }
    };

    const handleKeyDownAdd = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleClickAdd();
        }
    };

    return (
        <FormControl sx={{m: 1, width: '100%', padding: "0.5em"}} variant="standard">
            <InputLabel htmlFor="standard-adornment-add">Add a new trainee</InputLabel>
            <Input
                id="standard-adornment-add"
                type="text"
                onKeyDown={handleKeyDownAdd}
                value={newTrainee}
                onChange={(e) => setNewTrainee(e.target.value.trim())}
                startAdornment={
                    <InputAdornment position="start">
                        <AccountCircle></AccountCircle>
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        {
                            isUpdatingSessionTrainees ?
                                <CircularProgress size={30} color="primary"></CircularProgress>
                                :
                                <IconButton aria-label="Add" onClick={handleClickAdd}>
                                    <Add></Add>
                                </IconButton>
                        }
                    </InputAdornment>
                }
            />
        </FormControl>
    );
};

export default AddTrainee;
