import React from 'react';

import Grid from "@mui/material/Grid";
import TraineeSessionsOverview from "../components/trainee/TraineeSessionsOverview";

type Props = {
    pageTitle: (arg: string) => void
};

const TraineeOverview: React.FunctionComponent<Props> = ({pageTitle}) => {

    React.useEffect(() => {
        pageTitle("My sessions");
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <TraineeSessionsOverview/>
            </Grid>
        </Grid>
    );
};

export default TraineeOverview;
