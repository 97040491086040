import * as React from 'react';
import {Button} from "@mui/material";
import {Link} from "react-router-dom";

const HeaderUnloggedMenu: React.FunctionComponent = () => {
    return (
        <Button color="inherit" component={Link} to="/home">Login</Button>
    );
}

export default HeaderUnloggedMenu;