import React from 'react';
import Typography from "@mui/material/Typography";
import {GridExpandMoreIcon} from "@mui/x-data-grid";
import {Accordion, AccordionDetails, AccordionSummary, CircularProgress, ListItem, ListItemAvatar} from "@mui/material";
import {useGetTraineeStandaloneInstancesQuery} from "../../services/InstanceService";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import ExerciseProgress from "./ExerciseProgress";
import InstanceStatusChip from "../instance/InstanceStatusChip";
import {useTheme} from "@mui/material/styles";

const TraineeStandaloneInstancesOverview: React.FunctionComponent = () => {

    const theme = useTheme();
    const {
        data: traineeStandaloneInstances = {content: []},
        isLoading
    } = useGetTraineeStandaloneInstancesQuery({
        pageNo: 0,
        pageSize: 1000
    }, {pollingInterval: 5000});

    return (
        <>
            <Accordion defaultExpanded key={"session-standalone"}
                       sx={{
                           backgroundColor: theme.palette.background.paper,
                           border: "2px solid " + theme.palette.primary.main,
                       }}>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon/>}
                    aria-controls="panel1bh-content"
                    id={"panel-header"}
                >
                    <Grid container alignItems="center">
                        <Grid item xs={8}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography color={"primary"} sx={{fontWeight: '500', paddingLeft: 1}}
                                                component="div">
                                        Standalone instances
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        traineeStandaloneInstances.content?.map((instance, index) => {
                            return (
                                <ListItem key={"instance" + index}
                                          secondaryAction={<>
                                              <Button component={Link}
                                                      to={"/view/" + instance.id}>View</Button>
                                          </>}
                                >
                                    <ListItemAvatar>
                                        <ExerciseProgress survey={instance.survey}></ExerciseProgress>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Grid container>
                                                <Grid item xs={12} alignItems="center">
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexWrap: 'wrap',
                                                    }}>
                                                        <Typography sx={{paddingRight: 1}}
                                                                    component="label">
                                                            {instance?.practicalExercise?.name}
                                                        </Typography>
                                                        <InstanceStatusChip
                                                            status={instance?.status}></InstanceStatusChip>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        }
                                        secondary={instance?.practicalExercise?.description}
                                    />

                                </ListItem>
                            )
                        })
                    }
                </AccordionDetails>
            </Accordion>
            {
                isLoading &&
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12} sx={{textAlign: "center"}}>
                        <CircularProgress/>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default TraineeStandaloneInstancesOverview;
