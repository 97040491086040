import TrainingModule from "../types/TrainingModule";
import PracticalExercise from "../types/PracticalExercise";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {API_URL} from "../api/ConfigApi";
import LearningPath from "../types/LearningPath";
import {prepareHeaders} from "./ServiceUtils";
import {RootState} from "../slices";
import Page from "../types/Page";

export const catalogApi = createApi({
    reducerPath: 'catalogApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL + '/catalog',
        prepareHeaders: (headers, {getState}) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            return prepareHeaders(headers, getState() as RootState)
        },
    }),
    tagTypes: ['PracticalExercises', 'TrainingModules', 'LearningPaths'],
    endpoints: (builder) => ({
        getPracticalExercises: builder.query<Page<PracticalExercise>, { pageNo: number, pageSize: number, sort?: string[], filter?: string[] }>({
            query: (arg) => {
                const {pageNo, pageSize, sort, filter} = arg;
                return {
                    url: "/practicalExercises",
                    params: {pageNo, pageSize, sort, filter},
                };
            },
            providesTags: (result, error, page) =>
                result
                    ? [
                        // Provides a tag for each post in the current page,
                        // as well as the 'PARTIAL-LIST' tag.
                        ...result.content.map(({id}) => ({type: 'PracticalExercises' as const, id})),
                        {type: 'PracticalExercises', id: 'PARTIAL-LIST'},
                    ]
                    : [{type: 'PracticalExercises', id: 'PARTIAL-LIST'}],
            transformResponse: (rawResult: Page<PracticalExercise>, meta) => {
                return rawResult;
            },
        }),
        getPracticalExercise: builder.query<PracticalExercise, string>({
            query: (id) => `/practicalExercises/${id}`,
            providesTags: (_instance, _err, id) => [{type: 'PracticalExercises', id}],
        }),
        updatePracticalExercise: builder.mutation<PracticalExercise, Pick<PracticalExercise, 'id'> & Partial<PracticalExercise>>({
            query: ({id, ...patch}) => ({
                url: `/practicalExercises/${id}`,
                method: 'PUT',
                body: patch,
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'PracticalExercises', id}, {
                type: 'PracticalExercises',
                id: 'PARTIAL-LIST'
            },],
            async onQueryStarted({id, ...patch}, {dispatch, queryFulfilled}) {
                const patchResult = dispatch(
                    catalogApi.util.updateQueryData('getPracticalExercise', id ? id : "", (draft) => {
                        Object.assign(draft, patch)
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()

                    /**
                     * Alternatively, on failure you can invalidate the corresponding cache tags
                     * to trigger a re-fetch:
                     * dispatch(api.util.invalidateTags(['Post']))
                     */
                }
            },
        }),
        createPracticalExercise: builder.mutation<PracticalExercise, PracticalExercise>({
            query: (practicalExercise) => ({
                url: `/practicalExercises`,
                method: 'POST',
                body: practicalExercise,
            }),
            invalidatesTags: [{type: 'PracticalExercises', id: 'PARTIAL-LIST'}],
        }),
        deletePracticalExercise: builder.mutation<string, string>({
            query: (id) => ({
                url: `/practicalExercises/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{type: 'PracticalExercises', id: 'PARTIAL-LIST'}],
        }),
        getTrainingModules: builder.query<Page<TrainingModule>, { pageNo: number, pageSize: number, sort?: string[], filter?: string[] }>({
            query: (arg) => {
                const {pageNo, pageSize, sort, filter} = arg;
                return {
                    url: "/trainingModules",
                    params: {pageNo, pageSize, sort, filter},
                };
            },
            providesTags: (result, error, page) =>
                result
                    ? [
                        // Provides a tag for each post in the current page,
                        // as well as the 'PARTIAL-LIST' tag.
                        ...result.content.map(({id}) => ({type: 'TrainingModules' as const, id})),
                        {type: 'TrainingModules', id: 'PARTIAL-LIST'},
                    ]
                    : [{type: 'TrainingModules', id: 'PARTIAL-LIST'}],
            transformResponse: (rawResult: Page<TrainingModule>, meta) => {
                return rawResult;
            },
        }),
        updateTrainingModule: builder.mutation<TrainingModule, TrainingModule>({
            query: (training) => ({
                url: `/trainingModules/${training.id}`,
                method: 'PUT',
                body: training,
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'TrainingModules', id}, {
                type: 'TrainingModules',
                id: 'PARTIAL-LIST'
            },]
        }),
        deleteTrainingModule: builder.mutation<string, string>({
            query: (id) => ({
                url: `/trainingModules/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{type: 'TrainingModules', id: 'PARTIAL-LIST'}],
        }),
        createTrainingModule: builder.mutation<TrainingModule, TrainingModule>({
            query: (training) => ({
                url: `/trainingModules`,
                method: 'POST',
                body: training,
            }),
            invalidatesTags: [{type: 'TrainingModules', id: 'PARTIAL-LIST'}],
        }),

        getLearningPaths: builder.query<Page<LearningPath>, { pageNo: number, pageSize: number, sort?: string[], filter?: string[] }>({
            query: (arg) => {
                const {pageNo, pageSize, sort, filter} = arg;
                return {
                    url: "/learningPaths",
                    params: {pageNo, pageSize, sort, filter},
                };
            },
            providesTags: (result, error, page) =>
                result
                    ? [
                        // Provides a tag for each post in the current page,
                        // as well as the 'PARTIAL-LIST' tag.
                        ...result.content.map(({id}) => ({type: 'LearningPaths' as const, id})),
                        {type: 'LearningPaths', id: 'PARTIAL-LIST'},
                    ]
                    : [{type: 'LearningPaths', id: 'PARTIAL-LIST'}],
        }),
        getLearningPath: builder.query<LearningPath, string>({
            query: (id) => `/learningPaths/${id}`,
            providesTags: (_instance, _err, id) => [{type: 'LearningPaths', id}],
        }),
        updateLearningPath: builder.mutation<LearningPath, LearningPath>({
            query: (learningPath) => ({
                url: `/learningPaths/${learningPath.id}`,
                method: 'PUT',
                body: learningPath,
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'LearningPaths', id}, {
                type: 'LearningPaths',
                id: 'PARTIAL-LIST'
            },],
            async onQueryStarted({id, ...patch}, {dispatch, queryFulfilled}) {
                const patchResult = dispatch(
                    catalogApi.util.updateQueryData('getLearningPath', id ? id : "", (draft) => {
                        Object.assign(draft, patch)
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()

                    /**
                     * Alternatively, on failure you can invalidate the corresponding cache tags
                     * to trigger a re-fetch:
                     * dispatch(api.util.invalidateTags(['Post']))
                     */
                }
            },
        }),
        deleteLearningPath: builder.mutation<string, string>({
            query: (id) => ({
                url: `/learningPaths/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{type: 'LearningPaths', id: 'PARTIAL-LIST'}],
        }),
        createLearningPath: builder.mutation<LearningPath, LearningPath>({
            query: (learningPath) => ({
                url: `/learningPaths`,
                method: 'POST',
                body: learningPath,
            }),
            invalidatesTags: [{type: 'LearningPaths', id: 'PARTIAL-LIST'}],
        }),
    }),
})

export const {
    useLazyGetPracticalExercisesQuery,
    useGetPracticalExercisesQuery,
    useUpdatePracticalExerciseMutation,
    useCreatePracticalExerciseMutation,
    useDeletePracticalExerciseMutation,
    useLazyGetTrainingModulesQuery,
    useGetTrainingModulesQuery,
    useUpdateTrainingModuleMutation,
    useDeleteTrainingModuleMutation,
    useCreateTrainingModuleMutation,
    useGetLearningPathsQuery,
    useUpdateLearningPathMutation,
    useDeleteLearningPathMutation,
    useCreateLearningPathMutation,
    useGetPracticalExerciseQuery,
    useGetLearningPathQuery,
    useLazyGetLearningPathsQuery
} = catalogApi