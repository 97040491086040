import React from 'react';
import {CircularProgress, Tooltip} from "@mui/material";
import Instance from "../../types/Instance";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import {
    useDestroyInstanceMutation,
    useLaunchInstanceMutation,
    usePlanInstanceMutation
} from "../../services/InstanceService";
import SnackBarUtils from "../SnackBarUtils";
import {RocketLaunch} from "@mui/icons-material";

type Props = {
    instance: Instance,
    buttonType?: ("icon" | "full"),
};

const StartStopInstance: React.FunctionComponent<Props> = ({instance, buttonType = "full"}) => {

        const [planInstance, {isLoading: isPlanInstanceLoading}] = usePlanInstanceMutation();
        const [launchInstance, {isLoading: isLaunchInstanceLoading}] = useLaunchInstanceMutation();
        const [destroyInstance, {isLoading: isDestroyInstanceLoading}] = useDestroyInstanceMutation();
        const handlePlanInstance = () => {
            planInstance(instance.id).unwrap().catch(() => {
                SnackBarUtils.error('Instance already busy')
            });
        };
        const handleStartInstance = () => {
            launchInstance({
                instanceId: instance.id,
                isAutomaticallyDestroyed: true,
            }).unwrap().catch(() => {
                SnackBarUtils.error('Instance already busy')
            });
        };

        const handleDestroyInstance = () => {
            destroyInstance(instance.id).unwrap().catch(() => {
                SnackBarUtils.error('Instance already busy')
            });
        };

        return (
            <>
                {buttonType === 'full' ?
                    <Box>
                        <LoadingButton
                            disabled={isLaunchInstanceLoading}
                            loading={isLaunchInstanceLoading}
                            onClick={handleStartInstance}
                            variant="contained"
                            disableElevation
                            size={"small"}
                            sx={{m: 0.5}}
                        >
                            Deploy
                        </LoadingButton>
                        <LoadingButton
                            disabled={isLaunchInstanceLoading}
                            loading={isPlanInstanceLoading}
                            onClick={handlePlanInstance}
                            variant="outlined"
                            disableElevation
                            size={"small"}
                            sx={{m: 0.5}}
                        >
                            Plan
                        </LoadingButton>
                        <LoadingButton
                            disabled={isDestroyInstanceLoading}
                            loading={isDestroyInstanceLoading}
                            variant="outlined"
                            onClick={handleDestroyInstance}
                            size={"small"}
                            sx={{m: 0.5}}
                        >
                            Destroy
                        </LoadingButton>
                    </Box>
                    :
                    <Box>
                        {
                            isLaunchInstanceLoading || isPlanInstanceLoading ?
                                <IconButton>
                                    <CircularProgress size={16} color="primary"/> </IconButton> :
                                <Tooltip title="Deploy" placement="bottom">
                                    <IconButton onClick={handleStartInstance}>
                                        <RocketLaunch color="primary"></RocketLaunch>
                                    </IconButton>
                                </Tooltip>
                        }
                        {
                            isDestroyInstanceLoading ?
                                <IconButton><CircularProgress size={16} color="primary"/></IconButton> :
                                <Tooltip title="Destroy" placement="bottom">
                                    <IconButton onClick={handleDestroyInstance}>
                                        <RocketLaunch sx={{transform: "rotate(90deg)"}} color="primary"></RocketLaunch>
                                    </IconButton>
                                </Tooltip>
                        }
                    </Box>
                }
            </>
        );
    }
;

export default StartStopInstance;
