import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import PracticalExercise from "../../types/PracticalExercise";
import {FormControlLabel, FormGroup, Switch} from "@mui/material";
import React, {useState} from "react";
import {useUpdatePracticalExerciseMutation} from "../../services/CatalogService";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackBarUtils from "../SnackBarUtils";

interface PracticalExerciseBadgeProps {
    exercise: PracticalExercise,
}

export const PracticalExerciseBadge = ({exercise: initialExercise}: PracticalExerciseBadgeProps) => {

    const [exercise, setExercise] = useState(initialExercise);
    const [titleError, setTitleError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);

    const [updateExercise, {isLoading: isUpdatingExercise}] = useUpdatePracticalExerciseMutation();

    function handleSave() {
        updateExercise(exercise).unwrap().then(() => {
            SnackBarUtils.info('Badge successfully modified')
        }).catch(() => {
            SnackBarUtils.error('Error happened while updating badge.')
        });
    }

    return (<Grid container rowSpacing={1} sx={{paddingTop: "20px"}}>
        <Grid item xs={12} md={12} lg={12}>
            <FormGroup>
                <FormControlLabel control={
                    <Switch checked={exercise?.badge?.enabled} onChange={(e) => {
                        setExercise((oldState: PracticalExercise) => ({
                            ...oldState, badge: {...oldState.badge, enabled: e.target.checked}
                        }));
                    }}/>
                } label="Award badge"/>
            </FormGroup>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
            <TextField
                fullWidth
                required
                disabled={!exercise.badge?.enabled}
                variant="standard"
                label="Badge title"
                defaultValue={exercise?.badge?.title}
                error={titleError}
                onChange={(e) => {
                    setTitleError(e.target.value as string === "")
                    setExercise((oldState: PracticalExercise) => ({
                        ...oldState, badge: {...oldState.badge, title: e.target.value as string}
                    }));
                }}
                helperText={titleError ? "Please enter a badge title." : ""}
            />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
            <TextField
                fullWidth
                required
                disabled={!exercise.badge?.enabled}
                variant="standard"
                label="Badge description"
                defaultValue={exercise?.badge?.description}
                error={descriptionError}
                onChange={(e) => {
                    setDescriptionError(e.target.value === "")
                    setExercise((oldState: PracticalExercise) => ({
                        ...oldState, badge: {...oldState.badge, description: e.target.value as string}
                    }));
                }}
                helperText={descriptionError ? "Please enter a badge description." : ""}
            />
        </Grid>
        <LoadingButton sx={{marginTop: 2}}
                       size="small" disableElevation
                       onClick={handleSave}
                       loading={isUpdatingExercise}
                       variant="contained"
        >
            <span>SAVE</span>
        </LoadingButton>
    </Grid>)
}