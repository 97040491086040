import React, {useEffect, useState} from "react";
import TrainingModule from "../../../types/TrainingModule";
import {Autocomplete, List, ListItem} from "@mui/material";
import PracticalExercise from "../../../types/PracticalExercise";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import {
    useCreateTrainingModuleMutation,
    useGetPracticalExercisesQuery,
    useUpdateTrainingModuleMutation
} from "../../../services/CatalogService";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackBarUtils from "../../SnackBarUtils";
import TrainingModuleCatalog from "../../../types/TrainingModuleCatalog";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import reorderList from "../reorderList";
import OrderedPracticalExercise from "../../../types/OrderedPracticalExercise";

type Props = {
    mode: "edit" | "create"
    trainingModule: TrainingModule,
    isOpen: boolean,
    handleClose: () => void
    handleSave: (module: TrainingModule) => void
};

const TrainingModuleDetailsDialog: React.FunctionComponent<Props> = (props: Props) => {

    const [trainingModule, setTrainingModule] = useState<TrainingModuleCatalog>({})
    const [nameError, setNameError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const {
        data: exercisesPage = {content: [], totalElements: 0},
    } = useGetPracticalExercisesQuery({pageNo: 0, pageSize: 1000});
    const [createTraining, {isLoading: isCreateTrainingLoading}] = useCreateTrainingModuleMutation();
    const [updateTraining, {isLoading: isUpdateTrainingLoading}] = useUpdateTrainingModuleMutation();
    const [autocompleteValue, setAutocompleteValue] = useState<TrainingModule>({name: ""});

    useEffect(() => {
        setTrainingModule(props.trainingModule);
    }, [props.trainingModule])

    const handleSaveTraining = () => {
        if (props.mode === "edit") {
            updateTraining(trainingModule).unwrap().then(() => {
                SnackBarUtils.info('Training module exercise successfully modified')
                if (trainingModule) props.handleSave(trainingModule);
            }).catch(() => {
                SnackBarUtils.error('Error happened while updating training module.')
            });
        } else {
            createTraining(trainingModule).unwrap().then(() => {
                SnackBarUtils.info('Training module exercise successfully created')
                if (trainingModule) props.handleSave(trainingModule);
            }).catch(() => {
                SnackBarUtils.error('Error happened while creating training module.')
            });
        }
    }

    const handleClose = () => {
        //reinit
        setTrainingModule(props.trainingModule);
        props.handleClose();
    }
    const handleNewExerciseSelection = (exerciseToAdd: PracticalExercise) => {
        setTrainingModule((oldState: TrainingModuleCatalog) => ({
            ...oldState,
            orderedPracticalExercises: oldState.orderedPracticalExercises ? oldState.orderedPracticalExercises.concat({
                practicalExercise: exerciseToAdd,
                exerciseOrder: oldState.orderedPracticalExercises.length + 1
            }) : new Array(1).fill({practicalExercise: exerciseToAdd, exerciseOrder: "1"})
        }));
    }
    const removeExercise = (exerciseToRemove: PracticalExercise | undefined) => {
        setTrainingModule((oldState: TrainingModuleCatalog) => ({
            ...oldState,
            orderedPracticalExercises: oldState.orderedPracticalExercises?.filter((exercise) => exercise?.practicalExercise?.id !== exerciseToRemove?.id)
        }));
    }

    const handleUp = (index: number) => {
        let copy = Object.assign([], reorderList(trainingModule.orderedPracticalExercises ? trainingModule.orderedPracticalExercises : [], index, index - 1));
        let reorderedExercise = copy.map((exercise: OrderedPracticalExercise, index) => ({
            ...exercise,
            exerciseOrder: index
        }));
        setTrainingModule((oldState: PracticalExercise) => ({
            ...oldState, orderedPracticalExercises: reorderedExercise
        }));
    }
    const handleDown = (index: number) => {
        let copy = Object.assign([], reorderList(trainingModule.orderedPracticalExercises ? trainingModule.orderedPracticalExercises : [], index + 1, index));
        let reorderedExercise = copy.map((exercise: OrderedPracticalExercise, index) => ({
            ...exercise,
            exerciseOrder: index
        }));
        setTrainingModule((oldState: PracticalExercise) => ({
            ...oldState, orderedPracticalExercises: reorderedExercise
        }));
    }

    function isFormValid() {
        return trainingModule !== undefined && trainingModule.name !== ("" || undefined) && trainingModule.description !== ("" || undefined);
    }

    return (
        <Dialog PaperProps={{
            style: {
                backgroundImage: "none"
            },
        }} open={props.isOpen}>
            <DialogTitle>{props.mode === "edit" ? "Edit training module" : "New training module"}</DialogTitle>
            <DialogContent>
                <Grid container rowSpacing={1} sx={{paddingTop: "20px"}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            fullWidth
                            required
                            variant="standard"
                            label="Name"
                            defaultValue={trainingModule?.name}
                            error={nameError}
                            onChange={(e) => {
                                setNameError(e.target.value === "")
                                setTrainingModule((oldState: TrainingModule) => ({
                                    ...oldState, name: e.target.value
                                }));
                            }}
                            helperText={nameError ? "Please enter a name." : ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            fullWidth
                            required
                            variant="standard"
                            label="Description"
                            defaultValue={trainingModule?.description}
                            error={descriptionError}
                            onChange={(e) => {
                                setDescriptionError(e.target.value === "")
                                setTrainingModule((oldState: TrainingModule) => ({
                                    ...oldState, description: e.target.value
                                }));
                            }}
                            helperText={descriptionError ? "Please enter a description." : ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography component="h6" color="primary" sx={{paddingTop: 1}}>
                            Practical Exercises
                        </Typography>
                        <List>
                            {
                                trainingModule?.orderedPracticalExercises?.map((trainingExercise, index) => {
                                    return <ListItem
                                        key={trainingExercise?.practicalExercise?.name}
                                        secondaryAction={
                                            <Grid container>
                                                <IconButton disabled={index === 0} size={"small"} color={"primary"}
                                                            aria-label="Add" onClick={() => handleUp(index)}>
                                                    <KeyboardArrowUp></KeyboardArrowUp>
                                                </IconButton>
                                                <IconButton
                                                    disabled={index + 1 === trainingModule?.orderedPracticalExercises?.length}
                                                    size={"small"} color={"primary"} aria-label="Add"
                                                    onClick={() => handleDown(index)}>
                                                    <KeyboardArrowDown></KeyboardArrowDown>
                                                </IconButton>
                                                <IconButton edge="end" value={trainingExercise?.practicalExercise?.name}
                                                            onClick={() => removeExercise(trainingExercise.practicalExercise)}
                                                            aria-label="delete">
                                                    <DeleteIcon color={"primary"}/>
                                                </IconButton>
                                            </Grid>
                                        }
                                    >
                                        <ListItemText
                                            sx={{
                                                "& .MuiListItemText-root": {
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }
                                            }}
                                            primary={trainingExercise.practicalExercise?.name}
                                        />
                                    </ListItem>
                                })
                            }

                        </List>
                        <Autocomplete
                            id="add-exercise"
                            fullWidth
                            options={exercisesPage.content}
                            getOptionDisabled={(option) =>
                                trainingModule.orderedPracticalExercises
                                    ? trainingModule.orderedPracticalExercises.findIndex(exercise => exercise?.practicalExercise?.name === option.name) !== -1
                                    : false
                            }
                            getOptionLabel={(option) => option.name ?? ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Exercises"
                                    placeholder="Add exercises"
                                />
                            )}
                            onChange={(event: any, newExercise: PracticalExercise | null | undefined) => {
                                if (newExercise != null) handleNewExerciseSelection(newExercise);
                                setAutocompleteValue({name: ""})
                            }}
                            isOptionEqualToValue={() => true}
                            value={autocompleteValue}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disableElevation onClick={handleClose}
                        disabled={isCreateTrainingLoading || isUpdateTrainingLoading}>CANCEL</Button>
                {
                    isFormValid ?
                        <LoadingButton
                            size="small"
                            onClick={handleSaveTraining}
                            loading={(isCreateTrainingLoading || isUpdateTrainingLoading) && isFormValid()}
                            disableElevation
                            variant="contained"
                        >
                            <span>{props.mode === "edit" ? "SAVE" : "CREATE"}</span>
                        </LoadingButton>
                        :
                        <Button variant="contained" disableElevation onClick={handleSaveTraining}
                                disabled>{props.mode === "edit" ? "SAVE" : "CREATE"}</Button>
                }
            </DialogActions>
        </Dialog>
    );
}

export default TrainingModuleDetailsDialog;
