import {MenuItem, Select, SelectChangeEvent} from "@mui/material";
import React, {useMemo, useState} from "react";
import InstanceStatusChip from "./InstanceStatusChip";
import {useUpdateStatusMutation} from "../../services/InstanceService";
import {Status} from "../../types/Instance";
import SnackBarUtils from "../SnackBarUtils";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

type Props = {
    currentStatus: Status,
    instanceId: string
};

const InstanceStatusSelect: React.FunctionComponent<Props> = ({currentStatus: initialStatus, instanceId}) => {
    const [status, setStatus] = useState(initialStatus);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [updateInstanceStatus] = useUpdateStatusMutation();

    useMemo(() => {
        setStatus(initialStatus);
    }, [initialStatus])

    const updateStatus = () => {
        updateInstanceStatus({
            instanceId: instanceId,
            newStatus: status
        }).unwrap().then((instance) => {
            instance.status && setStatus(instance.status)
            SnackBarUtils.info('Practical exercise successfully modified')
            setOpenConfirmDialog(false)
        }).catch(() => {
            SnackBarUtils.error('Error happened while updating practical exercise.')
            setOpenConfirmDialog(false)
        });
    };

    const handleOpen = (event: SelectChangeEvent) => {
        setOpenConfirmDialog(true);
        setStatus(event.target.value as Status);
    };
    const handleClose = () => {
        setOpenConfirmDialog(false);
        setStatus(initialStatus);
    };

    return (
        <>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={status}
                defaultValue={status}
                autoWidth
                disableUnderline={true}
                variant="standard"
                renderValue={value => <InstanceStatusChip status={value}></InstanceStatusChip>}
                label="Status"
                onChange={(e) => handleOpen(e)}
            >
                <MenuItem value="CREATED">
                    <InstanceStatusChip status={"CREATED"}></InstanceStatusChip>
                </MenuItem>
                <MenuItem value="STARTED"> <InstanceStatusChip
                    status={"STARTED"}></InstanceStatusChip>
                </MenuItem>
                <MenuItem value="DESTROYED"> <InstanceStatusChip
                    status={"DESTROYED"}></InstanceStatusChip>
                </MenuItem>
                <MenuItem value="FAILED">
                    <InstanceStatusChip status={"FAILED"}></InstanceStatusChip>
                </MenuItem>
            </Select>
            <Dialog PaperProps={{
                style: {
                    backgroundImage: "none"
                },
            }} open={openConfirmDialog} fullWidth>
                <DialogTitle>Warning</DialogTitle>
                <DialogContent>
                    <p>You are about to force the instance status. This status is calculated by the server and is not
                        meant to be changed manually.
                        Please make sure this new status corresponds to instance status in Scaleway</p>
                    <p>Do you want to continue?</p>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation onClick={handleClose}>CANCEL</Button>
                    <Button disableElevation variant="contained"
                            onClick={() => updateStatus()}>CONTINUE</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default InstanceStatusSelect;
