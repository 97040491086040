import React, {useState} from "react";
import {ListItem} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from "@mui/material/IconButton";
import CheckIcon from '@mui/icons-material/Check';
import ListItemText from "@mui/material/ListItemText";
import {copyTextToClipboard} from "../utils/Utils";
import {useTheme} from "@mui/material/styles";

type Props = {
    label: string,
    value: string
};

const InstanceOutputForm: React.FunctionComponent<Props> = ({label, value}) => {
    const [isCopied, setIsCopied] = useState(false);

    const urlRegex: RegExp = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/

    const theme = useTheme();
    // onClick handler function for the copy button
    const handleCopyClick = (copyText: string) => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(copyText)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <ListItem sx={{padding: 0}}
                  secondaryAction={
                      <IconButton onClick={() => handleCopyClick(value)}>
                          {isCopied ? <CheckIcon color="success"/> : <ContentCopyIcon/>}
                      </IconButton>
                  }
        >
            <ListItemText primaryTypographyProps={{fontWeight: 550}} primary={label}
                          secondary={
                              urlRegex.test(value)
                                  ? <a href={value} target={"_blank"} rel="noreferrer">{value}</a>
                                  : value
                          }
                          secondaryTypographyProps={{color: theme.palette.primary.main}}
                          sx={{
                              "& .MuiListItemText-secondary": {
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "85%"
                              },
                              "a": {
                                  color: theme.palette.primary.main
                              }
                          }}
            />
        </ListItem>
    );
};

export default InstanceOutputForm;
