import TrainingSession from "../types/TrainingSession";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {API_URL} from "../api/ConfigApi";
import PracticalExercise, {ExerciseStatus} from "../types/PracticalExercise";
import Instance from "../types/Instance";
import {prepareHeaders} from "./ServiceUtils";
import {RootState} from "../slices";
import Page from "../types/Page";
import AccessCode from "../types/AccessCode";
import TrainingSessionLink from "../types/TrainingSessionLink";
import TrainingModule from "../types/TrainingModule";
import Rating from "../types/Rating";

function sortModulesByDate(a: TrainingModule, b: TrainingModule) {
    if (a?.startDate && b.startDate && a?.startDate < b.startDate) {
        return -1;
    }
    if (a?.startDate && b.startDate && a?.startDate > b.startDate) {
        return 1;
    }
    return 0;
}

export const trainingSessionApi = createApi({
    reducerPath: 'trainingSessionApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers, {getState}) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            return prepareHeaders(headers, getState() as RootState)
        },
    }),
    tagTypes: ['TrainingSession', 'TrainingSessionLinks'],
    endpoints: (builder) => ({
        getTrainingSessions: builder.query<Page<TrainingSession>, { pageNo: number, pageSize: number, sort: string[], filter?: string[] }>({
            query: (arg) => {
                const {pageNo, pageSize, sort, filter} = arg;
                return {
                    url: "/trainingSessions",
                    params: arg?.filter ? {pageNo, pageSize, sort, filter} : {pageNo, pageSize, sort}
                };
            },
            providesTags: (result) =>
                result
                    ? [
                        // Provides a tag for each post in the current page,
                        // as well as the 'PARTIAL-LIST' tag.
                        ...result.content.map(({id}) => ({type: 'TrainingSession' as const, id})),
                        {type: 'TrainingSession', id: 'PARTIAL-LIST'},
                    ]
                    : [{type: 'TrainingSession', id: 'PARTIAL-LIST'}],
            transformResponse: (rawResult: Page<TrainingSession>) => {
                return rawResult;
            },
        }),
        updateTrainingSession: builder.mutation<TrainingSession, TrainingSession>({
            query: (session) => ({
                url: `/trainingSessions/${session.id}`,
                method: 'PUT',
                body: session,
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'TrainingSession', id}, {
                type: 'TrainingSession',
                id: 'PARTIAL-LIST'
            },]
        }),
        deleteTrainingSession: builder.mutation<string, string>({
            query: (id) => ({
                url: `/trainingSessions/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [
                {type: 'TrainingSession', id},
                {type: 'TrainingSession', id: 'PARTIAL-LIST'},
            ],
        }),
        archiveTrainingSession: builder.mutation<TrainingSession, { id: string, isArchived: boolean }>({
            query: (params) => ({
                url: `/trainingSessions/${params.id}/isArchived`,
                method: 'PUT',
                body: params.isArchived,
            }),
            invalidatesTags: (result, error, {id}) => [{type: 'TrainingSession', id}, {
                type: 'TrainingSession',
                id: 'PARTIAL-LIST'
            },]
        }),
        createTrainingSession: builder.mutation<TrainingSession, { trainingSession: TrainingSession, learningPathId: string | undefined }>({
            query: (params) => ({
                url: `/trainingSessions?learningPathId=${params.learningPathId}`,
                method: 'POST',
                body: params.trainingSession,
            }),
            invalidatesTags: () => [
                {type: 'TrainingSession', id: 'PARTIAL-LIST'},
            ],
        }),
        rateExercise: builder.mutation<Rating, { rating: Rating, exerciseId: string | undefined }>({
            query: (params) => ({
                url: `/practicalExercises/${params.exerciseId}/rating`,
                method: 'POST',
                body: params.rating,
            }),
        }),
        getTrainingSession: builder.query<TrainingSession, string | null>({
            query: (id) => `/trainingSessions/${id}`,
            providesTags: (result) =>
                result?.id ? ([{type: 'TrainingSession' as const, id: result.id}]) : [{type: 'TrainingSession'}],
            transformResponse: (rawResult: TrainingSession) => {
                if (rawResult.trainingModules) {
                    rawResult.trainingModules?.sort(sortModulesByDate);
                }
                return rawResult;
            },
        }),
        getTrainingSessionExercise: builder.query<PracticalExercise, string>({
            providesTags: ['TrainingSession'],
            query: (exerciseId) => ({
                url: `/practicalExercises/${exerciseId}`,
            })
        }),
        getTrainingSessionExerciseTrainerNotes: builder.query<string, { exerciseId: string }>({
            query: (params) => ({
                url: `/practicalExercises/${params.exerciseId}/trainerNotes`,
                responseHandler: "text"
            }),
            providesTags: ['TrainingSession']
        }),
        getTrainingSessionExerciseGuideline: builder.query<string, { exerciseId: string | undefined }>({
            query: (params) => ({
                url: `/practicalExercises/${params.exerciseId}/guideline`,
                responseHandler: "text"
            }),
            providesTags: ['TrainingSession']
        }),
        updateTrainingSessionTrainees: builder.mutation<Array<Instance>, { sessionId: string, trainees: string[] }>({
            query: (params) => ({
                url: `/trainingSessions/${params.sessionId}/trainees`,
                method: 'PUT',
                body: params.trainees,
            }),
            invalidatesTags: (result, error, {sessionId}) => [{type: 'TrainingSession', sessionId}, {
                type: 'TrainingSession',
                id: 'PARTIAL-LIST'
            }]
        }),
        updateTrainingSessionExerciseStatus: builder.mutation<PracticalExercise, { sessionId: string, exerciseId: string, status: ExerciseStatus }>({
            query: (params) => ({
                url: `/practicalExercises/${params.exerciseId}/status`,
                method: 'PUT',
                body: params.status,
            }),
            invalidatesTags: (result, error, {sessionId}) => [{type: 'TrainingSession', sessionId}, {
                type: 'TrainingSession',
                id: 'PARTIAL-LIST'
            }]
        }),
        joinTrainingSession: builder.mutation<Array<TrainingSession>, string>({
            query: (accessCode) => ({
                url: `/trainingSessions/join?accessCode=${accessCode}`,
                method: 'POST',
            }),
            invalidatesTags: ['TrainingSession'],
        }),
        getTrainingSessionAccessCode: builder.query<AccessCode, string>({
            query: (id) => `/trainingSessions/${id}/accessCode`,
            providesTags: (_instance, _err) => [{type: 'TrainingSession'}],
        }),
        generateNewAccessCode: builder.mutation<AccessCode, string>({
            query: (id) => ({
                url: `/trainingSessions/${id}/accessCode`,
                method: 'PUT'
            }),
            invalidatesTags: () => [{type: 'TrainingSession'}]
        }),
        getTrainingSessionLinks: builder.query<TrainingSessionLink[], string | null>({
            query: (id) => `/trainingSessions/${id}/links`,
            providesTags: (_instance, _err) => [{type: 'TrainingSession'}],
        }),
        updateTrainingSessionLinks: builder.mutation<TrainingSessionLink[], { id: string, links: TrainingSessionLink[] }>({
            query: ({id, links}) => ({
                url: `/trainingSessions/${id}/links`,
                body: links,
                method: 'PUT'
            }),
            invalidatesTags: () => [{type: 'TrainingSession'}]
        }),
        updateTrainingSessionModule: builder.mutation<TrainingModule, { sessionId: string, moduleId: string, start: Date, end: Date }>({
            query: ({moduleId, start, end}) => ({
                url: `/trainingModules/${moduleId}`,
                method: 'PUT',
                body: {id: moduleId, startDate: start, endDate: end},
            }),
            invalidatesTags: (result, error, {sessionId}) => [
                {type: 'TrainingSession', sessionId},
                {type: 'TrainingSession', id: 'PARTIAL-LIST'},
            ],
        }),
    }),
})

export const {
    useGetTrainingSessionQuery,
    useLazyGetTrainingSessionQuery,
    useLazyGetTrainingSessionsQuery,
    useUpdateTrainingSessionMutation,
    useDeleteTrainingSessionMutation,
    useCreateTrainingSessionMutation,
    useUpdateTrainingSessionTraineesMutation,
    useArchiveTrainingSessionMutation,
    useGetTrainingSessionExerciseQuery,
    useUpdateTrainingSessionExerciseStatusMutation,
    useLazyGetTrainingSessionExerciseTrainerNotesQuery,
    useLazyGetTrainingSessionExerciseGuidelineQuery,
    useJoinTrainingSessionMutation,
    useLazyGetTrainingSessionAccessCodeQuery,
    useGenerateNewAccessCodeMutation,
    useGetTrainingSessionLinksQuery,
    useUpdateTrainingSessionLinksMutation,
    useUpdateTrainingSessionModuleMutation,
    useRateExerciseMutation
} = trainingSessionApi