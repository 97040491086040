import React, {useEffect, useState} from 'react';
import {List, ListItem} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import {AccountCircle} from "@mui/icons-material";
import {useParams} from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
    useGetTrainingSessionQuery,
    useLazyGetTrainingSessionQuery,
    useUpdateTrainingSessionTraineesMutation
} from "../../services/TrainingSessionService";

type Props = {};

const TraineeList: React.FunctionComponent<Props> = () => {

    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

    const {id} = useParams<{ id: any }>()
    const [updateSessionTrainees] = useUpdateTrainingSessionTraineesMutation();
    const {
        data: session = {id: '', title: '', trainees: [''], trainingModule: []},
    } = useGetTrainingSessionQuery(id);
    const [
        triggerGetSessionQuery, {
            isFetching: isSessionLoading,
        }
    ] = useLazyGetTrainingSessionQuery();

    const [selectedTrainee, setSelectedTrainee] = useState('');

    useEffect(() => {
        triggerGetSessionQuery(id);
    }, []);

    const handleClickOpen = (trainee: string) => {
        setSelectedTrainee(trainee);
        setOpenConfirmDialog(true);
    };

    const handleClose = () => {
        setOpenConfirmDialog(false);
        setSelectedTrainee('');
    };
    const removeSelectedTrainee = () => {
        if (session.trainees === undefined) session.trainees = [];
        if (session.id != null) {
            const indexToRemove = session.trainees?.indexOf(selectedTrainee.trim());
            if (indexToRemove !== -1) {
                let newTrainees = Object.assign([], session.trainees);
                newTrainees.splice(indexToRemove, 1);
                updateSessionTrainees({sessionId: session.id, trainees: newTrainees})
                handleClose()
            }
        }
    }

    return (
        <React.Fragment>
            {
                session.trainees && session.trainees.length > 0 && !isSessionLoading ?
                    <List>
                        {
                            session.trainees.map(trainee => {
                                return <ListItem
                                    key={trainee}
                                    secondaryAction={
                                        <IconButton edge="end" value={trainee} aria-label="delete"
                                                    onClick={() => handleClickOpen(trainee)}>
                                            <DeleteIcon color={"primary"}/>
                                        </IconButton>
                                    }
                                >
                                    <ListItemIcon>
                                        <AccountCircle/>
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            "& .MuiListItemText-root": {
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }
                                        }}
                                        primary={trainee}
                                    />
                                </ListItem>
                            })
                        }

                    </List> : "No trainee"
            }
            <Dialog PaperProps={{
                style: {
                    backgroundImage: "none"
                },
            }} open={openConfirmDialog} fullWidth>
                <DialogTitle>Warning</DialogTitle>
                <DialogContent>
                    <p>You are about to delete trainee '{selectedTrainee}'. All corresponding instances will be deleted
                        forever. Trainee will have no access to this session and will lose progress on all
                        exercises.</p>
                    <p>Do you want to continue?</p>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation onClick={handleClose}>CANCEL</Button>
                    <Button disableElevation variant="contained"
                            onClick={() => removeSelectedTrainee()}>CONTINUE</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>

    );
};

export default TraineeList;
