import React from 'react';

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import InstanceList from "../components/instance/InstanceList";
import {useTheme} from "@mui/material/styles";

type Props = {
    pageTitle: (arg: string) => void,
};

const Instances: React.FunctionComponent<Props> = ({pageTitle}) => {
    React.useEffect(() => {
        pageTitle("Instances");
    });
    const theme = useTheme();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: theme.palette.background.paper,
                    }}
                >
                    <InstanceList/>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Instances;
