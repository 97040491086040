import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {configSlice} from "./configSlice";
import {userSlice} from "./userSlice";
import {themeSlice} from "./themeSlice";

const rootReducer = combineReducers({
    user: userSlice.reducer,
    config: configSlice.reducer,
    theme: themeSlice.reducer
});

const store = configureStore({
    reducer: rootReducer,
});

export {store, userSlice, configSlice};

export type RootState = ReturnType<typeof rootReducer>;
