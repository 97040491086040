import {useSelector} from "react-redux";

import {RootState} from "../../slices";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Avatar, CardContent, CardHeader, List, ListItem} from "@mui/material";
import {Item, stringAvatar} from "../utils/Utils";
import {useTheme} from "@mui/material/styles";
import {useGetTraineeQuery, useSetPseudonymMutation} from "../../services/TraineeService";
import SnackBarUtils from "../SnackBarUtils";
import ListItemText from "@mui/material/ListItemText";
import InteractiveOutlinedInput from "../utils/InteractiveOutlinedInput";

// Display the identity of the user
const ProfileInfo: React.FunctionComponent = () => {
    const isAdmin = useSelector((state: RootState) => state.user.isAdmin);
    const firstname = useSelector((state: RootState) => state.user.firstname);
    const lastname = useSelector((state: RootState) => state.user.lastname);
    const username = useSelector((state: RootState) => state.user.username);

    const [setPseudonym] = useSetPseudonymMutation();

    const {data: trainee} = useGetTraineeQuery();

    const theme = useTheme();

    const handleUpdateChangePseudonym = (enteredPseudonym: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            setPseudonym({
                username: username, pseudonym: enteredPseudonym.trim()
            }).unwrap().then(() => {
                SnackBarUtils.info('Pseudonym updated successfully');
                resolve(true)
            }).catch(() => {
                SnackBarUtils.error('Failed to update pseudonym');
                reject(false)
            });
        });
    };

    return (
        <Grid container spacing={0} alignItems={"stretch"}>
            <Grid item xs={12} md={12} lg={12}>
                <Item>
                    <CardHeader title={
                        <Typography component="h2" variant="h6" color="primary">
                            Profile
                        </Typography>
                    }/>
                    <CardContent sx={{minHeight: 220}}>
                        <Grid container display="flex" gap="32px" justifyContent="flex-start" padding={"0 32px"}>
                            <Grid item display="flex" alignSelf="flex-start" justifyContent="center"
                                  alignItems="center">
                                <Avatar {...stringAvatar(firstname, lastname)}
                                        sx={{
                                            color: theme.palette.primary.contrastText,
                                            backgroundColor: theme.palette.primary.main,
                                            width: 150,
                                            height: 150,
                                            fontSize: 80
                                        }}/>
                            </Grid>
                            <Grid item display="flex" flexDirection="column" gap={0.5}>
                                <Typography component="h3" variant="h4">
                                    {`${firstname} ${lastname} ${trainee?.pseudonym ? `(${trainee.pseudonym})` : ''}`}
                                </Typography>
                                <List>
                                    <ListItem sx={{padding: 0}}>
                                        <ListItemText primaryTypographyProps={{fontWeight: 550}}
                                                      primary="User role"
                                                      secondary={((isAdmin) ? "Trainer" : "Trainee")}
                                                      secondaryTypographyProps={{component: 'div'}}/>
                                    </ListItem>
                                    <ListItem sx={{padding: 0}}>
                                        <ListItemText primaryTypographyProps={{fontWeight: 550}}
                                                      primary="Email"
                                                      secondary={username}
                                                      secondaryTypographyProps={{component: 'div'}}/>
                                    </ListItem>
                                    {!isAdmin && (
                                        <ListItem sx={{padding: 0}}>
                                            <ListItemText primaryTypographyProps={{fontWeight: 550}}
                                                          primary="Pseudonym"
                                                          secondary={
                                                              <InteractiveOutlinedInput
                                                                  initialValue={trainee?.pseudonym ? `${trainee.pseudonym}` : username}
                                                                  handleUpdate={handleUpdateChangePseudonym}
                                                                  fullWidth={false}
                                                                  sx={{
                                                                      color: theme.palette.text.secondary,
                                                                      fontSize: 14,
                                                                      height: 20
                                                                  }}
                                                                  maxLength={10}
                                                              />
                                                          }
                                                          secondaryTypographyProps={{component: 'div'}}/>
                                        </ListItem>
                                    )}
                                </List>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Item>
            </Grid>
        </Grid>
    );
};

export default ProfileInfo;
