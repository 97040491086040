import React from 'react';

import Grid from "@mui/material/Grid";
import LearningPathCatalogList from "../components/catalog/learningPaths/LearningPathsCatalogList";

type Props = {
    pageTitle: (arg: string) => void,
};

const LearningPaths: React.FunctionComponent<Props> = ({pageTitle}) => {

    React.useEffect(() => {
        pageTitle("Catalog - Learning Paths");
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <LearningPathCatalogList/>
            </Grid>
        </Grid>
    );
};

export default LearningPaths;
