import {OptionsObject, useSnackbar, WithSnackbarProps} from 'notistack'
import * as React from 'react'
import {Button} from "@mui/material";

interface IProps {
    setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void
}

const InnerSnackbarUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
    props.setUseSnackbarRef(useSnackbar())
    return null
}

let useSnackbarRef: WithSnackbarProps
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
    useSnackbarRef = useSnackbarRefProp
}

export const SnackbarUtilsConfigurator = () => {
    return (
        <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef}/>
    )
}
const action = () => (
    <Button color="inherit" href={'/home'}>SHOW ME</Button>
);
const SnackBarUtils = {
    success(msg: string, options: OptionsObject = {}, link?: string) {
        this.toast(msg, {...options, variant: 'success', action: link ? action() : <></>})
    },
    warning(msg: string, options: OptionsObject = {}) {
        this.toast(msg, {...options, variant: 'warning'})
    },
    info(msg: string, options: OptionsObject = {}) {
        this.toast(msg, {...options, variant: 'info'})
    },
    error(msg: string, options: OptionsObject = {}) {
        this.toast(msg, {...options, variant: 'error'})
    },
    toast(msg: string, options: OptionsObject = {}) {
        useSnackbarRef.enqueueSnackbar(msg, options)
    }
}
export default SnackBarUtils;