import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {CircularProgress, InputAdornment, Theme} from "@mui/material";
import React, {KeyboardEvent, useState} from "react";
import {Check, Send} from "@mui/icons-material";
import Answer from "../../types/Answer";
import {useGetInstanceQuery, useUpdateAnswerMutation} from "../../services/InstanceService";
import IconButton from "@mui/material/IconButton";
import {useLazyGetTraineeQuery} from "../../services/TraineeService";
import SnackBarUtils from "../SnackBarUtils";
import {useTheme} from '@mui/material/styles';

interface AnswerQuestionProps {
    answer: Answer,
    instanceId: string
}

export const AnswerQuestion = ({answer: initialAnswer, instanceId}: AnswerQuestionProps) => {

    const [answer, setAnswer] = useState(initialAnswer);
    const [newAnswer, setNewAnswer] = useState(initialAnswer.lastAnswer);
    const [updateAnswer, {isLoading: isUpdatingAnswer}] = useUpdateAnswerMutation();
    const {
        data: instance,
    } = useGetInstanceQuery(instanceId);
    const [triggerGetTrainee] = useLazyGetTraineeQuery();
    const theme = useTheme<Theme>();

    function isSurveyDone() {
        let nbOfSuccess = 0;
        instance?.survey?.forEach((answer) => {
            answer.correct && nbOfSuccess++;
        })
        return nbOfSuccess + 1 === instance?.survey?.length
    }

    function getBackgroundBorderColor() {
        if (answer.correct) return theme.palette.success.main;
        if (answer.lastAnswer) return theme.palette.error.main
        return theme.palette.primary.main;
    }

    function handleSubmit() {
        updateAnswer({
            instanceId: instanceId,
            answer: newAnswer,
            questionId: answer.questionId
        }).unwrap().then((resp: Answer) => {
            setAnswer(resp);
            if (resp.correct) {
                SnackBarUtils.info('Correct !')
                if (isSurveyDone()) {
                    //update awarded badges
                    triggerGetTrainee();
                }
            } else {
                SnackBarUtils.error('Your answer is incorrect.')
            }
        });
    }

    const handleKeyDownAdd = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    };

    return (
        <Grid item xs={12} md={12} lg={12}
              sx={{
                  backgroundColor: theme.palette.background.paper,
                  padding: "1em",
                  borderRadius: "10px",
                  border: "2px solid " + getBackgroundBorderColor()
              }}>
            <TextField
                sx={{minWidth: '100%'}}
                onKeyDown={handleKeyDownAdd}
                label={answer.question}
                placeholder={"Answer format: " + answer.format}
                variant="standard"
                value={newAnswer ? newAnswer : ""}
                onChange={(e) => {
                    setNewAnswer(e.target.value)
                }}
                color={answer.correct ? "success" : "primary"}
                focused
                disabled={answer.correct}
                InputProps={{
                    endAdornment: (answer.correct ?
                            <InputAdornment position="end">
                                <IconButton disabled color="primary">
                                    <Check color={"success"}/>
                                </IconButton>
                            </InputAdornment> :
                            <InputAdornment position="end">
                                {
                                    isUpdatingAnswer
                                        ?
                                        <CircularProgress size={20} color="primary"/>
                                        :
                                        <IconButton onClick={handleSubmit} color="primary">
                                            <Send></Send>
                                        </IconButton>
                                }
                            </InputAdornment>
                    ),
                }}
            />
        </Grid>)
}