import PracticalExercise from "./PracticalExercise";
import Answer from "./Answer";

export enum Status {
    CREATED = "CREATED",
    PLANNED = "PLANNED",
    STARTING = "STARTING",
    STARTED = "STARTED",
    FAILED = "FAILED",
    DESTROYING = "DESTROYING",
    DESTROYED = "DESTROYED",
}

export default interface Instance {
    id: string | "",
    name?: string,
    inputs?: {
        [key: string]: string;
    },
    outputs?: {
        [key: string]: string;
    },
    logs?: string,
    status?: Status,
    lastCorrectAnswerDate?: Date,
    createdDate?: Date,
    deploymentStartDate?: Date,
    deploymentEndDate?: Date,
    practicalExercise?: PracticalExercise,
    trainee: string,
    survey?: Answer[],
    automaticallyDestroyed?: boolean,
}