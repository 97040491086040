import React from 'react';
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {LinearProgress, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useNavigate} from "react-router-dom";
import TrainingSession from "../../types/TrainingSession";
import StartExercise from "./StartExercise";
import PracticalExerciseStatusChip from "./PracticalExerciseStatusChip";
import StopExercise from "./StopExercise";
import {ExerciseStatus} from "../../types/PracticalExercise";
import TraineeExerciseStatusChip from "../trainee/TraineeExerciseStatusChip";
import TrainingModule from "../../types/TrainingModule";
import {useUpdateTrainingSessionExerciseStatusMutation} from "../../services/TrainingSessionService";

type Props = {
    trainingSession: TrainingSession | null,
    module: TrainingModule
};

const PracticalExerciseList: React.FunctionComponent<Props> = (
    {trainingSession, module}: Props,
) => {

    const [updateExerciseStatus] = useUpdateTrainingSessionExerciseStatusMutation();

    const navigate = useNavigate();

    const handleChangeStatus = (exerciseId: string, event: SelectChangeEvent) => {
        trainingSession?.id && updateExerciseStatus({
            sessionId: trainingSession.id,
            exerciseId: exerciseId,
            status: event.target.value as ExerciseStatus
        });
    };
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 100,
        page: 0,
    });
    const columns: GridColDef[] = [
        {
            field: 'status',
            align: 'center',
            headerName: 'Status',
            headerAlign: 'center',
            width: 140,
            renderCell: (params) => (
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={params.row.status}
                    autoWidth
                    disableUnderline={true}
                    variant="standard"
                    label="Status"
                    onChange={(e) => handleChangeStatus(params.row.id, e)}
                >
                    <MenuItem value="UPCOMING">
                        <TraineeExerciseStatusChip status={ExerciseStatus.UPCOMING}></TraineeExerciseStatusChip>
                    </MenuItem>
                    <MenuItem value="LIVE"> <TraineeExerciseStatusChip
                        status={ExerciseStatus.LIVE}></TraineeExerciseStatusChip></MenuItem>
                    <MenuItem value="FINISHED"> <TraineeExerciseStatusChip
                        status={ExerciseStatus.FINISHED}></TraineeExerciseStatusChip></MenuItem>
                </Select>
            )
        },
        {
            field: 'name',
            align: 'center',
            headerName: 'Name',
            flex: 1,
            headerAlign: 'center',
            renderCell: (params) => (
                <div
                    onClick={(() => navigate("/sessions/" + trainingSession?.id + "/exercises/" + params.row.id))}>{params.row.name}</div>
            )
        },
        {
            field: 'deploymentDuration',
            align: 'center',
            headerName: 'Deployment duration',
            headerAlign: 'center',
            renderCell: (params) => (
                <div
                    onClick={(() => navigate("/sessions/" + trainingSession?.id + "/exercises/" + params.row.id))}>{params.row.deploymentDuration} min</div>
            )
        },
        {
            field: 'exerciseDuration',
            align: 'center',
            headerName: 'Exercise duration',
            headerAlign: 'center',
            renderCell: (params) => (
                <div
                    onClick={(() => navigate("/sessions/" + trainingSession?.id + "/exercises/" + params.row.id))}>{params.row.exerciseDuration} min</div>
            )
        },
        {
            field: 'states',
            headerName: 'Instances states',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            width: 250,
            renderCell: (params) => (
                <PracticalExerciseStatusChip exerciseId={params.row.id}
                                             sessionId={trainingSession?.id}>Edit</PracticalExerciseStatusChip>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                trainingSession ?
                    <>
                        <StartExercise session={trainingSession} practicalExercise={params.row}
                                       type={"icon"}></StartExercise>
                        <StopExercise session={trainingSession} practicalExercise={params.row}
                                      type={"icon"}></StopExercise>
                    </>
                    : <></>
            )
        }
    ];

    return (
        <React.Fragment>
            <DataGrid
                sx={{
                    boxShadow: 0,
                    border: 0,
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none",
                    },
                    cursor: "pointer"
                }}
                disableRowSelectionOnClick
                autoHeight
                rows={module.practicalExercises || []}
                columns={columns}
                getRowId={(row: any) => row.id.toString()}
                components={{
                    LoadingOverlay: LinearProgress,
                }}
                paginationModel={paginationModel}
            />
        </React.Fragment>

    );
}

export default PracticalExerciseList;
