import React, {useEffect} from 'react';
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Chip,
    Input,
    InputLabel,
    LinearProgress
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Link as ReactLink, useNavigate, useParams, useSearchParams} from "react-router-dom";
import logo from '../../assets/picto_lupise.png';
import SnackBarUtils from "../SnackBarUtils";
import LoadingButton from "@mui/lab/LoadingButton";
import {SignalCellularAlt} from "@mui/icons-material";
import {Level} from "../../types/LearningPath";
import {useJoinTrainingSessionMutation} from "../../services/TrainingSessionService";
import {
    useLazyGetInstancesWithFiltersQuery,
    useLazyGetTraineeTrainingSessionsQuery
} from "../../services/InstanceService";
import TrainingProgress from "./TrainingProgress";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const TraineeTrainingsOverview: React.FunctionComponent = () => {

        const [triggerGetTrainingSession, {
            data: traineeTrainingSession = []
        }] = useLazyGetTraineeTrainingSessionsQuery({pollingInterval: 5000});
        const [triggerGetTraineeInstances, {
            data: traineeInstances = {content: [], totalElements: 0}
        }] = useLazyGetInstancesWithFiltersQuery({pollingInterval: 5000});
        const [joinSession, {isLoading: isJoiningSession}] = useJoinTrainingSessionMutation();
        const navigate = useNavigate();
        const [accessCode, setAccessCode] = React.useState('');
        const [open, setOpen] = React.useState(false);

        const [queryParameters] = useSearchParams();
        const route = useParams<{ accessCode: any }>()


        function getToggleColor(level: Level) {
            switch (level) {
                case Level.BEGINNER :
                    return "success";
                case Level.INTERMEDIATE :
                    return "warning";
                case Level.EXPERT:
                    return "error";
                default:
                    return "info"
            }
        }

        useEffect(() => {
            triggerGetTrainingSession();
            triggerGetTraineeInstances({pageNo: 0, pageSize: 1000});
        }, []);

        useEffect(() => {
            if (route.accessCode) {
                setAccessCode(route.accessCode);
                handleJoinSession(route.accessCode);
            }
        }, [queryParameters]);

        const handleImageError = (e: any) => {
            e.target.onerror = null;
            e.target.src = logo
        }

        const handleJoinSession = (accessCode: string) => {
            joinSession(accessCode).unwrap().then(() => {
                triggerGetTrainingSession();
                setAccessCode('')
                handleClose();
                SnackBarUtils.info('Training successfully joined !')
            }).catch(() => {
                SnackBarUtils.error('Access code is wrong or expired.')
            });
        }

        const handleClose = () => {
            setOpen(false);
        };

        const handleOpen = () => {
            setOpen(true);
        };

        return (
            <>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button variant="contained" onClick={handleOpen} sx={{margin: "0.5em"}}>
                        Join session
                    </Button>
                </Grid>

                <Grid container direction="row"
                      alignItems="stretch"
                      spacing={3}>
                    {
                        traineeTrainingSession.map((trainingSession) => {
                            return (
                                <Grid key={'training' + trainingSession.id} item xs={4} md={4} lg={4}
                                      sx={{textAlign: "center"}}>
                                    <Card>
                                        <CardActionArea
                                            onClick={() => navigate(trainingSession.id === "standalone" ? "/my-standalone-exercises" : "/my-sessions?trainingId=" + trainingSession.id)}>
                                            <CardMedia
                                                component="img"
                                                alt="training image"
                                                height="300"
                                                src={trainingSession.imageLink ? trainingSession.imageLink : logo}
                                                onError={handleImageError}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div" height={"80px"}>
                                                    {trainingSession.title}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" height={"40px"} sx={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    display: "-webkit-box",
                                                    WebkitLineClamp: "2",
                                                    WebkitBoxOrient: "vertical",
                                                }}>
                                                    {trainingSession.description}
                                                </Typography>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-evenly"
                                                    alignItems="center"
                                                    height={"60px"}
                                                >
                                                    <Grid key={'training-level-' + trainingSession.id} item xs={4} md={4}
                                                          lg={4}
                                                          sx={{textAlign: "center"}}>
                                                        {trainingSession.level && <><InputLabel htmlFor="level"
                                                                                                sx={{
                                                                                                    fontWeight: 600,
                                                                                                    fontSize: "11px",
                                                                                                    marginTop: "13px",
                                                                                                    marginBottom: "4px"
                                                                                                }}>
                                                            Level
                                                        </InputLabel><Chip variant="outlined"
                                                                           color={getToggleColor(trainingSession.level)}
                                                                           label={trainingSession.level}
                                                                           sx={{border: "none", fontWeight: "700"}}
                                                                           icon={<SignalCellularAlt/>}/></>}
                                                    </Grid>
                                                    <Grid key={'training-progress-' + trainingSession.id} item xs={4} md={4}
                                                          lg={4}
                                                          sx={{textAlign: "center"}}>
                                                        {trainingSession.level && <><InputLabel htmlFor="progress"
                                                                                                sx={{
                                                                                                    fontWeight: 600,
                                                                                                    fontSize: "11px",
                                                                                                    marginTop: "13px",
                                                                                                    marginBottom: "4px"
                                                                                                }}>
                                                            Progress
                                                        </InputLabel>
                                                            {traineeInstances?.content &&
                                                                <TrainingProgress
                                                                    trainingSessionInstance={traineeInstances?.content?.filter(instance => instance?.practicalExercise?.trainingModule?.trainingSession?.id === trainingSession.id)}>
                                                                </TrainingProgress>
                                                            }
                                                        </>}
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                            <CardActions>

                                                <Grid item xs={12} md={12} lg={12}>
                                                    <Button variant="contained" disableElevation component={ReactLink}
                                                            to={trainingSession.id === "standalone" ? "/my-standalone-exercises" : "/my-sessions?trainingId=" + trainingSession.id}>Access</Button>
                                                </Grid>

                                            </CardActions>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                    <Dialog PaperProps={{
                        style: {
                            backgroundImage: "none"
                        },
                    }} open={open} fullWidth>
                        <DialogTitle sx={{textAlign: "center"}}>Join a training</DialogTitle>
                        <DialogContent>
                            <Grid key={'join-training'} item xs={12} md={12} lg={12} sx={{textAlign: "center"}}>
                                <Typography variant="body2" color="text.secondary" height={"40px"}>
                                    Please enter access code
                                </Typography>
                                <Grid height={"60px"}>
                                    <Input
                                        inputProps={{
                                            min: 0,
                                            style: {textAlign: 'center', fontWeight: '500', fontSize: '1.5rem'}
                                        }}
                                        value={accessCode}
                                        id="standard-adornment-password"
                                        onChange={(e) => {
                                            setAccessCode(e.target.value)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button disableElevation onClick={handleClose} disabled={isJoiningSession}>Cancel</Button>
                            <LoadingButton
                                onClick={() => handleJoinSession(accessCode)}
                                loading={isJoiningSession}
                                variant="contained" disableElevation
                            >
                                <span>Join</span>
                            </LoadingButton>
                        </DialogActions>
                        {isJoiningSession && (
                            <LinearProgress/>
                        )}
                    </Dialog>

                </Grid>
            </>);
    }
;

export default TraineeTrainingsOverview;
