import {useKeycloak} from '@react-keycloak/web';
import React, {useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {BrowserRouter, generatePath, Navigate, Route, Routes, useParams} from 'react-router-dom';

import Home from '../pages/Home';
import LoginPage from '../pages/Login';
import {RootState, userSlice} from "../slices";
import Keycloak from "keycloak-js";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Header from "../components/header/Header";
import Sessions from "../pages/Sessions";
import Instances from "../pages/Instances";
import InstanceDetails from "../components/instance/InstanceDetails";
import TrainingSessionDetails from "../components/trainingSession/TrainingSessionDetails";
import PracticalExerciseDetails from "../components/practicalExercise/PracticalExerciseDetails";
import TraineeView from "../components/trainee/TraineeView";
import TrainingModules from "../pages/TrainingModules";
import PracticalExercises from "../pages/PracticalExercises";
import PracticalExerciseCatalogDetails from "../components/catalog/PracticalExerciseCatalogDetails";
import TraineeOverview from "../pages/TraineeOverview";
import PageNotFound from "../pages/PageNotFound";
import MainBreadCrumb from "../pages/MainBreadCrumb";
import BreadcrumbItem from "../types/BreadcrumbItem";
import LearningPaths from "../pages/LearningPaths";
import LeaningPathCatalogDetails from "../components/catalog/learningPaths/LeaningPathCatalogDetails";
import TraineeTrainings from "../pages/TraineeTrainings";
import Events from "../pages/Events";
import TraineeStandaloneOverview from "../pages/TraineeStandaloneOverview";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {PaletteMode} from "@mui/material";
import Versions from "../pages/Versions";
import TrainingSessionPresentation from "../pages/TrainingSessionPresentationPage";
import ProfilePage from "../pages/Profile";

// Redirect on pages according to the given routes.
export const AppRouter = () => {
    const {initialized, keycloak} = useKeycloak();
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.user.token);
    const [mode, setMode] = useState<'light' | 'dark'>("dark");
    const darkMode = useSelector((state: RootState) => state.theme.darkMode);

    const [pageTitle, setPageTitle] = React.useState<string>("Default");
    const [breadCrumbItems, setBreadCrumbItems] = React.useState<BreadcrumbItem[]>([]);

    const updatePageTitle = (value: string) => {
        setPageTitle(value);
    }

    const updateBreadcrumbItems = (value: BreadcrumbItem[]) => {
        setBreadCrumbItems(value);
    }


    useMemo(() => {
        if (darkMode) {
            document.documentElement.setAttribute('data-color-mode', 'dark')
            document.documentElement.setAttribute('data-color-scheme', 'dark')
            setMode("dark");
        } else {
            document.documentElement.setAttribute('data-color-scheme', 'light')
            document.documentElement.setAttribute('data-color-mode', 'light')
            setMode("light");
        }
    }, [darkMode]);

    const getDesignTokens = (mode: PaletteMode) => ({
        palette: {
            mode,
            primary: {
                main: '#52A3BA',
                contrastText: '#f0fbff'
            },
            secondary: {
                main: '#262626',
            },
            warning: {
                main: '#9c8d76',
            },
            error: {
                main: '#d32f2f'
            },
            success: {
                main: '#4caf50'
            },
            background: {
                default: '#f5f5f5',
                paper: '#fff'
            },
            components: {
                styleOverrides: {
                    ".rbc-today": {
                        backgroundColor: "rgba(86, 172, 191, 0.24)"
                    },
                    ".rbc-toolbar button": {
                        color: "#fff"
                    }
                },
                MuiListItemButton: {
                    styleOverrides: {
                        root: {
                            "& .Mui-selected": {
                                color: "#56ACBF",
                            },
                            "& .Mui-selected .MuiListItemIcon-root": {
                                color: "#56ACBF",
                            },
                        }
                    }
                },
            },
            ...(mode === 'dark' && {
                primary: {
                    main: '#56ACBF',
                    contrastText: '#f0fbff'
                },
                secondary: {
                    main: '#262626',
                },
                warning: {
                    main: '#9c8d76',
                },
                error: {
                    main: '#d32f2f'
                },
                success: {
                    main: '#4caf50'
                },
                background: {
                    default: '#121212',
                    paper: '#1A2027'
                },
                text: {
                    primary: '#e0e1e2'
                }
            }),
        },
    });

    const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

    React.useEffect(() => {
        const token: any = keycloak.tokenParsed;
        if (token) {
            dispatch(userSlice.actions.setUserInfo({
                username: token.preferred_username,
                lastname: token.family_name,
                firstname: token.given_name,
                email: token.email,
            }));
            dispatch(userSlice.actions.setIsAdmin({
                groups: token.groups
            }));
        }
    }, [dispatch, initialized, keycloak]);

    if (!initialized || token === "") {
        return (
            <div>
                <p>Loading...</p>
            </div>
        );
    }

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Header pageTitle={pageTitle}/>

                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <Container maxWidth="lg" sx={{mb: 4}}>
                        <MainBreadCrumb items={breadCrumbItems}
                                        breadCrumbItems={updateBreadcrumbItems}></MainBreadCrumb>

                        <Routes>
                            <Route path="/home"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/home" auth={keycloak}>
                                           <Home pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/home"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/home" auth={keycloak}>
                                           <Home pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/sessions"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/sessions" auth={keycloak}
                                                    mustBeTrainer={true}>
                                           <Sessions pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/sessions/:id"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/sessions" auth={keycloak}
                                                    mustBeTrainer={true}>
                                           <TrainingSessionDetails pageTitle={updatePageTitle}
                                                                   breadCrumbItems={updateBreadcrumbItems}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/sessions/:sessionId/exercises/:exerciseId"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/sessions" auth={keycloak}
                                                    mustBeTrainer={true}>
                                           <PracticalExerciseDetails pageTitle={updatePageTitle}
                                                                     breadCrumbItems={updateBreadcrumbItems}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/instances"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/instances" auth={keycloak}
                                                    mustBeTrainer={true}>
                                           <Instances pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/instances/:id"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/instances" auth={keycloak}
                                                    mustBeTrainer={true}>
                                           <InstanceDetails pageTitle={updatePageTitle}
                                                            breadCrumbItems={updateBreadcrumbItems}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/learningPaths"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/learningPaths" auth={keycloak}
                                                    mustBeTrainer={true}>
                                           <LearningPaths pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/learningPaths/:id"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/learningPaths" auth={keycloak}
                                                    mustBeTrainer={true}>
                                           <LeaningPathCatalogDetails pageTitle={updatePageTitle}
                                                                      breadCrumbItems={updateBreadcrumbItems}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/modules"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/modules" auth={keycloak}
                                                    mustBeTrainer={true}>
                                           <TrainingModules pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/exercises"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/exercises" auth={keycloak}
                                                    mustBeTrainer={true}>
                                           <PracticalExercises pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/exercises/:id"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/exercises" auth={keycloak}
                                                    mustBeTrainer={true}>
                                           <PracticalExerciseCatalogDetails pageTitle={updatePageTitle}
                                                                            breadCrumbItems={updateBreadcrumbItems}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/my-sessions"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/my-sessions" auth={keycloak}>
                                           <TraineeOverview pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/my-trainings"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/my-trainings" auth={keycloak}>
                                           <TraineeTrainings pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/my-trainings/join/:accessCode"
                                   element={
                                       <RequireAuth redirectTo="/login"
                                                    redirectFrom="/my-trainings/join/:accessCode"
                                                    auth={keycloak}>
                                           <TraineeTrainings pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/my-standalone-exercises"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/my-standalone-exercises"
                                                    auth={keycloak}>
                                           <TraineeStandaloneOverview pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/view/:instanceId"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/instances" auth={keycloak}>
                                           <TraineeView pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/events"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/events" auth={keycloak}
                                                    mustBeTrainer={true}>
                                           <Events pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/versions"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/versions" auth={keycloak}
                                                    mustBeTrainer={true}>
                                           <Versions pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/presentation/:trainingSessionId"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/presentation/:trainingSessionId"
                                                    auth={keycloak}>
                                           <TrainingSessionPresentation pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/profile"
                                   element={
                                       <RequireAuth redirectTo="/login" redirectFrom="/profile" auth={keycloak}>
                                           <ProfilePage pageTitle={updatePageTitle}/>
                                       </RequireAuth>
                                   }
                            />
                            <Route path="/login" element={<LoginPage/>}/>
                            <Route path="/" element={<Navigate to="/home"/>}/>
                            <Route path="/404" element={<PageNotFound/>}/>
                            <Route path="*" element={<PageNotFound/>}/>
                        </Routes>

                    </Container>
                </Box>
            </ThemeProvider>
        </BrowserRouter>
    );
};

function RequireAuth({
                         children,
                         redirectTo,
                         redirectFrom,
                         auth,
                         mustBeTrainer,
                         mustBeTrainee
                     }: {
    children: JSX.Element,
    redirectTo: string,
    redirectFrom: string,
    auth: Keycloak,
    mustBeTrainer?: boolean,
    mustBeTrainee?: boolean
}) {
    const isAdmin = useSelector((state: RootState) => state.user.isAdmin);
    const params = useParams();
    let isAuthenticated = auth.authenticated;
    if (isAuthenticated) {
        if (mustBeTrainer && isAdmin) {
            return children;
        } else if (mustBeTrainee && isAdmin) {
            return <Navigate to={"/404"} state={{from: redirectFrom}}/>;
        } else if (!mustBeTrainer) {
            return children;
        } else {
            return <Navigate to={"/404"} state={{from: redirectFrom}}/>;
        }
    } else {
        return <Navigate to={redirectTo} state={{from: generatePath(redirectFrom, params)}}/>;
    }
}
