import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import * as serviceWorker from './serviceWorker'
import './index.css';
import SecuredApp from "./SecuredApp";
import Box from "@mui/material/Box";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {store} from "./store";
import moment from 'moment';


moment.updateLocale("fr", {
    week: {
        dow: 1
    },
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm'
    },
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>

            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Box translate="no" sx={{display: 'flex'}}>
                    <SecuredApp/>
                </Box>
            </LocalizationProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
