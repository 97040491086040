import React, {useEffect, useState} from "react";
import TrainingModule from "../../../types/TrainingModule";
import Typography from "@mui/material/Typography";
import {LinearProgress} from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridFilterModel,
    GridPaginationModel,
    GridSortDirection,
    GridSortModel
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import TrainingModuleDetailsDialog from "./TrainingModuleDetailsDialog";
import Paper from "@mui/material/Paper";
import {useDeleteTrainingModuleMutation, useLazyGetTrainingModulesQuery} from "../../../services/CatalogService";
import Grid from "@mui/material/Grid";
import SnackBarUtils from "../../SnackBarUtils";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {stringFilterOperators} from "../../utils/Utils";
import {useSearchParams} from "react-router-dom";

type Props = {};

const TrainingModuleList: React.FunctionComponent<Props> = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 10,
        page: 0,
    });
    const [pageSize, setPageSize] = React.useState(10);
    const [pageNo, setPageNo] = React.useState(0);
    const [sortOptions, setSortOptions] = React.useState(["name,asc"]);
    const [filterOptions, setFilterOptions] = React.useState([""]);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: "startDate", sort: "desc"
    }]);
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
    });

    const [triggerGetTrainingModules, {
        data: trainingsPage = {content: [], totalElements: 0},
        isFetching
    }] = useLazyGetTrainingModulesQuery();
    const [deleteTraining] = useDeleteTrainingModuleMutation();


    const handlePaginationChange = React.useCallback((newPaginationModel: GridPaginationModel) => {
        setPaginationModel(newPaginationModel);
        setPageSize(newPaginationModel.pageSize);
        setPageNo(newPaginationModel.page)
        // Here you save the data you need from the sort model
        setSearchParams(searchParams => {
            searchParams.set("page", newPaginationModel.page.toString())
            searchParams.set("pageSize", newPaginationModel.pageSize.toString())
            return searchParams;
        });
    }, []);
    const getTrainingModulesPage = () => {
        triggerGetTrainingModules({
            pageNo: pageNo,
            pageSize: pageSize,
            sort: sortOptions,
            filter: filterOptions
        })
    }

    const [rowCountState, setRowCountState] = React.useState(trainingsPage.totalElements);
    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            trainingsPage.totalElements !== undefined ? trainingsPage.totalElements : prevRowCountState,
        );
    }, [trainingsPage.totalElements, setRowCountState]);

    React.useEffect(() => {
        getTrainingModulesPage();
    }, [pageNo, pageSize, sortOptions, filterOptions]);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedDetailedModule, setSelectedDetailedModule] = useState<TrainingModule>();
    const [isNewModuleOpen, setIsNewModuleOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [selectedTrainingModule, setSelectedTrainingModule] = useState<TrainingModule>();

    useEffect(() => {
        let name = searchParams.get("name");
        if (name) {
            handleFilterModelChange({items: [{id: 1, field: 'name', operator: 'contains', value: name}]});
        }
        let description = searchParams.get("description");
        if (description) {
            handleFilterModelChange({items: [{id: 1, field: 'description', operator: 'contains', value: description}]});
        }
        let sort = searchParams.get("sort");
        if (sort) {
            handleSortModelChange([{field: sort.split(",")[0], sort: sort.split(",")[1] as GridSortDirection}]);
        }
        let pageParam = searchParams.get("page");
        let pageSizeParam = searchParams.get("pageSize");
        handlePaginationChange({
            pageSize: pageSizeParam ? parseInt(pageSizeParam) : 10,
            page: pageParam ? parseInt(pageParam) : 0
        });
    }, [searchParams]);

    const columns: GridColDef[] = [
        {
            field: 'name',
            align: 'center',
            headerName: 'Name',
            flex: 1,
            headerAlign: 'center',
            filterOperators: stringFilterOperators,
        },
        {
            field: 'description',
            align: 'center',
            headerName: 'Description',
            flex: 1,
            headerAlign: 'center',
            filterOperators: stringFilterOperators,
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            width: 250,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <>
                    <Button variant="text" onClick={() => handleEditClick(params.row)}>Edit</Button>
                    <Button variant="text" onClick={(e) => {
                        handleClickOpenDeleteDialog(params.row);
                        e.stopPropagation();
                    }
                    }
                            color={"error"}>Delete</Button>
                </>
            )
        }
    ];

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        setSortModel(sortModel)
        let firstSort = sortModel.find(first => first);
        if (firstSort) {
            setSearchParams(searchParams => {
                searchParams.set("sort", firstSort?.field + "," + firstSort?.sort)
                return searchParams;
            });
        }
    }, []);

    const handleFilterModelChange = React.useCallback((filterModel: GridFilterModel) => {
        setFilterModel(filterModel)
        setFilterOptions(filterModel.items.map((filterItem) => {
            return filterItem.value ? filterItem.field + "=" + filterItem.value : ""
        }));
        filterModel.items.forEach((item) => {
            item.value && setSearchParams(searchParams => {
                searchParams.set(item.field, item.value)
                return searchParams;
            });
        })
    }, []);

    const handleEditClick = (selected: TrainingModule) => {
        setSelectedDetailedModule(selected);
        setIsOpen(true);
    };

    const handleNewClick = () => {
        setIsNewModuleOpen(true);
    };

    const handleSaveTrainingModule = () => {
        setIsOpen(false);
    };

    const handleSaveNewTrainingModule = () => {
        setIsNewModuleOpen(false);
    };


    const handleCloseEditDialog = () => {
        setIsOpen(false);
    };

    const handleCloseNewTrainingModule = () => {
        setIsNewModuleOpen(false);
    };

    const handleClickOpenDeleteDialog = (trainingModule: TrainingModule) => {
        setSelectedTrainingModule(trainingModule);
        setOpenDeleteDialog(true);
    };
    const handleCloseDeleteDialog = () => {
        setSelectedTrainingModule({});
        setOpenDeleteDialog(false);
    };
    const handleValidDeleteDialog = () => {
        if (selectedTrainingModule?.id) {
            deleteTraining(selectedTrainingModule.id).unwrap().then(() => {
                SnackBarUtils.info('Module deleted');
            }).catch(() => {
                SnackBarUtils.error('Error happened while deleting module');
            });
        }
        setOpenDeleteDialog(false);
    };

    return (
        <>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container>
                    <Grid item xs={6}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom mb={3}>
                            Training Modules
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: 'right'}}>
                        <Button size="small" disableElevation variant="contained" onClick={() => handleNewClick()}
                                sx={{marginBottom: 3}}>New training module</Button>
                    </Grid>
                </Grid>
                <DataGrid
                    sx={{
                        boxShadow: 0,
                        border: 0,
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none",
                        },
                        cursor: "pointer"
                    }}
                    disableRowSelectionOnClick
                    autoHeight
                    rows={trainingsPage.content}
                    columns={columns}
                    pageSizeOptions={[10, 20, 50]}
                    paginationMode={"server"}
                    rowCount={rowCountState}
                    filterMode="server"
                    onFilterModelChange={handleFilterModelChange}
                    filterModel={filterModel}
                    getRowId={(row: any) => row.id.toString()}
                    pagination
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    components={{
                        LoadingOverlay: LinearProgress,
                    }}
                    loading={isFetching}
                    onRowClick={(row) => handleEditClick(row.row)}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationChange}
                />
                {<TrainingModuleDetailsDialog mode={"create"} trainingModule={{}}
                                              handleSave={handleSaveNewTrainingModule} isOpen={isNewModuleOpen}
                                              handleClose={handleCloseNewTrainingModule}></TrainingModuleDetailsDialog>}
                {selectedDetailedModule &&
                    <TrainingModuleDetailsDialog mode={"edit"} trainingModule={selectedDetailedModule} isOpen={isOpen}
                                                 handleClose={handleCloseEditDialog}
                                                 handleSave={handleSaveTrainingModule}/>}
            </Paper>
            <Dialog PaperProps={{
                style: {
                    backgroundImage: "none"
                },
            }} open={openDeleteDialog}>
                <DialogTitle>Delete training module?</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to delete this module?</p>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation onClick={handleCloseDeleteDialog}>CANCEL</Button>
                    <Button disableElevation color={"error"} onClick={handleValidDeleteDialog}>DELETE</Button>
                </DialogActions>
            </Dialog>
        </>);
}

export default TrainingModuleList;
